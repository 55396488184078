import React from "react"; // Importing React library
import { ReactComponent as Checkmark } from "../../../../assets/svg/checkmark.svg"; // Importing Checkmark SVG as a React component
import { CustomButton } from "../../../../components/buttons/CustomButton"; // Importing CustomButton component
import Iphone11 from "./assets/iphone11.png"; // Importing the iPhone 11 image

const PaymentPaid = ({ handleClose }) => {
  // Functional component with handleClose prop
  return (
    <div className="flex flex-col justify-center p-0">
      {" "}
      {/* Main container for the payment success message */}
      <div className="flex flex-row justify-center mt-[20px]">
        {" "}
        {/* Centering the success message */}
        <div className="flex flex-col text-center ">
          <Checkmark className="w-[98px] h-[98px] mx-auto" />{" "}
          {/* Checkmark icon indicating success */}
          <div className="text-[24px] font-medium">Payment Successful</div>{" "}
          {/* Success message text */}
        </div>
      </div>
      <div className="flex flex-col mx-auto ">
        {" "}
        {/* Container for additional payment details */}
        <div className="text-[16px] mx-auto font-medium">
          You have successfully paid CosmicNomad{" "}
          {/* Message confirming the payment */}
        </div>
        <div className="text-[30px] font-semibold text-center">$800</div>{" "}
        {/* Displaying the payment amount */}
        <div className="text-[16px] mx-auto font-medium">
          Thank you For your order
        </div>{" "}
        {/* Thank you message */}
        <div className="text-[12px] font-normal text-[#6B6B6B] ">
          Reference Number 123456{" "}
          {/* Displaying the reference number for the transaction */}
        </div>
        <div className="text-[12px] font-normal text-[#6B6B6B] pt-[2px] ">
          You will receive an email confirmation shortly at xyz.02@gmail.com{" "}
          {/* Email confirmation message */}
        </div>
        <div className="mx-auto mt-[10px] mb-[10px]">
          <img src={Iphone11} alt="Iphone11" className="w-[200px] h-[140px]" />{" "}
          {/* Displaying the iPhone image */}
        </div>
      </div>
      <div className="flex flex-row justify-center gap-[20px] items-center mx-auto mb-4">
        {" "}
        {/* Container for action buttons */}
        <div
          className="px-[56px] h-[28px] font-bold cursor-pointer" // Styling for the "Later" button
          onClick={handleClose} // Function to call when the button is clicked
        >
          Later {/* Button text */}
        </div>
        <CustomButton
          buttonVariant="secondary" // Button variant for styling
          containerVariant="w-[180px] h-[48px] rounded-full flex text-[18px] font-bold justify-center items-center" // Styling for the button
          labelText="Give Review" // Label for the button
        />
      </div>
    </div>
  );
};

export default PaymentPaid; // Exporting the PaymentPaid component
