import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import AppWrapper from "../../components/AppWrapper";
import SimpleCardModal from "../../components/Modal/SimpleCardModal";
import ImageViewer from "../../components/reusables/ImageViewer";
import { useDispatch, useSelector } from "react-redux";
import { formatNumberToCurrency, stringToNumber } from "../../utils/libs";
import { CustomButton } from "../../components/buttons/CustomButton";
import { clearListingData, resetSteps } from "../../redux/reducers/reducer";
import CenterModal from "../../components/Modal/CenterModal";
import { ReactComponent as Success } from "../../assets/svg/big-square.svg";
import { APP_PATHS } from "../../routes/constants";
import { useNavigate } from "react-router-dom";
import RequireAuth from "../../components/RequireAuth";
//import { uploadImageToStorage } from "../../StoreListingData";

//TODO: This should be included into the redux as a step rather than a page of it's own

const ReviewListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listData = useSelector((state) => state?.step);
  const [success, toggleSuccess] = useState(false);

  const threshold =
    stringToNumber(listData?.price) * (listData?.threshold / 100);
  console.log(listData);

  return (
    <RequireAuth>
      <Helmet>
        <title> Listing | Nezz</title>
      </Helmet>
      <AppWrapper>
        <div className="w-full flex justify-center px-10 my-10">
          <SimpleCardModal width="1000px">
            <>
              <div className="text-center mb-10">
                <h1 className="font-medium text-2xl">Summary</h1>
                <div className="font-light leading-tight text-sm">
                  Here is the summary of the item you want to list
                </div>
              </div>
              <div className="grid px-4 grid-cols-1 md:grid-cols-2 w-full mx-auto gap-5">
                <div className="w-full space-y-4">
                  <div>
                    <ImageViewer
                      images={
                        listData?.base64Urls
                          ? JSON.parse(listData?.base64Urls)
                          : []
                      }
                    />
                  </div>
                  <div>
                    <div className="mb-3 text-md font-bold">Tags</div>
                    <div className="flex gap-3">
                      {listData?.tags?.map((x, index) => (
                        <span
                          key={index}
                          className="bg-[#9747FF40] text-[#9747FF] p-2"
                        >
                          {x}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className=" space-y-3">
                    <div>
                      <h1 className="font-bold text-xl">
                        {listData?.itemName}
                      </h1>
                    </div>
                    <div>
                      <h1 className="font-bold text-3xl">
                        {formatNumberToCurrency({
                          currencyCode: 2,
                          number: stringToNumber(listData?.price),
                        })}
                      </h1>
                      <p className="font-extralight">
                        {formatNumberToCurrency({
                          currencyCode: 2,
                          number: threshold,
                        })}{" "}
                        / Threshold
                      </p>
                    </div>
                    <div>
                      <h1 className="font-bold text-md">Description</h1>
                      <p className="text-sm">{listData?.description}</p>
                    </div>
                    <div>
                      <h1 className="font-bold text-md">Condition</h1>
                      <p className="text-sm">{listData?.condition}</p>
                    </div>
                    <div>
                      <h1 className="font-bold text-md">Quantity</h1>
                      <p className="text-sm">{listData?.quantity}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" mt-10 flex flex-col items-center">
                <CustomButton
                  labelText={"Publish"}
                  containerVariant="text-sm px-5 py-1 rounded-full w-[50%]"
                  variant="font-bold"
                  buttonVariant="secondary"
                  onClick={() => {
                    dispatch(clearListingData());
                    toggleSuccess(true);
                    // navigate(APP_PATHS.REVIEW_LISTING);
                  }}
                />
                <button
                  onClick={() => {
                    dispatch(resetSteps());
                    navigate(APP_PATHS.LISTING_MAIN);
                  }}
                  className="mt-4 text-sm cursor-pointer"
                >
                  Edit
                </button>
              </div>
            </>
          </SimpleCardModal>
        </div>
      </AppWrapper>

      {success && (
//TODO: Go to dashboard or somewhere 
    //navigate(`/`);
        <CenterModal
          showCloseIcon={false}
          width="50%"
          handleClose={() => toggleSuccess(false)}
        >
          <div className="flex flex-col gap-5 items-center">
            <div>
              <Success />
            </div>
            <p className="text-center font-semibold">
              Item Listed Successfully
            </p>
          </div>
        </CenterModal>
      )}
    </RequireAuth>
  );
};

export default ReviewListing;
