import React from "react"; // Importing React library
import { ReactComponent as Checkmark } from "../../../../assets/svg/checkmark.svg"; // Importing Checkmark SVG as a React component
import { CustomButton } from "../../../../components/buttons/CustomButton"; // Importing CustomButton component
import PropTypes from "prop-types"; // Importing PropTypes for type checking

const PaymentSuccess = ({ handleClose, transferVariant, balance }) => {
  // Functional component with props
  return (
    <div className="flex flex-col p-0">
      {" "}
      {/* Reduced margins */}
      {/* Main container for the payment success message */}
      <div className="flex flex-row justify-center mt-[20px]">
        {" "}
        {/* Reduced bottom margin */}
        <div className="flex flex-col text-center">
          <Checkmark className="w-[98px] h-[98px] mx-auto" />{" "}
          {/* Checkmark icon indicating success */}
          <div className="text-[24px] font-medium">Payment Successful</div>{" "}
          {/* Reduced font size */}
        </div>
      </div>
      {transferVariant === "Add" ? ( // Conditional rendering based on transfer variant
        <div>
          <div className="flex flex-row text-[20px] justify-between font-medium mx-[30px] mb-[20px]">
            {" "}
            {/* Reduced margins and font size */}
            <div>To</div> {/* Label for the recipient */}
            <div>Wallet</div> {/* Recipient's account */}
          </div>
          <div className="flex flex-row text-[20px] justify-between font-medium mx-[30px] mb-[20px]">
            {" "}
            {/* Reduced margins and font size */}
            <div>From</div> {/* Label for the sender */}
            <div>Bank of America</div> {/* Sender's account */}
          </div>
        </div>
      ) : (
        // If transferVariant is not "Add"
        <div>
          <div className="flex flex-row text-[20px] justify-between font-medium mx-[100px] mb-[10px]">
            {" "}
            {/* Reduced margins and font size */}
            <div>To</div> {/* Label for the recipient */}
            <div>Bank of America</div> {/* Recipient's account */}
          </div>
          <div className="flex flex-row text-[20px] justify-between font-medium mx-[100px] mb-[10px]">
            {" "}
            {/* Reduced margins and font size */}
            <div>From</div> {/* Label for the sender */}
            <div>Wallet</div> {/* Sender's account */}
          </div>
        </div>
      )}
      <div className="flex flex-row text-[20px] justify-between font-medium mx-[100px] mb-[10px]">
        {" "}
        {/* Reduced margins and font size */}
        <div>Amount</div> {/* Label for the amount */}
        <div>${balance}</div> {/* Displaying the amount transferred */}
      </div>
      <div className="flex flex-row text-[20px] justify-between font-medium mx-[100px] mb-[10px]">
        {" "}
        {/* Reduced margins and font size */}
        <div>Date</div> {/* Label for the date */}
        <div>05/13/2024</div> {/* Displaying the transaction date */}
      </div>
      <div className="flex flex-row text-[20px] justify-between font-medium mx-[100px] mb-[10px]">
        {" "}
        {/* Reduced margins and font size */}
        <div>Email</div> {/* Label for the email */}
        <div>xyz.02@gmail/com</div>{" "}
        {/* Displaying the email associated with the transaction */}
      </div>
      <div className="flex flex-row text-[20px] justify-between font-medium mx-[100px] mb-[20px]">
        {" "}
        {/* Reduced margins and font size */}
        <div>Confirmation</div> {/* Label for the confirmation code */}
        <div>xy35dhjt</div> {/* Displaying the confirmation code */}
      </div>
      <div className="flex flex-row justify-center mt-[20px] gap-[20px] mb-4">
        {" "}
        {/* Reduced top margin and gap */}
        {/* Container for action buttons */}
        <CustomButton
          buttonVariant="tetiary" // Button variant for the first button
          containerVariant="w-[180px] h-[48px] rounded-full flex text-[16px] font-bold justify-center items-center" // Reduced button size
          labelText="Goto Home" // Label for the button
          onClick={handleClose} // Function to call when the button is clicked
        />
        <CustomButton
          buttonVariant="secondary" // Button variant for the second button
          containerVariant="w-[180px] h-[48px] rounded-full flex text-[14px] font-bold items-center justify-center" // Reduced button size
          labelText="Send another payment" // Label for the button
        />
      </div>
    </div>
  );
};

PaymentSuccess.propTypes = {
  // Prop type validation
  handleClose: PropTypes.func.isRequired, // handleClose must be a function
};

export default PaymentSuccess; // Exporting the PaymentSuccess component
