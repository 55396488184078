import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import SimpleCardModal from "../../../../components/Modal/SimpleCardModal";
import TextInput from "../../../../components/reusables/TextInput";
import { CustomButton } from "../../../../components/buttons/CustomButton";
import DragDropList from "../../../../components/DnD/DragDropList";
import { ToastNotify } from "../../../../components/reusables/helpers/ToastNotify";
import { listingValidationStep1 } from "../../../../utils/ValidationSchemas/getStarted.validation";
import { nextStep } from "../../../../redux/reducers/reducer";

const Step1 = ({ listingData }) => {
  const dispatch = useDispatch();
  const [base64Urls, setBase64Urls] = useState(Array(6).fill(null));
  const [load, toggleLoad] = useState(false);

  useEffect(() => {
    const storedImagesUrls = listingData?.base64Urls;
    if (storedImagesUrls) {
      setBase64Urls(JSON.parse(storedImagesUrls));
    }
    if (listingData?.itemName != null) {
      setFieldValue("itemName", listingData?.itemName);
    }
  }, [listingData]);


  const handleFileSelect = (selectedBase64Urls) => {
    setBase64Urls(selectedBase64Urls);
  };

  const formik = useFormik({
    initialValues: {
      itemName: "",
    },
    validationSchema: listingValidationStep1,
  });

  const { handleChange, values, errors, touched, setFieldValue, handleBlur } =
    formik;

  const storeValues = async () => {
    try {
      toggleLoad(true);
      //store values in sessionStorage until final completion
      if (base64Urls.some((file) => file != null)) {
        dispatch(
          nextStep({ itemName: values?.itemName, base64Urls: JSON.stringify(base64Urls) })
        );
      }
    } catch (error) {
      console.log(error);
      ToastNotify({
        type: "error",
        message: error.message,
        position: "top-right",
      });
    } finally {
      toggleLoad(false);
    }
  };
  return (
    <>
      <div className="w-full flex justify-center my-10">
        <SimpleCardModal width="650px">
          <>
            <div className="text-center">
              <h1 className="font-medium text-2xl">List Item</h1>
              <div className="font-light leading-tight text-sm">
                Fill in your item name and image below to give <br />
                your item its unique identity.
              </div>
            </div>

            <div className="my-10 px-10 space-y-5">
              <div className="w-full">
                <TextInput
                  name="itemName"
                  label="Item Name"
                  placeHolder="Item Name"
                  error={errors?.itemName}
                  handleChange={handleChange}
                  value={values?.itemName}
                  handleBlur={handleBlur}
                  hasError={errors?.itemName && touched?.itemName}
                />
              </div>
              <div className="space-y-2">
                <label className="text-xs">
                  Upload a clear image (JPG,PNG,JPEG, SVG)
                </label>
                <div className="border-2 border-black border-dashed p-4 rounded-xl">
                  <DragDropList
                    onFileSelect={handleFileSelect}
                    initialSelectedFiles={base64Urls.map((url, index) =>
                      url ? { name: `Image ${index}` } : null
                    )}
                    initialBase64Urls={base64Urls}
                  />
                </div>
              </div>
            </div>

            <div className="w-[70%] mx-auto text-center">
              <CustomButton
                labelText={load ? "Please wait" : "Continue 1/5"}
                containerVariant="text-sm px-5 py-3 rounded-full w-full"
                variant="font-bold"
                buttonVariant="secondary"
                onClick={storeValues}
                isDisabled={
                  values.itemName.trim() === "" ||
                  base64Urls.every((file) => file === null) ||
                  load
                }
              />
            </div>
          </>
        </SimpleCardModal>
      </div>
    </>
  );
};

export default Step1;
