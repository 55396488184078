import { db, storage } from "../../firebaseConfig";
import { doc, updateDoc, Timestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { v4 } from "uuid";

export const storeListingInfo = async (
  itemName,
  description,
  price,
  quantity,
  condition,
  currency,
  tags,
  additionalDetails,
  threshold,
  imageUrl
) => {
  try {
    const email = sessionStorage.getItem("userEmail");
    const userRef = doc(db, "Listing", email);
    await updateDoc(
      userRef,
      {
        itemName: itemName,
        description: description,
        price: price,
        currency: currency,
        quantity: quantity,
        condition: condition,
        additionalDetails: additionalDetails,
        thresholdAmount: threshold,
        tags: tags,
        imageUrl: imageUrl,
        listingID: v4(),
        status: "Listed",
        dateofListing: Timestamp.now(),
        lastlistingUpdate: Timestamp.now(),
      },
      { merge: true }
    );
    return { success: true, message: "User information updated successfully" };
  } catch (error) {
    throw new Error(error.message);
  }
};

const dataURLtoBlob = (dataURL) => {
  const byteString = atob(dataURL.split(",")[1]);
  const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const uploadImageToStorage = async (base64Urls) => {
  try {
    const validBase64Urls = base64Urls.filter((url) => url !== null);
    const uploadTasks = validBase64Urls.map((base64Url) => {
      const imageBlob = dataURLtoBlob(base64Url);
      const storageRef = ref(storage, `listing Images/${v4()}`);
      return uploadBytesResumable(storageRef, imageBlob);
    });

    await Promise.all(uploadTasks);
    const downloadURLs = await Promise.all(
      uploadTasks.map((uploadTask) => getDownloadURL(uploadTask.snapshot.ref))
    );
    return downloadURLs;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const uploadProfileImageToStorage = async (base64Urls, type, previousImageUrl) => {
  try {
    // Delete the previous image if it exists
    if (previousImageUrl) {
      const previousImageRef = ref(storage, previousImageUrl);
      await deleteObject(previousImageRef);
    }

    const validBase64Urls = base64Urls.filter((url) => url !== null);
    const uploadTasks = validBase64Urls.map((base64Url) => {
      const imageBlob = dataURLtoBlob(base64Url);
      const storageRef =
        type === "profile"
          ? ref(storage, `Profile Images/${v4()}`)
          : ref(storage, `listing Images/${v4()}`);
      return uploadBytesResumable(storageRef, imageBlob);
    });

    await Promise.all(uploadTasks);
    const downloadURLs = await Promise.all(
      uploadTasks.map((uploadTask) => getDownloadURL(uploadTask.snapshot.ref))
    );
    return downloadURLs;
  } catch (error) {
    throw new Error(error.message);
  }
};
