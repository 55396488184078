import CustomSlider from "../../components/reusables/CustomSlider";
import CustomOTPInput from "../../components/reusables/CustomOTPInput";
import GrowingInput from "../../components/reusables/GrowingInput";

import Banner from "../../assets/img/banner.png";
import SearchInput from "../../components/reusables/SearchInput";
import SelectField from "../../components/reusables/SelectField";
import TextArena from "../../components/reusables/TextArena";
import TextInput from "../../components/reusables/TextInput";

import NavBar from "../../components/navbar/Navbar";
import ExitableSection from "../../components/navbar/exitableSection";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import AppWrapper from "../../components/AppWrapper";
import SimpleCardModal from "../../components/Modal/SimpleCardModal";
import ImageViewer from "../../components/reusables/ImageViewer";
import { useDispatch, useSelector } from "react-redux";
import { formatNumberToCurrency, stringToNumber } from "../../utils/libs";
import { CustomButton } from "../../components/buttons/CustomButton";
import { clearListingData, resetSteps } from "../../redux/reducers/reducer";
import CenterModal from "../../components/Modal/CenterModal";
import { ReactComponent as Success } from "../../assets/svg/big-square.svg";
import { APP_PATHS } from "../../routes/constants";
import { useNavigate } from "react-router-dom";
import RequireAuth from "../../components/RequireAuth";

const ItemSearchDetail = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <RequireAuth>
      <Helmet>
        <title> Listing | Nezz</title>
      </Helmet>
      <AppWrapper>
        <div className="w-full flex justify-center px-10 my-10">
          <div className="flex flex-row w-full pl-[74px] pr-[62px]">
            <div className="w-full flex flex-col">
              <div className="size-9 font-bold">
                Here are you search results
              </div>

              <div>
                Hello, we were able to find about 100 items matching your search
                prompt:
              </div>
              <div>
                <b>"Items Near Me"</b>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    </RequireAuth>
  );
};

export default ItemSearchDetail;
