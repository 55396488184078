import Iphone11 from "./assets/iphone11.png"; // Importing the iPhone 11 image
import { ReactComponent as Stars } from "./assets/stars.svg"; // Importing the Stars SVG as a React component
import { CustomButton } from "../../../../components/buttons/CustomButton"; // Importing the CustomButton component
import MoneyTransfer from "../MoneyTransfer/MoneyTransfer"; // Importing MoneyTransfer component (not used in this snippet)
import PropTypes from "prop-types"; // Importing PropTypes for type checking

const ProductInformation = ({ handleClose, nextStep }) => {
  // Functional component with props
  const handleCancel = () => {
    // Function to handle cancel action
    handleClose(); // Call the handleClose function passed as a prop
  };

  const handleConfirm = () => {
    // Function to handle confirm action
    nextStep(); // Call the nextStep function passed as a prop
    handleClose(); // Close the modal after confirming
  };

  return (
    <div className="flex flex-col justify-center mt-5">
      {" "}
      {/* Main container for product information */}
      <div className="text-[31px] font-bold text-center">
        Product Information
      </div>{" "}
      {/* Title for the product information */}
      <div className="text-[20px] font-semibold text-center mt-5">
        Cosmic Nomad selling product:
      </div>{" "}
      {/* Subtitle for the seller */}
      <div className="flex flex-row pl-[144px] gap-16 pt-6">
        {" "}
        {/* Container for product image and details */}
        <img src={Iphone11} className="w-[163px] h-[155px]" />{" "}
        {/* Displaying the iPhone image */}
        <div className="flex flex-col h-[155px] justify-between">
          {" "}
          {/* Container for product details */}
          <div className="text-[20px] font-medium">Iphone 11 Pro Max</div>{" "}
          {/* Product name */}
          <div className="text-[20px] font-bold">$800</div>{" "}
          {/* Product price */}
          <div className="text-[20px] font-medium">36 Miles</div>{" "}
          {/* Product mileage */}
          <div className="text-[20px] font-medium">
            <Stars />
          </div>{" "}
          {/* Displaying stars rating */}
        </div>
      </div>
      <div className="flex flex-row justify-center gap-[16px] mb-[30px] mt-12 items-center">
        {" "}
        {/* Container for action buttons */}
        <button
          className="text-[14px] h-[22px] cursor-pointer font-bold text-black pl-[76px] pr-[50px]" // Styling for the cancel button
          onClick={handleClose} // Close the modal when clicked
        >
          Cancel {/* Cancel button text */}
        </button>
        <CustomButton
          buttonVariant="secondary" // Button variant for styling
          containerVariant="w-[172px] h-[40px] rounded-full flex text-[16px] font-medium items-center justify-center" // Styling for the confirm button
          labelText="Confirm" // Label for the confirm button
          onClick={handleConfirm} // Call handleConfirm when clicked
        />
      </div>
    </div>
  );
};

ProductInformation.propTypes = {
  // Prop type validation
  handleClose: PropTypes.func.isRequired, // handleClose must be a function
  nextStep: PropTypes.func.isRequired, // nextStep must be a function
};

export default ProductInformation; // Exporting the ProductInformation component
