import React, {useCallback} from "react";
import { CustomButton } from "../../../components/buttons/CustomButton";
import DragDrop from "../../../components/DnD/DragDrop";
import OnboardingWrapper from "../OnboardingWrapper";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import RequireAuth from "../../../components/RequireAuth"; 


const Step3 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectTo = new URLSearchParams(location.search).get('redirect')
  const step = new URLSearchParams(location.search).get('step') || 0

  const finalOnboarding = useCallback(() => {
    navigate(`${redirectTo}?step=${step}`);
  }, [navigate, redirectTo, step]);

  return (
    <>
    <Helmet>
        <title> Onboarding 3/3 | Nezz </title>
      </Helmet>
      <OnboardingWrapper>
        <div className="flex mt-16 justify-center overflow-auto">
          <div className="text-center rounded-lg w-full max-w-lg px-5">
            <div className="mb-10">
              <h1 className="text-2xl font-semibold">Verify your Identity</h1>
              <p className="text-xs">
                To build trust, we kindly ask you to authenticate your identity.
              </p>
            </div>
            <div className="space-y-10">
              <div>
                <span className="text-sm mb-3 flex">
                  Kindly upload a Valid ID Card
                </span>
                <DragDrop />
              </div>
              <div className="mx-auto text-center space-y-2">
                <CustomButton
                  labelText="Continue 3/3"
                  containerVariant="text-sm px-5 py-3 rounded-full w-full"
                  variant="font-bold"
                  buttonVariant="secondary"
                  //TODO: Go to dashboard/account or wherever it's been redirected from
                  onClick={finalOnboarding}
                />
                <div className="mt-5">Skip</div>
              </div>
            </div>
          </div>
        </div>
      </OnboardingWrapper>
    </>
  );
};

export default Step3;
