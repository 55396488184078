import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./CustomSlider.css"; // Custom CSS for rc-slider
import { formatNumberToCurrency, stringToNumber } from "../../utils/libs";

const CustomSlider = ({ value, onChange, price }) => {
  console.log(value);
  const thresholdValue = Number((stringToNumber(price) * value) / 100);
  return (
    <div className="relative w-full py-3">
      <div className="relative">
        <span
          className="absolute -top-6 text-purple-600 text-xs font-medium"
          style={{
            left: `${value}%`,
            transform: `translateX(-${value}%)`,
          }}
        >
          {formatNumberToCurrency({
            number: thresholdValue,
            currencyCode: 2,
          })}
        </span>
      </div>
      <Slider
        min={0}
        max={100}
        value={value}
        onChange={onChange}
        trackStyle={{ backgroundColor: "#9747FF", height: 6 }}
        handleStyle={{
          borderColor: "#9747FF",
          height: 16,
          width: 16,
          marginLeft: 0,
          marginTop: -5,
          backgroundColor: "#9747FF",
        }}
        railStyle={{ backgroundColor: "#e9ecef", height: 6 }}
        tipFormatter={(value) => `${value}%`}
        marks={{ 
          0: "0%", 
          25: "25%",
          50: "50%", 
          75: "75%", 
          100: "100%" 
        }}
      />
    </div>
  );
};

export default CustomSlider;
