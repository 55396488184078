import Ally from "./assets/ally.png"; // Importing Ally bank logo
import BMO from "./assets/bmo.png"; // Importing BMO bank logo
import BOA from "./assets/boa.png"; // Importing Bank of America logo
import Capital from "./assets/capital.png"; // Importing Capital bank logo
import CapitalOne from "./assets/capitalone.png"; // Importing Capital One bank logo
import Chase from "./assets/chase.png"; // Importing Chase bank logo
import CITI from "./assets/citi.png"; // Importing CITI bank logo
import FifthThrid from "./assets/fifththird.png"; // Importing Fifth Third bank logo
import PNC from "./assets/pnc.png"; // Importing PNC bank logo
import TD from "./assets/td.png"; // Importing TD bank logo
import Truist from "./assets/truist.png"; // Importing Truist bank logo
import WellsFargo from "./assets/wellsfargo.png"; // Importing Wells Fargo bank logo
import { CustomButton } from "../../../../components/buttons/CustomButton"; // Importing CustomButton component
import MoneyTransfer from "../MoneyTransfer/MoneyTransfer"; // Importing MoneyTransfer component
import React, { useState } from "react"; // Importing React and useState hook
import PropTypes from "prop-types"; // Importing PropTypes for type checking

const BankSelect = ({
  // Functional component definition with props
  handleClose, // Function to close the modal
  transferFailed, // Function to handle transfer failure
  transferVariant, // Type of transfer (e.g., transfer, pay, add)
  verificationOpen, // Function to open verification modal
  balance, // Current balance of the user
}) => {
  const [nextStep, setNextStep] = useState(false); // State to manage the next step in the process

  return nextStep ? ( // Conditional rendering based on nextStep state
    <MoneyTransfer
      variant={transferVariant} // Pass the transfer variant to MoneyTransfer
      handleClose={() => handleClose()} // Close the modal when MoneyTransfer is closed
      transferFailed={() => transferFailed()} // Handle transfer failure
      bank="Bank of America" // Hardcoded bank name for the transfer
      verificationOpen={() => verificationOpen()} // Open verification modal
      balance={balance} // Pass the current balance
    />
  ) : (
    // Render the bank selection UI
    <div className="flex flex-col mt-[15px] h-[25%]">
      {" "}
      {/* Adjusted height and side margins */}
      <div className="flex flex-row justify-center">
        {" "}
        {/* Centering the title */}
        <div className="font-semibold text-[24px] text-black">
          Select Bank
        </div>{" "}
        {/* Adjusted font size */}
      </div>
      <div className="flex flex-col mt-[20px] gap-y-[30px]">
        {" "}
        {/* Adjusted margins and gaps */}
        {/* Row 1 */}
        <div className="flex flex-row justify-center gap-x-[80px]">
          <button
            className="w-[144px] h-[72px] border-[1px] border-black rounded-xl cursor-pointer flex justify-center items-center" // Increased button size by 20%
            onClick={() => setNextStep(true)} // Set nextStep to true when clicked
          >
            <img
              className="w-[90px] h-[36px]"
              src={BOA}
              alt="Bank of America"
            />{" "}
            {/* Increased size by 20% */}
          </button>
          <div className="w-[144px] h-[72px] border-[1px] border-black rounded-xl cursor-pointer flex justify-center items-center">
            {" "}
            {/* BMO button */}
            <img className="w-[72px] h-[27px]" src={BMO} alt="BMO" />{" "}
            {/* Increased size by 20% */}
          </div>
          <div className="w-[144px] h-[72px] bg-[#B31E30] border-[1px] border-black rounded-xl cursor-pointer flex justify-center items-center">
            {" "}
            {/* Wells Fargo button */}
            <img
              className="w-[54px] h-[54px]"
              src={WellsFargo}
              alt="Wells Fargo"
            />{" "}
            {/* Increased size by 20% */}
          </div>
        </div>
        {/* Row 2 */}
        <div className="flex flex-row justify-center gap-x-[80px]">
          <div className="w-[144px] h-[72px] border-[1px] border-black rounded-xl cursor-pointer flex justify-center items-center">
            {" "}
            {/* Truist button */}
            <img
              className="w-[90px] h-[22.5px]"
              src={Truist}
              alt="Truist"
            />{" "}
            {/* Increased size by 20% */}
          </div>
          <div className="w-[144px] h-[72px] border-[1px] border-black rounded-xl cursor-pointer flex justify-center items-center">
            {" "}
            {/* Fifth Third button */}
            <img
              className="w-[90px] h-[36px]"
              src={FifthThrid}
              alt="Fifth Third"
            />{" "}
            {/* Increased size by 20% */}
          </div>
          <div className="w-[144px] h-[72px] border-[1px] border-black rounded-xl cursor-pointer flex justify-center items-center">
            {" "}
            {/* Ally button */}
            <img className="w-[54px] h-[27px]" src={Ally} alt="Ally" />{" "}
            {/* Increased size by 20% */}
          </div>
        </div>
        {/* Row 3 */}
        <div className="flex flex-row justify-center gap-x-[80px]">
          <div className="w-[144px] h-[72px] border-[1px] border-black rounded-xl cursor-pointer flex justify-center items-center">
            {" "}
            {/* Chase button */}
            <img className="w-[90px] h-[22.5px]" src={Chase} alt="Chase" />{" "}
            {/* Increased size by 20% */}
          </div>
          <div className="w-[144px] h-[72px] border-[1px] border-black rounded-xl cursor-pointer flex justify-center items-center">
            {" "}
            {/* PNC button */}
            <img
              className="w-[90px] h-[22.5px]"
              src={PNC}
              alt="PNC Bank"
            />{" "}
            {/* Increased size by 20% */}
          </div>
          <div className="w-[144px] h-[72px] border-[1px] border-black rounded-xl cursor-pointer flex justify-center items-center">
            {" "}
            {/* TD button */}
            <img className="w-[90px] h-[54px]" src={TD} alt="TD Bank" />{" "}
            {/* Increased size by 20% */}
          </div>
        </div>
        {/* Row 4 */}
        <div className="flex flex-row justify-center gap-x-[80px]">
          <div className="w-[144px] h-[72px] border-[1px] border-black rounded-xl cursor-pointer flex justify-center items-center">
            {" "}
            {/* CITI button */}
            <img
              className="w-[72px] h-[36px]"
              src={CITI}
              alt="CITI Bank"
            />{" "}
            {/* Increased size by 20% */}
          </div>
          <div className="w-[144px] h-[72px] border-[1px] border-black rounded-xl cursor-pointer flex justify-center items-center">
            {" "}
            {/* Capital button */}
            <img
              className="w-[90px] h-[36px]"
              src={Capital}
              alt="Capital Bank"
            />{" "}
            {/* Increased size by 20% */}
          </div>
          <div className="w-[144px] h-[72px] border-[1px] border-black rounded-xl cursor-pointer flex justify-center items-center">
            {" "}
            {/* Capital One button */}
            <img
              className="w-[90px] h-[36px]"
              src={CapitalOne}
              alt="Capital One"
            />{" "}
            {/* Increased size by 20% */}
          </div>
        </div>
      </div>
      <div className="mt-[20px] flex justify-center mb-[10px]">
        {" "}
        {/* Adjusted margins */}
        <CustomButton
          buttonVariant="secondary"
          labelText="Cancel"
          containerVariant="py-[5px] px-[30px] rounded-full flex justify-center" // Adjusted button size
          onClick={handleClose} // Function to call when the button is clicked
        />
      </div>
    </div>
  );
};

BankSelect.propTypes = {
  // Prop type validation for the BankSelect component
  handleClose: PropTypes.func.isRequired, // handleClose must be a function
  transferFailed: PropTypes.func.isRequired, // transferFailed must be a function
  verificationOpen: PropTypes.func.isRequired, // verificationOpen must be a function
  transferVariant: PropTypes.string.isRequired, // transferVariant must be a string
  balance: PropTypes.number.isRequired, // balance must be a number
};

export default BankSelect; // Exporting the BankSelect component
