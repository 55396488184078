export const APP_PATHS = {
  LANDING: "/",

  // onboarding
  LOGIN: "/get-started",
  ONBOARDING_STEP_1: "/onboarding/step-1",
  ONBOARDING_STEP_2: "/onboarding/step-2",
  ONBOARDING_STEP_3: "/onboarding/step-3",

  // listing
  LISTING_MAIN: "/list",
  REVIEW_LISTING: "/list/review-listing",
};
