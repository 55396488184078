import React from "react";
import {useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/svg/nezzlogowhite.svg";
import { APP_PATHS } from "../../routes/constants";
import { CustomButton } from "../buttons/CustomButton";
import { track } from "@vercel/analytics";

const Navbar = () => {
  const navigate = useNavigate();
  //const location = useLocation();
  //const path = location?.pathname;

  const handleApply = () => {
    const url = "https://dbwe9d9cfaa.typeform.com/to/FBYpvPqv";
    window.open(url, "_self");
  };

  return (
    <>
      <div className="absolute font-sfpro top-0 py-5 px-5 md:px-16 flex justify-between items-center w-full">
        <div
          onClick={() => navigate(APP_PATHS.LANDING)}
          className="z-50 cursor-pointer"
        >
          <Logo />
        </div>
        <div className="">
          <CustomButton
            labelText="Get Priority Access"
            containerVariant="text-xs md:text-sm px-3 py-2 rounded-full"
            variant="font-bold "
            onClick={() => {
              track("Get Access Nav"); //navbar
              handleApply();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Navbar;
