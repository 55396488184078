import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as CaretDownIcon } from "../../assets/svg/downIcon.svg";

function SelectField({
  containerVariant = "w-full flex flex-col",
  label,
  options,
  name,
  placeholder,
  isDisabled = false,
  onChange = () => {},
  onBlur = () => {},
  selectedItem = null,
  isCountrySelect = false,
  value,
  hasError = false,
  error = "",
  isLabelBold = false,
  isBorderBold = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectedItem);
  const fieldRef = useRef(null);

  const countryLogo = options?.filter((x) => x?.value === value)[0];

  useEffect(() => {
    setSelectedOption(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        fieldRef.current &&
        !fieldRef.current.contains(event.target) &&
        !event.target.classList.contains("select-field-option")
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);

    // Trigger Formik's handleChange
    onChange({
      target: {
        name,
        value: option.value,
      },
    });
  };

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleBlur = () => {
    onBlur({
      target: {
        name,
      },
    });
  };

  return (
    <div className={`${containerVariant}`}>
      {label && (
        <div
          className="flex items-center font-jarkata font-normal"
          ref={fieldRef}
        >
          <label
            htmlFor={`input-${name}`}
            className={`text-xs mb-2.5 ${
              isDisabled ? "text-gray-300" : "text-black"
            } ${isLabelBold ? "font-bold" : "font-normal"}`}
          >
            {label}
          </label>
        </div>
      )}

      <div className="relative">
        <input
          className={`w-full h-[48px] outline-none text-black placeholder:text-xs placeholder:text-[#00000098] text-xs ${
            value && isCountrySelect && `pl-10 `
          } text-left border ${
            isBorderBold ? "border-black" : "border-[#858585]"
          } px-4 py-4 rounded-xl bg-[#ffffff] bg-opacity-10 ${
            hasError ? "border-red-500" : ""
          }`}
          onClick={handleToggleDropdown}
          onBlur={handleBlur}
          value={value}
          placeholder={placeholder}
        />
        {isCountrySelect && (
          <span
            className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${countryLogo?.className}`}
          />
        )}
        <CaretDownIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
      </div>
      {isOpen && (
        <div className="absolute z-10 min-w-[150px] mt-20 py-2 px-3 bg-[#ffffff] rounded-md shadow-lg">
          <div
            className={`overflow-x-auto ${
              options?.length > 5 ? "h-60" : "h-20"
            }`}
          >
            {options?.map((option) =>
              option ? (
                <div
                  key={option.value}
                  className="px-2 py-2 cursor-pointer hover:bg-gray-100 text-xs select-field-option"
                  onClick={() => handleOptionClick(option)}
                >
                  {isCountrySelect && (
                    <span
                      className={`mr-2 ${option.className} mr-2 scale-75`}
                    />
                  )}
                  <span>{option.label}</span>
                </div>
              ) : null
            )}
          </div>
        </div>
      )}
      {hasError && (
        <div className="flex gap-2">
          <p className="text-red-500 text-[10px] h-auto py-1 font-openSans">
            {error}
          </p>
        </div>
      )}
    </div>
  );
}

export default SelectField;
