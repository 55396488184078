import React, { useState, useRef, useEffect } from "react";
import { ToastNotify } from "../reusables/helpers/ToastNotify";

const DragDropList = ({ onFileSelect, initialSelectedFiles, initialBase64Urls }) => {
  const [selectedFiles, setSelectedFiles] = useState(Array(6).fill(null));
  const [base64Urls, setBase64Urls] = useState(Array(6).fill(null));

  useEffect(() => {
    if (initialSelectedFiles) {
      const newSelectedFiles = [...Array(6).fill(null)];
      initialSelectedFiles.forEach((file, index) => {
        newSelectedFiles[index] = file;
      });
      setSelectedFiles(newSelectedFiles);
    }

    if (initialBase64Urls) {
      const newBase64Urls = [...Array(6).fill(null)];
      initialBase64Urls.forEach((url, index) => {
        newBase64Urls[index] = url;
      });
      setBase64Urls(newBase64Urls);
    }
  }, [initialSelectedFiles, initialBase64Urls]);

  const fileInputRefs = useRef(
    Array(6)
      .fill(null)
      .map(() => React.createRef())
  );

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = Array.from(e.dataTransfer.files).slice(0, 6);
    handleFiles(files);
  };

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files).slice(0, 6);
    handleFiles(files);
  };

  const handleFiles = (files) => {
    const validFiles = files.filter((file) => file.type.startsWith("image/"));
    if (validFiles.length > 0) {
      const newSelectedFiles = [...selectedFiles];
      validFiles.forEach((file) => {
        const emptyIndex = newSelectedFiles.findIndex((f) => f === null);
        if (emptyIndex !== -1) {
          newSelectedFiles[emptyIndex] = file;
          convertToBase64(file, emptyIndex);
        }
      });
      setSelectedFiles(newSelectedFiles);
    } else {
      ToastNotify({
        type: "warning",
        message: "Please select valid image files.",
        position: "top-right",
      });
    }
    if (files.some((file) => file.size > 10485760)) {
      ToastNotify({
        type: "warning",
        message: "File size exceeds the maximum limit of 10MB.",
        position: "top-right",
      });
    }
  };

  const handleRemove = (index) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles[index] = null;
    setSelectedFiles(newSelectedFiles);

    setBase64Urls((prevBase64Urls) => {
      const newBase64Urls = [...prevBase64Urls];
      newBase64Urls[index] = null;
      onFileSelect(newBase64Urls.filter((url) => url !== null));
      return newBase64Urls;
    });
  };

  const convertToBase64 = (file, index) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Urls((prevBase64Urls) => {
        const newBase64Urls = [...prevBase64Urls];
        newBase64Urls[index] = reader.result;
        onFileSelect(newBase64Urls.filter((url) => url !== null));
        return newBase64Urls;
      });
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
      {selectedFiles.map((file, index) => (
        <div
          key={index}
          className={`relative w-full h-32 bg-[#D9D9D9] rounded-xl flex items-center justify-center ${
            file ? "border-blue-500" : "border-black"
          }`}
          onDragEnter={handleDragEnter}
          onDragOver={(e) => e.preventDefault()}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {file ? (
            <div className="relative w-full h-full">
              <img
                src={base64Urls[index] || URL.createObjectURL(file)}
                alt={`Image ${index}`}
                className="w-full h-full object-cover rounded-lg"
              />
              <button
                className="absolute top-2 right-2 px-2 py-1 text-sm text-white bg-red-500 rounded hover:bg-red-600"
                onClick={() => handleRemove(index)}
              >
                Remove
              </button>
            </div>
          ) : (
            <div className="text-center flex flex-col items-center justify-center">
              <span
                className="text-black cursor-pointer text-xl"
                onClick={() => fileInputRefs.current[index].current.click()}
              >
                +
              </span>
              <input
                id={`file-upload-${index}`}
                type="file"
                accept="image/*"
                ref={fileInputRefs.current[index]}
                className="hidden"
                onChange={handleFileInputChange}
                multiple
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DragDropList;