import React, { useEffect, useState, useCallback } from "react";
import "../styles.scss";
import { CustomButton } from "../../../../components/buttons/CustomButton";
import { useNavigate, useLocation } from "react-router";
import CenterModal from "../../../../components/Modal/CenterModal";
import CustomOTPInput from "../../../../components/reusables/CustomOTPInput";
import { APP_PATHS } from "../../../../routes/constants";
import { ReactComponent as Apple } from "../../../../assets/svg/apple.svg";
import { ReactComponent as Google } from "../../../../assets/svg/google.svg";
import OnboardingNavbar from "../../components/OnboardingNavbar";
import {
  verifyOtp,
  signInWithApple,
  signInWithGoogle,
  signUpCredentials,
  requestOtpSpecialCase,
} from "../../../../authService"; // Import the authService functions
import TextInput from "../../../../components/reusables/TextInput";
import { useFormik } from "formik";
import { getStartedValidationSchema } from "../../../../utils/ValidationSchemas/getStarted.validation";
import { ToastNotify } from "../../../../components/reusables/helpers/ToastNotify";
import { Helmet } from "react-helmet-async";
import { useUserValue } from "../../../../context/userContext"; // Import the user context

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loginType, setLoginType] = useState(1);
  const [error, setError] = useState("");
  const [otpModal, toggleOtpModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState(59);
  const [otp, setOtp] = useState("");
  const { currentUser } = useUserValue();
  const [load, toggleLoad] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const redirectTo = new URLSearchParams(location.search).get('redirect') || APP_PATHS.LANDING;

  useEffect(() => {
    if(currentUser){
      console.log("start useEffect");
      //TODO should take them to dashboard or account
      navigate(redirectTo);
    }else if (isNewUser){
      console.log("secondUse Effect");
      navigate(APP_PATHS.ONBOARDING_STEP_1);
      setIsNewUser(false);
    }
  },[ currentUser, navigate, redirectTo]);


  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
    },

    validationSchema: getStartedValidationSchema(loginType),
  });

  const { 
    handleChange, 
    handleBlur, 
    errors, 
    values, 
    touched, 
    dirty, 
    isValid 
  } = formik;

  // sign in with Apple
  const signInApple = useCallback(async () => {
    try {
      const response = await signInWithApple();
      if (response?.success && response?.isNewUser) {
        navigate(APP_PATHS.ONBOARDING_STEP_1);
      } else {
        window.location.reload();
      }
    } catch (err) {
      ToastNotify({
        type: "error",
        message: `Login failed: ${err.message}`,
        position: "top-right",
      });
    }
  }, [redirectTo, navigate]);

  //sign in with google
  const signInGoogle = useCallback(async () => {
    try {
      const response = await signInWithGoogle();
      if (response?.success && response?.isNewUser) {
        navigate(APP_PATHS.ONBOARDING_STEP_1);
      } else {
        console.log("here for SIGNiNGOOGLE")
        window.location.reload();
      }
      window.location.reload();
    } catch (err) {
      ToastNotify({
        type: "error",
        message: `Login failed: ${err.message}`,
        position: "top-right",
      });
    }
  }, [redirectTo, navigate]);

  // Function to handle sign up
  const handleSignUp = async () => {
    try {
      toggleLoad(true);
      const saveInfoResponse = await signUpCredentials(
        values?.email,
        values?.firstName,
        values?.lastName
      );
      if (saveInfoResponse?.success !== "false") {
        const setStatus = saveInfoResponse?.isNewUser
        setIsNewUser(setStatus);
        console.log("isNewUser in handleSignUp", setStatus)
        toggleOtpModal(true); // Open OTP modal after requesting OTP
        setTimeLeft(59);
      } else {
        ToastNotify({
          type: "error",
          message: saveInfoResponse.message,
          position: "top-right",
        });
      }
    } catch (error) {
      setError(error.message);
      ToastNotify({
        type: "error",
        message: error.message,
        position: "top-right",
      });
    }  finally {
      toggleLoad(false);
    }
  };

  // Function to handle SignIn
  const handleSignIn = async () => {
    try {
      toggleLoad(true)
      const response = await requestOtpSpecialCase(values?.email);
      if (response) {
        toggleOtpModal(true); // Open OTP modal after requesting OTP
        setTimeLeft(59);
      } else {
        ToastNotify({
          type: "error",
          message: response.message,
          position: "top-right",
        });
      }
    } catch (error) {
      setError(error.message);
      ToastNotify({
        type: "error",
        message: error.message,
        position: "top-right",
      });
     } finally {
      toggleLoad(false)
    }
  };

  // Function to handle OTP verification
  const handleOtpVerification = async () => {
    try {
      toggleLoad(true); //make the user make it look fast
      const response = await verifyOtp(values?.email, otp);
      if (response?.status) {
        ToastNotify({
          type: "success",
          message: response.message,
          position: "top-right",
        });
        sessionStorage.setItem("userEmail", values?.email);
        // OTP verification successful, navigate to the next page
        console.log("isNewUser in handleOtp", isNewUser)
        if (isNewUser) {
           console.log("should go to otp")
           navigate(APP_PATHS.ONBOARDING_STEP_1);
        } else {
          navigate(redirectTo); 
          window.location.reload();
        }
      } else {
        ToastNotify({
          type: "error",
          message: response.message,
          position: "top-right",
        });
      }
    } catch (error) {
      setError(error.message);
      ToastNotify({
        type: "error",
        message: error.message,
        position: "top-right",
      });
     } finally {
      toggleLoad(false);
    }
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  const resendCode = () => {
    // Logic to resend the code
    handleSignIn();
  };

  return (
    <>
      <Helmet>
        <title> Login, Sign up | Nezz</title>
      </Helmet>
      {/* {loading && <PageLoader />}  not used at all*/} 
      <div className="relative">
        <OnboardingNavbar />
      </div>
      <div className="min-h-screen w-full grid grid-cols-1 md:grid-cols-12 bg-white">
        <div className="bg-white h-full flex flex-col justify-between md:col-span-6 lg:col-span-6 items-center px-10 py-20">
          <div className="h-full flex flex-col justify-between max-w-sm w-full items-center space-y-8">
            <div className="space-y-8 w-full my-auto">
              <div className="flex justify-center mb-12 px-5">
                <div
                  className={`text-xl cursor-pointer text-center text-black border-b-4 ${
                    loginType === 1
                      ? "border-[#9747FF]"
                      : "border-none text-opacity-30"
                  } w-full font-semibold pb-2`}
                  onClick={() => setLoginType(1)}
                >
                  Sign Up
                </div>
                <div
                  className={`text-xl cursor-pointer text-center text-black border-b-4 ${
                    loginType === 2
                      ? "border-[#9747FF]"
                      : "border-none text-opacity-30"
                  } w-full font-semibold pb-2`}
                  onClick={() => setLoginType(2)}
                >
                  Sign In
                </div>
              </div>

              <div className="relative">
                <div
                  className={`transition-opacity duration-500 space-y-5 ${
                    loginType === 1 ? "opacity-100" : "opacity-0 absolute"
                  }`}
                >
                  {loginType === 1 && (
                    <>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                        <TextInput
                          name="firstName"
                          placeHolder="First Name"
                          value={values?.firstName}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          hasError={errors?.firstName && touched?.firstName}
                          error={errors?.firstName}
                        />
                        <TextInput
                          name="lastName"
                          placeHolder="Last Name"
                          value={values?.lastName}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          hasError={errors?.lastName && touched?.lastName}
                          error={errors?.lastName}
                        />
                      </div>
                      <TextInput
                        name="email"
                        placeHolder="Email"
                        value={values?.email}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        hasError={errors?.email && touched?.email}
                        error={errors?.email}
                      />
                    </>
                  )}
                </div>

                <div
                  className={`transition-opacity duration-500 ${
                    loginType === 2 ? "opacity-100" : "opacity-0 absolute"
                  }`}
                >
                  {loginType === 2 && (
                    <>
                      <TextInput
                        name="email"
                        placeHolder="Email"
                        value={values?.email}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        hasError={errors?.email && touched?.email}
                        error={errors?.email}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="text-center space-y-2">
                <CustomButton
                  labelText={load ? "Please wait":"Continue"}
                  containerVariant="text-sm px-5 py-3 rounded-full w-full"
                  variant="font-bold"
                  onClick={() =>
                    loginType === 1 ? handleSignUp() : handleSignIn()
                  }
                  isDisabled={!(dirty && isValid) || load }
                />
                <p>or</p>
                <CustomButton
                  labelText="Continue with Google"
                  containerVariant="text-sm px-5 py-3 rounded-full w-full"
                  variant="font-bold "
                  buttonVariant="tetiary"
                  icon={{
                    active: true, // Boolean to determine if the icon should be displayed
                    variant: "mr-2", // Any additional classes for styling the icon
                    preview: <Google />, // The icon component or SVG
                  }}
                  onClick={signInGoogle}
                />
                <CustomButton
                  labelText={"Continue with Apple"}
                  containerVariant="text-sm px-5 py-3 rounded-full w-full"
                  variant="font-bold "
                  buttonVariant="secondary"
                  icon={{
                    active: true, // Boolean to determine if the icon should be displayed
                    variant: "mr-2", // Any additional classes for styling the icon
                    preview: <Apple />, // The icon component or SVG
                  }}
                  onClick={signInApple}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="banner h-full w-full hidden md:flex flex-col justify-center pl-20 md:col-span-6 lg:col-span-6 px-5 py-20">
          {/* Used to balance the view. Do not remove */}
          <div />
          <div className="text-white">
            <div className="text-6xl font-semibold">Get Started</div>
            <div className="font-light mt-5">
              Hi there! <br /> You are one step away from gaining access
            </div>
          </div>
        </div>
      </div>

      {otpModal && (
        <CenterModal
          showCloseIcon={false}
          handleClose={() => toggleOtpModal(false)}
          allowOutsideClick={false}
        >
          <div className="text-center font-sfpro px-10">
            <div className="font-bold text-2xl">Verify Your Email</div>
            <div className="text-sm mt-2">
              A 6 digit passcode has been sent to your email. <br />
            </div>
            <div className="my-6">
              <CustomOTPInput numInputs={6} onChange={setOtp} />
            </div>
            <div className="text-sm">
              Did not receive any code? You can{" "}
              <span
                className={`underline ${
                  timeLeft > 0
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-[#9747FF] cursor-pointer"
                }`}
                onClick={timeLeft === 0 ? resendCode : null}
              >
                resend
              </span>{" "}
              once the timer is up
              <br />
              {`00:00:${timeLeft.toString().padStart(2, "0")}`}
            </div>
            <div className="mt-5">
              <CustomButton
                labelText={load ? "Please wait" : "Proceed"}
                containerVariant="text-sm px-5 py-3 rounded-full"
                variant="font-bold "
                buttonVariant="primary"
                onClick={handleOtpVerification}
                isDisabled={!(otp.length === 6) || load }
              />
            </div>
          </div>
        </CenterModal>
      )}
    </>
  );
};

export default Login;
