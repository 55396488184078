import React from "react";
import LogoBlack from "../../assets/svg/logoblack.png"; // Adjust the path and file type
import { useNavigate } from "react-router-dom";
import { APP_PATHS } from "../../routes/constants";

const OnboardingWrapper = ({ children }) => {
  const navigate = useNavigate();
  return (
    <>
      <nav className="sticky w-full bg-white py-3 px-3">
        <div
          onClick={() => navigate(APP_PATHS.LANDING)}
          className="max-w-7xl mx-auto flex items-center justify-between cursor-pointer"
        >
          <img src={LogoBlack} width={75} alt="" />
        </div>
      </nav>

      <div>{children}</div>
    </>
  );
};

export default OnboardingWrapper;
