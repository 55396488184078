// store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import stepReducer from "./redux/reducers/reducer";
import localforage from "localforage";
import { persistReducer, persistStore } from "redux-persist";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";

const persistConfig = {
  key: "nezzapp",
  storage: localforage,
  storeName: "nezzapp-store",
  version: 0,
};

const combinedReducer = combineReducers({
  step: stepReducer,
});

const persistedReducer = persistReducer(persistConfig, combinedReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
