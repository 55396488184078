import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as PendingIcon } from "../../assets/svg/error-icon.svg";
import { ReactComponent as Close } from "../../assets/svg/Close.svg";

const SimpleCardModal = ({
  icon = <PendingIcon />,
  title,
  subTitle,
  children,
  info,
  width = "auto", // Default width
}) => {
  return (
    <div
      className={`h-auto min-w-[550px] bg-[#ffffff] rounded-xl overflow-y-auto`}
      style={{
        boxShadow: "4px 4px 30px 0px rgba(0, 0, 0, 0.25)",
        width: width, // Set the width dynamically
      }}
    >
      <div className="mx-6 my-4">
        <div className="flex justify-between items-center">
          <p className="text-xl font-semibold text-left text-white">{title}</p>
        </div>
        <p className="text-[10px] font-normal text-left pt-0.5 text-nav-item-inactive">
          {subTitle}
        </p>
        <p className="text-xs text-lib-alat-black text-left">{info}</p>
        <div className="p-4 text-left">{children}</div>
      </div>
    </div>
  );
};

SimpleCardModal.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  children: PropTypes.node,
  info: PropTypes.string,
  width: PropTypes.string, // Define the prop type for width
};

export default SimpleCardModal;
