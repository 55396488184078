// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth"; 
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCPYeWhP0EsYhqGqiwAJt1MCz6cT_U4ydo",
  authDomain: "nezzapp-main.firebaseapp.com",
  projectId: "nezzapp-main",
  storageBucket: "nezzapp-main.appspot.com",
  messagingSenderId: "1076059995624",
  appId: "1:1076059995624:web:ed20cf20b9cc6840d6f85d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

const functions = getFunctions(app);
const db = getFirestore(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

export {auth, functions, storage, db, provider, appleProvider}; // Export the auth module for use in other files