import React, { useEffect, useState } from "react";
import SimpleCardModal from "../../../../components/Modal/SimpleCardModal";
import CustomSlider from "../../../../components/reusables/CustomSlider";
import { useFormik } from "formik";
import { CustomButton } from "../../../../components/buttons/CustomButton";
import { useDispatch } from "react-redux";
import RequireAuth from "../../../../components/RequireAuth";

import {
  previousStep,
  storeData,
} from "../../../../redux/reducers/reducer";
import { APP_PATHS } from "../../../../routes/constants";
import { useNavigate } from "react-router-dom";

const Step4 = ({ listingData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const formik = useFormik({
    initialValues: {
      threshold: 0,
      listType: "",
      privacySetting: "",
    },
  });

  const { values, setFieldValue, setValues, handleChange, handleBlur } = formik;

  const handleSliderChange = (newValue) => {
    setValue(newValue);
    setFieldValue("threshold", newValue);
  };

  useEffect(() => {
    if (listingData) {
      setFieldValue("threshold", listingData?.threshold);
      setValue(listingData?.threshold);
      setFieldValue("listType", listingData?.listType);
      setFieldValue("privacySetting", listingData?.privacySetting);
    }
  }, [listingData]);

  return (
    <RequireAuth>
    <div>
      <div className="w-full flex justify-center px-10 my-10">
        <SimpleCardModal width="650px">
          <>
            <div className="text-center">
              <h1 className="font-medium text-2xl">Listing Setup</h1>
              <div className="font-light leading-tight text-sm">
                You can add a threshold amount for a buyer to add into their
                wallet before they can <br /> message you. This would enable you
                weed out unserious buyers.
              </div>
            </div>

            <div className="my-10 px-10 space-y-5">
              <div className="space-y-3">
                <label className="text-sm font-medium" htmlFor="">
                  Threshold
                </label>
                <CustomSlider
                  value={value}
                  onChange={handleSliderChange}
                  price={listingData?.price}
                />
              </div>
              <div>
                <label className="text-sm font-medium">List Type</label>
                <div className="text-sm space-y-5 mt-2 ml-2">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="listType"
                      value="new"
                      checked={values?.listType === "new"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mr-2"
                    />
                    New
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="listType"
                      value="certified"
                      checked={values?.listType === "certified"}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    Certified
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="listType"
                      value="used"
                      checked={values?.listType === "used"}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    Used
                  </label>
                </div>
              </div>
              <div>
                <label className="text-sm font-medium">Privacy Settings</label>
                <p className="text-sm font-extralight">
                  Set who can see your personal information like name and
                  location
                </p>
                <div className="text-sm space-y-5 mt-2 ml-2">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="privacySetting"
                      value="Display my name and location"
                      checked={
                        values?.privacySetting ===
                        "Display my name and location"
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="mr-2"
                    />
                    Display my name and location
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="privacySetting"
                      value="Display only my username"
                      checked={
                        values?.privacySetting === "Display only my username"
                      }
                      onChange={handleChange}
                      className="mr-2"
                    />
                    Display only my username
                  </label>
                </div>
              </div>
            </div>

            <div className=" mt-5 flex flex-col items-center">
              <CustomButton
                labelText={"Continue 4/5"}
                containerVariant="text-sm px-5 py-1 rounded-full w-[50%]"
                variant="font-bold"
                buttonVariant="secondary"
                onClick={() => {
                  dispatch(storeData({ ...values }));
                  navigate(APP_PATHS.REVIEW_LISTING);
                }}
              />
              <button
                onClick={() => dispatch(previousStep())}
                className="mt-4 text-sm cursor-pointer"
              >
                Back
              </button>
            </div>
          </>
        </SimpleCardModal>
      </div>
    </div>
    </RequireAuth>
  );
};

export default Step4;
