import "./styles.css"; // Importing the CSS styles for the MoneyTransfer component
import CurrencySelect from "./components/CurrencySelect"; // Importing the CurrencySelect component for currency selection
import { CustomButton } from "../../../../components/buttons/CustomButton"; // Importing the CustomButton component
import React, { useState } from "react"; // Importing React and useState hook
import PropTypes from "prop-types"; // Importing PropTypes for type checking

const MoneyTransfer = ({
  // Functional component definition with props
  handleClose, // Function to close the modal
  variant, // Type of transfer (e.g., transfer, pay, add)
  bank, // Bank name for the transfer
  balance, // Current balance of the user
  transferVariant, // Variant of the transfer
  transferFailed, // Function to handle transfer failure
  verificationOpen, // Function to open verification modal
}) => {
  let title; // Variable to hold the title based on the transfer variant

  // Function to handle transfer failure and close the modal
  const failAndClose = () => {
    transferFailed(); // Call the transferFailed function
    handleClose(); // Close the modal
  };

  // Function to handle successful transfer and open verification modal
  const passAndClose = () => {
    verificationOpen(); // Open the verification modal
    handleClose(); // Close the modal
  };

  // Set title based on the variant
  if (variant === "transfer") {
    title = "Transfer Money to " + bank; // Title for transfer to a bank
  } else if (variant === "pay") {
    title = "Pay"; // Title for payment
  } else if (variant === "add") {
    title = "Transfer Money to Wallet"; // Title for adding money to wallet
  } else {
    title = "Default Title"; // Fallback title if none match
  }

  return (
    <div className="flex flex-col p-0">
      {/* Main container for the MoneyTransfer component */}
      <div className="w-full flex justify-center items-center text-[#000000] font-semibold text-[24px] h-[100px] shadow-xl">
        {" "}
        {/* Reduced height */}
        <h1 className="child">{title}</h1> {/* Displaying the title */}
      </div>
      <div className="bg-[#00000007] text-[#000000]">
        {/* Background for the transfer details */}
        <div className="flex justify-between mx-[60px] border-b-[1px] border-black flex-row text-[24px] mt-[20px]">
          {" "}
          {/* Reduced margins and font size */}
          <div>Total Balance</div> {/* Label for total balance */}
          <div>${balance}</div> {/* Displaying the current balance */}
        </div>
        <div className="text-[60px] flex flex-col items-center justify-center mt-[20px]">
          {" "}
          {/* Reduced margin */}
          <span className="text-shadow">${balance}</span>{" "}
          {/* Displaying the balance with shadow effect */}
          <div className="flex flex-row items-center gap-x-[40px] mt-[20px] justify-between">
            {" "}
            {/* Reduced gap */}
            <div className="text-[24px] font-medium">Amount</div>{" "}
            {/* Label for the amount to transfer */}
            <CurrencySelect
              containerVariant="w-[122px] flex flex-col text-white" // Styling for the currency select component
              options={["USD", "EUR", "CAD"]} // Available currency options
              placeholder="USD" // Placeholder for the currency select
            />
          </div>
          <div className="w-[696px] h-[100px] text-[16px] font-medium text-wrap mt-[20px]">
            {" "}
            {/* Reduced height and margin */}
            This is a one-time transaction. This transaction cannot be reversed.
            You can send up to $10,000. Money is typically available in their
            account in minutes. Once you've sent money, you can't cancel it.
          </div>
          <div className="flex flex-row gap-[20px] mb-4">
            {" "}
            {/* Reduced gap */}
            {/* Container for action buttons */}
            <CustomButton
              buttonVariant="tetiary" // Button variant for cancel button
              containerVariant="w-[180px] h-[48px] rounded-full flex text-[16px] font-bold justify-center" // Reduced button size
              labelText="Cancel" // Label for the button
              onClick={handleClose} // Function to call when the button is clicked
            />
            <CustomButton
              buttonVariant="secondary" // Button variant for continue button
              containerVariant="w-[180px] h-[48px] rounded-full flex text-[16px] font-bold justify-center" // Reduced button size
              labelText="Continue" // Label for the button
              onClick={
                balance >= 900 ? () => failAndClose() : () => passAndClose() // Conditional action based on balance
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// Prop type validation for the MoneyTransfer component
MoneyTransfer.propTypes = {
  handleClose: PropTypes.func.isRequired, // handleClose must be a function
  transferFailed: PropTypes.func.isRequired, // transferFailed must be a function
  verificationOpen: PropTypes.func.isRequired, // verificationOpen must be a function
  variant: PropTypes.string.isRequired, // variant must be a string
  bank: PropTypes.string.isRequired, // bank must be a string
  balance: PropTypes.number.isRequired, // balance must be a number
  transferVariant: PropTypes.string.isRequired, // transferVariant must be a string
};

export default MoneyTransfer; // Exporting the MoneyTransfer component
