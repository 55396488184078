import React from "react";
import Preloader from "../mics/Preloader";

export const CustomButton = ({
  labelText,
  variant = "font-medium",
  containerVariant = "py-4 px-5 rounded-full flex justify-center",
  buttonVariant = "primary",
  isDisabled = false,
  isLoading = false,
  icon,
  onClick, // Step 1: Add onClick prop
}) => {
  // Step 3: Define click handler function
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  // Define styles for each state and variant
  const styles = {
    primary: {
      enabled:
        "bg-[#9747FF] hover:bg-[#9747fff3] text-white py-3 cursor-pointer text-center",
      disabled: "bg-[#c5a3ff] text-[#e0d4ff] cursor-not-allowed py-3", // Lighter shade for disabled state
    },
    secondary: {
      enabled:
        "bg-[#000000] border-[1.5px] border-[#000000] text-[#ffffff] py-3 cursor-pointer",
      disabled:
        "border-[1.5px] border-[#E0B8C4] text-[#E0B8C4] cursor-not-allowed py-3",
    },
    tetiary: {
      enabled:
        "bg-[#ffffff] border-[1.5px] border-[#000000] text-[#000000] py-3 cursor-pointer",
      disabled:
        "bg-[#ffffff] border-[1.5px] border-[#d3d3d3] text-[#d3d3d3] cursor-not-allowed py-3",
    },
    tag: {
      enabled:
        "bg-[#ffffff] border-[1.5px] border-[#111111bf191] text-[#9747FF] py-3 cursor-pointer",
      disabled:
        "bg-[#ffffff] border-[1.5px] border-[#d3d3d3] text-[#d3d3d3] cursor-not-allowed py-3",
    },
  };

  // Determine the appropriate style based on the button state and variant
  const currentStyle = isDisabled
    ? styles[buttonVariant].disabled
    : styles[buttonVariant].enabled;

  return (
    <button
      type="submit"
      className={`${variant} shadow-md ${currentStyle} ${containerVariant}`}
      disabled={isDisabled}
      onClick={handleClick} // Step 2: Pass onClick prop
    >
      <div className="flex justify-center items-center text-center">
        {icon?.active && (
          <div className={`${icon.variant}`}>{icon.preview}</div>
        )}
        {isLoading ? <Preloader variant="w-6 h-6" /> : labelText}
      </div>
    </button>
  );
};
