import TwitterCLDR from "twitter_cldr/full/core";
import TwitterCLDRDataBundle from "twitter_cldr/full/en";

TwitterCLDR.set_data(TwitterCLDRDataBundle);


/**
 * Takes in countries array and returns the countries array with their flags
 *
 * @param {Array} arr countries array
 * @param {string} valueKey key to be converted
 * @param {string} codeKey country code to be converted
 * @returns {Array} the countries result array
 */
export const createCustomCountryOptions = (arr, valueKey, codeKey) => {
  if (!Array.isArray(arr)) return [];

  return arr.reduce((acc, curr) => {
    const value = {
      className: `flag flag-${curr[codeKey]?.toLowerCase()}`,
      label: curr[valueKey],
      value: curr[valueKey],
    };
    acc.push(value);
    return acc;
  }, []);
};

/**
 * An object of currency ENUMs for ALAT.
 */
export const currencyENUMs = {
  1: "NGN",
  2: "USD",
  3: "GBP",
  4: "EUR",
  5: "JPY",
  6: "GHC",
  CAD: "CAD",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  NGN: "NGN",
  USD: "USD",
};

/**
 * Format number with desired currency
 *
 * @param {object} args The arguments for the function
 * @param {number | string} args.number The number to be converted
 * @param {string} args.currencyCode The currecncy code to use
 * @param {string} args.precision The number of decimal places
 * @returns {string} String number containing currency
 */
export const formatNumberToCurrency = ({
  number, currencyCode = 1, precision = 2,
}) => {
  const formatter = new TwitterCLDR.CurrencyFormatter();

  return formatter.format(Number(number), {
    currency: currencyENUMs[currencyCode], precision,
  });
};

export const stringToNumber = (str)=> {
  // Remove commas from the string
  const cleanedStr = str.replace(/,/g, '');
  // Convert the cleaned string to a number
  const number = Number(cleanedStr);
  
  // Alternatively, you can use parseInt if you prefer
  // const number = parseInt(cleanedStr, 10);
  
  return number;
}

export const convertImageToBase64 = (file)=> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};