import React, { useEffect, useState } from "react";
import LogoBlack from "../assets/svg/logoblack.png"; // Adjust the path and file type
import { useNavigate } from "react-router-dom";
import { APP_PATHS } from "../routes/constants";
import SearchInput from "./reusables/SearchInput";
import { ReactComponent as Home } from "../assets/svg/home icon.svg";
import { ReactComponent as Chat } from "../assets/svg/chat icon.svg";
import { ReactComponent as Wallet } from "../assets/svg/wallet icon.svg";
import { ReactComponent as List } from "../assets/svg/add icon.svg";
import { ReactComponent as Profile } from "../assets/svg/profile icon.svg";
import { ReactComponent as Hamburger } from "../assets/svg/menu.svg";
import { ReactComponent as Close } from "../assets/svg/close-square.svg";

const AppWrapper = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState("");
  const [menuOpen, setMenuOpen] = useState(false); // State to manage menu visibility

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="relative w-full h-full font-sfpro">
      <nav className="sticky z-20 flex justify-between items-center bg-white py-3 mx-10">
        <div className="flex items-center gap-5">
          <div
            onClick={() => navigate(APP_PATHS.LANDING)}
            className="max-w-7xl flex items-center justify-between cursor-pointer"
          >
            <img src={LogoBlack} width={75} alt="" />
          </div>
          <div className="w-[40vw]">
            <SearchInput
              name="search"
              placeHolder="Search"
              handleChange={(e) => setSearchParams(e.target.value)}
              value={searchParams}
            />
          </div>
        </div>
        <div className="flex items-center">
          <ul className="hidden md:flex text-sm gap-10 items-center">
            <li
              className="cursor-pointer flex flex-col items-center"
              onClick={() => navigate(APP_PATHS.LANDING)}
            >
              <Home />
              <span className="text-xs text-center">Home</span>
            </li>
            <li className="cursor-pointer flex flex-col items-center">
              <Wallet />
              <span className="text-xs text-center">Wallet</span>
            </li>
            <li
              className="cursor-pointer flex flex-col items-center"
              onClick={() => navigate(APP_PATHS.LISTING_MAIN)}
            >
              <List />
              <span className="text-xs text-center">List</span>
            </li>
            <li className="cursor-pointer flex flex-col items-center">
              <Chat />
              <span className="text-xs text-center">Chat</span>
            </li>
            <li className="cursor-pointer flex flex-col items-center">
              <Profile />
              <span className="text-xs text-center">Profile</span>
            </li>
          </ul>
          <div className="md:hidden flex items-center">
            {menuOpen ? (
              <Close className="cursor-pointer" onClick={toggleMenu} />
            ) : (
              <Hamburger className="cursor-pointer" onClick={toggleMenu} />
            )}
          </div>
        </div>
      </nav>
      {menuOpen && (
        <div
          className="md:hidden z-10 w-auto h-auto rounded-xl absolute right-5 items-center bg-white py-3"
          style={{
            boxShadow: "4px 4px 30px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <ul className="p-6 w-full grid grid-cols-3 gap-5 items-center">
            <li
              className="cursor-pointer hover:bg-slate-100 p-3 rounded mx-auto"
              onClick={() => navigate(APP_PATHS.LANDING)}
            >
              <Home />
              <span className="text-xs text-center">Home</span>
            </li>
            <li className="cursor-pointer hover:bg-slate-100 p-3 rounded mx-auto">
              <Wallet />
              <span className="text-xs">Wallet</span>
            </li>
            <li
              onClick={() => navigate(APP_PATHS.LISTING_MAIN)}
              className="cursor-pointer hover:bg-slate-100 p-3 rounded mx-auto"
            >
              <List />
              <span className="text-xs text-center">List</span>
            </li>
            <li className="cursor-pointer hover:bg-slate-100 p-3 rounded mx-auto">
              <Chat />
              <span className="text-xs text-center">Chat</span>
            </li>
            <li className="cursor-pointer hover:bg-slate-100 p-3 rounded mx-auto">
              <Profile />
              <span className="text-xs text-center">Profile</span>
            </li>
          </ul>
        </div>
      )}
      <div className="mt-6">{children}</div>
    </div>
  );
};

export default AppWrapper;
