import PropTypes from "prop-types"; // Add this import at the top

const Verification = ({ handleClose, openNextModal }) => {
  // Function to handle the next step in the verification process
  const nextStep = () => {
    openNextModal(); // Call the function to open the next modal
    handleClose(); // Close the current verification modal
  };

  Verification.propTypes = {
    // Add prop types validation
    handleClose: PropTypes.func.isRequired,
    openNextModal: PropTypes.func.isRequired,
  };

  return (
    <div className="flex flex-col relative w-full">
      <div className="flex flex-col justify-center w-full">
        <div className="text-[24px] font-medium text-center mt-5">
          Verification {/* Title for the verification modal */}
        </div>
        <div className="text-[20px] text-wrap mx-auto mt-[40px] w-[470px]">
          You are about to transfer $500.00 from Chase to Wallet on May 17, 2024
          {/* Information about the transfer being verified */}
        </div>
        <div className="text-[18px] text-wrap mx-auto mt-[40px] w-[470px] text-center">
          Do You Want to Continue? {/* Prompt for user confirmation */}
        </div>
        <div className="flex flex-row justify-center mt-[25px] border-t-[1px] border-black">
          <div
            onClick={handleClose} // Close the modal when "No" is clicked
            className="w-full text-[36px] py-[10px] font-medium text-[#9747FF] text-center hover:bg-[#B8B8B8] cursor-pointer transition-all duration-500"
          >
            No {/* Button for declining the transfer */}
          </div>
          <div className="w-[1px] border-[1px] border-black"></div>
          <div
            className="w-full items-center justify-center text-[36px] py-[10px] font-medium text-[#9747FF] text-center hover:bg-[#B8B8B8] cursor-pointer transition-all duration-500"
            onClick={nextStep} // Proceed to the next step when "Yes" is clicked
          >
            Yes {/* Button for confirming the transfer */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification; // Export the Verification component
