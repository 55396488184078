import React, { useState, useEffect, useMemo, useRef } from "react";
import TextInput from "../../../components/reusables/TextInput";
import { CustomButton } from "../../../components/buttons/CustomButton";
import { ReactComponent as Profile } from "../../../assets/svg/profile-circle.svg";
import { APP_PATHS } from "../../../routes/constants";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useUserValue } from "../../../context/userContext";
import useAccountDetails from "../../../utils/hooks/useAccountDetails";
import { countryCodes, businessTypes } from "../constants";
import SelectField from "../../../components/reusables/SelectField";
import {
  convertImageToBase64,
  createCustomCountryOptions,
} from "../../../utils/libs";
import { OnboardingValidationSchema } from "../../../utils/ValidationSchemas/getStarted.validation";
import { userInfoStore } from "../storeOnboardingData";
import { ToastNotify } from "../../../components/reusables/helpers/ToastNotify";
import { Helmet } from "react-helmet-async";
import OnboardingWrapper from "../OnboardingWrapper";
import { uploadProfileImageToStorage } from "../../Listing/StoreListingData";
//import InputMask from "react-input-mask";

const Step1 = () => {
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const { getUser, currentUser } = useAccountDetails();
  const [load, toggleLoad] = useState(false);
  const { step1Data, setStep1Data } = useUserValue();
  const [selectedImage, setSelectedImage] = useState();
  const [previousImageUrl, setPreviousImageUrl] = useState();
  const userEmail = sessionStorage.getItem("userEmail");

  useEffect(() => {
    getUser();
    setPreviousImageUrl(currentUser?.photoURL);
  }, []);

  const countryCodeData = useMemo(
    () =>
      JSON.parse(countryCodes)?.map((x) => ({
        country: x.name,
        id: x.code,
        value: x.dial_code,
        valueLabel: `${x.name} ${"      "} ${x.dial_code}`,
      })),
    [countryCodes]
  );

  const sortedCountryCodes = useMemo(
    () =>
      countryCodeData?.sort(
        (a, b) => parseFloat(a?.value) - parseFloat(b?.value)
      ),
    [countryCodeData]
  );

  const initialValues = {
    fullName: step1Data?.fullName || currentUser?.displayName || "",
    userName: step1Data?.userName || currentUser?.userName || "",
    dob: step1Data?.dob || currentUser?.dob || "",
    countryCode: step1Data?.countryCode || currentUser?.countryCode || "",
    phoneNumber: step1Data?.phoneNumber || currentUser?.phoneNumber || "",
    city: step1Data?.city || currentUser?.city || "",
    zipCode: step1Data?.zipCode || currentUser?.zipCode || "",
    businessName: step1Data?.businessName || currentUser?.businessName || "",
    businessType: step1Data?.businessType || currentUser?.businessType || "",
    businessNumber:
      step1Data?.businessNumber || currentUser?.businessNumber || "",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const profileImg = selectedImage
        ? await uploadProfileImageToStorage(selectedImage, "profile", previousImageUrl)
        : currentUser?.photoURL;

      try {
        toggleLoad(true);
        setStep1Data(values);
        await userInfoStore(
          userEmail,
          values.fullName,
          values.userName,
          values.dob,
          values.countryCode,
          values.phoneNumber,
          values.city,
          values.zipCode,
          values.businessName,
          values.businessNumber,
          values.businessType,
          selectedImage ? profileImg[0] : profileImg,
        );
        setPreviousImageUrl(profileImg[0]);
        navigate(APP_PATHS.ONBOARDING_STEP_2);
      } catch (error) {
        ToastNotify({
          type: "error",
          message: "Failed to Submit form data",
          position: "top-right",
        });
      } finally {
        toggleLoad(false);
      }
    },

    validationSchema: OnboardingValidationSchema,
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isValid,
    dirty,
    errors,
    setFieldValue,
  } = formik;

  const handleImageSelection = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const base64String = await convertImageToBase64(file);
        return base64String;
      } catch (error) {
        console.error("Error converting image to base64:", error);
        return null;
      }
    } else {
      return null;
    }
  };

  const handleFileChange = async (event) => {
    const base64String = await handleImageSelection(event);
    setSelectedImage([base64String]);
  };

  const handleUpload = () => {
    fileInputRef.current.click();
  };

  // useEffect(() => {
  //   setFieldValue("fullName", currentUser?.displayName);
  //   const fields = [
  //     "userName",
  //     "dob",
  //     "countryCode",
  //     "phoneNumber",
  //     "city",
  //     "zipCode",
  //     "businessName",
  //     "businessType",
  //     "businessNumber",
  //   ];
  //   fields.forEach((field) => setFieldValue(field, currentUser?.[field]));
  // }, [currentUser, setFieldValue]);

  return (
    <>
      <Helmet>
        <title> Onboarding 1/3 | Nezz </title>
      </Helmet>
      <OnboardingWrapper>
        <div className="min-h-screen flex items-center justify-center">
          <div className="text-center rounded-lg w-full max-w-lg">
            <div className="mb-10">
              <h1 className="text-2xl font-semibold">Welcome to Nezz</h1>
              <p className="text-xs">Tell us a bit about yourself</p>
            </div>
            <div className="space-y-5">
              <div className="flex justify-center items-center gap-3 flex-col">
                {selectedImage ? (
                <img
                  src={selectedImage[0]}
                  alt="Profile"
                  className="w-24 h-24 rounded-full object-cover"
                />
              ) : currentUser?.photoURL ? (
                <img
                  src={currentUser.photoURL}
                  alt="Profile"
                  className="w-24 h-24 rounded-full object-cover"
                />
              ) : (
                <Profile />
              )}
                <p onClick={handleUpload} className="text-xs cursor-pointer">
                 <u> Add Logo or photo </u>
                </p>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden"
                  ref={fileInputRef}
                />
              </div>

              <div className="relative px-10">
                <div
                  className={"transition-opacity duration-500 space-y-5 w-full"}
                >
                  <>
                    <TextInput
                      name="fullName"
                      label="Full Name"
                      placeHolder="Full Name"
                      error={errors?.fullName}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values?.fullName}
                      hasError={errors?.fullName}
                    />

                    <TextInput
                      name="userName"
                      label="Username"
                      placeHolder="Username"
                      error={errors?.userName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values?.userName}
                      hasError={errors?.userName}
                    />

                    <TextInput
                      name="dob"
                      placeHolder="Date of Birth"
                      label="Date of Birth"
                      type="date"
                      error={errors?.dob}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values?.dob}
                    />

                    <div className="mx-auto w-full grid grid-cols-3 justify-center items-center gap-5">
                      <div className="col-span-1">
                        <SelectField
                          name="countryCode"
                          options={createCustomCountryOptions(
                            sortedCountryCodes,
                            "value",
                            "id"
                          )}
                          label="Country Code"
                          onChange={handleChange}
                          value={values?.countryCode}
                          onBlur={handleBlur}
                          hasError={!values?.countryCode}
                          error={errors?.countryCode}
                          placeHolder="+1"
                          isCountrySelect
                        />
                      </div>
                      <div className="col-span-2">
                        <TextInput
                          name="phoneNumber"
                          placeHolder="Phone Number"
                          label="Phone Number"
                          error={errors?.phoneNumber}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values?.phoneNumber}
                          hasError={errors?.phoneNumber}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                      <TextInput
                        name="city"
                        placeHolder="City"
                        label="City"
                        handleChange={handleChange}
                        error={errors?.city}
                        handleBlur={handleBlur}
                        value={values?.city}
                        hasError={errors?.city}
                      />

                      <TextInput
                        name="zipCode"
                        placeHolder="Zip/Postal Code"
                        label="Zip/Postal Code"
                        handleChange={handleChange}
                        error={errors?.zipCode}
                        handleBlur={handleBlur}
                        value={values?.zipCode}
                        hasError={errors?.zipCode}
                      />
                    </div>

                    <TextInput
                      name="businessName"
                      placeHolder="Business Name"
                      label="Business Name"
                      handleChange={handleChange}
                      value={values?.businessName}
                    />
                    <TextInput
                      name="businessNumber"
                      placeHolder="Business Number"
                      label="Business Number"
                      handleChange={handleChange}
                      value={values?.businessNumber}
                    />
                    <SelectField
                      name="businessType"
                      placeHolder="Business Type"
                      options={businessTypes}
                      label="Business Type"
                      onChange={handleChange}
                      value={values?.businessType}
                    />
                  </>
                </div>
              </div>
              <div className="mx-auto text-center space-y-2 px-10 pt-5">
                <CustomButton
                  labelText={load ? "Please wait" : "Continue 1/3"}
                  containerVariant="text-sm px-5 py-3 rounded-full w-full"
                  variant="font-bold"
                  buttonVariant="secondary"
                  onClick={handleSubmit}
                  isDisabled={load}
                />
              </div>
            </div>
          </div>
        </div>
      </OnboardingWrapper>
    </>
  );
};

export default Step1;
