import React from "react";
import AppWrapper from "../../../components/AppWrapper";
import { Helmet } from "react-helmet-async";

const NotFound = () => {
  return (
    <>
    <Helmet>
        <title> Page Not Found | Nezz </title>
      </Helmet>
    <AppWrapper>
      <div className="min-h-[70vh] flex items-center justify-center">
        <h1>404 - Page Not Found</h1>
      </div>
    </AppWrapper>
    </>
  );
};

export default NotFound;