import { db } from "../../firebaseConfig";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";

// to store update the user's information
export const userInfoStore = async (
  email,
  fullName,
  userName,
  dob,
  countryCode,
  phoneNumber,
  city,
  zipCode,
  businessName,
  businessNumber,
  businessType,
  photoUrl
) => {
  try {
    const userRef = doc(db, "users", email);
    // Prepare the data to be updated

    const updateData = {
      fullName: fullName,
      userName: userName,
      dob: dob,
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      city: city,
      zipCode: zipCode,
      businessNumber: businessNumber,
      businessName: businessName,
      businessType: businessType,
      photoURL: photoUrl
    };
    // Set businessAccount to true if businessName is not null
    if (businessName) {
      updateData.businessAccount = true;
    } else {
      updateData.businessAccount = false;
    }
    // This section updates the database with the entered information
    await updateDoc(userRef, updateData, { merge: true });

    return { success: true, message: "User information updated successfully" };
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while updating user information",
    };
  }
};

// to store and update the user's interests into the database
export const userInterestsStore = async (interests) => {
  try {
    const email = sessionStorage.getItem("userEmail");
    const interestRef = doc(db, "interests", email);
    const docSnap = await getDoc(interestRef);
    if (!docSnap.exists()) {
      await setDoc(interestRef, { Interests: interests });
    } else {
      await updateDoc(interestRef, { Interests: interests }, { merge: true });
    }
    return { success: true, message: "User interests updated successfully" };
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while updating user interests",
    };
  }
};
