import React, { useContext, useEffect, useState } from "react";
import useAccountDetails from "../utils/hooks/useAccountDetails";

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const userEmail = sessionStorage.getItem("userEmail");
  const { getUser, currentUser, getUserInterest, userInterests } =
    useAccountDetails();
  const [step1Data, setStep1Data] = useState(null);
  const [step2Data, setStep2Data] = useState(null);

  useEffect(() => {
    if (userEmail) {
      getUser();
    }
  }, [userEmail]);
  
  return (
    <UserContext.Provider
      value={{
        currentUser,
        step1Data,
        setStep1Data,
        step2Data,
        setStep2Data,
        getUserInterest,
        userInterests,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserValue = () => {
  return useContext(UserContext);
};
