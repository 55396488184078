import React from "react";
import LogoBlack from "../../../assets/svg/logoblack.png"; // Adjust the path and file type
import { APP_PATHS } from "../../../routes/constants";
import { useNavigate } from "react-router-dom";

const OnboardingNavbar = () => {
  const navigate = useNavigate();
  return (
    <nav className="absolute top-0 left-0 w-full bg-white py-3 px-3">
      <div
        onClick={() => navigate(APP_PATHS.LANDING)}
        className="max-w-7xl mx-auto flex items-center justify-between cursor-pointer"
      >
        <img src={LogoBlack} width={75} alt="" />
      </div>
    </nav>
  );
};

export default OnboardingNavbar;
