import React, { useState, useRef } from "react";

const CustomOTPInput = ({ numInputs = 4, onChange }) => {
  const [otp, setOtp] = useState(Array(numInputs).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (index, value) => {
    if (isNaN(value)) return; // Prevent non-numeric input
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    onChange(newOtp.join(""));
    if (value && index < numInputs - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className="flex justify-center items-center">
    {otp.map((_, index) => (
      <input
        key={index}
        ref={(el) => (inputRefs.current[index] = el)}
        type="string"
        className="w-12 h-12 text-4xl mx-[6px] bg-[#D9D9D9] text-center shadow-lg rounded focus:outline-none focus:border-[#000] focus:w-14 focus:h-14"
        value={otp[index]}
        onChange={(e) => handleChange(index, e.target.value)}
        onKeyDown={(e) => handleKeyDown(index, e)}
        maxLength={1}
      />
    ))}
  </div>
  );
};

export default CustomOTPInput;
