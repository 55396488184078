import RequireAuth from "../../components/RequireAuth"; // Component to enforce authentication before accessing the Dashboard
import { Helmet } from "react-helmet-async"; // Library for managing document head in React
import AppWrapper from "../../components/AppWrapper"; // Wrapper component for the Dashboard layout
import { ReactComponent as ProfileCircle } from "../../assets/svg/profile-circle.svg"; // SVG icon for the profile circle
import { ReactComponent as ProfileLight } from "../../assets/svg/profile-light.svg"; // SVG icon for profile settings
import { ReactComponent as Security } from "../../assets/svg/security-icon.svg"; // SVG icon for security settings
import { ReactComponent as Notification } from "../../assets/svg/notification.svg"; // SVG icon for notification settings
import { ReactComponent as Privacy } from "../../assets/svg/privacy.svg"; // SVG icon for privacy settings
import { ReactComponent as Wallet } from "../../assets/svg/wallet-light.svg"; // SVG icon for wallet settings
import { ReactComponent as QuestionMark } from "../../assets/svg/question-mark.svg"; // SVG icon for help and support
import { ReactComponent as TermsNPolicies } from "../../assets/svg/terms-policies.svg"; // SVG icon for terms and policies
import { ReactComponent as Tickbox } from "../../assets/svg/tickbox.svg"; // SVG icon for incomplete tasks
import { ReactComponent as TickboxFilled } from "../../assets/svg/tickboxfilled.svg"; // SVG icon for completed tasks
import { ReactComponent as ListedItems } from "../../assets/svg/listed-items.svg"; // SVG icon for listed items
import { ReactComponent as SoldItems } from "../../assets/svg/sold-items.svg"; // SVG icon for sold items
import { ReactComponent as BoughtItems } from "../../assets/svg/bought-items.svg"; // SVG icon for bought items
import Rolex from "./assets/rolex.png"; // Image of Rolex watch
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  // Array of items to be displayed in the Dashboard
  const items = Array(9).fill({
    name: "Rolex Submariner", // Name of the item
    variant: "(Gold)", // Variant of the item
    price: "$15,699", // Price of the item
    stock: "In stock: 10", // Stock information
    imageSrc: Rolex, // Source of the item image
    imageAlt: "Rolex", // Alt text for the item image
  });

  return (
    // Enforces authentication; wraps the entire Dashboard component
    <RequireAuth>
      {/* Sets the title of the document */}
      <Helmet>
        <title>Dashboard | Nezz</title>
      </Helmet>

      {/* Wrapper component providing layout for the Dashboard */}
      <AppWrapper>
        <div className="lg:pl-[54px] pl-4 pb-[183px] flex flex-col">
          {/* Main content of the Dashboard, divided into sections */}
          <div className="flex flex-row mt-8 justify-between gap-4">
            {/* Sidebar with user profile and navigation options */}
            <div className="h-[835px] border-[#D9D9D9] border-[1px] w-[249px] shadow-xl rounded-xl items-center">
              {/* Profile section with circular profile icon */}
              <div className="relative mx-auto mt-[38px] w-[75px] h-[75px]">
                <ProfileCircle
                  className="relative w-full h-full"
                  stroke="#FFFFFF"
                />
                <div
                  className="absolute inset-0 m-auto w-[83%] h-[83%]"
                  style={{
                    background: "linear-gradient(to bottom, #AED259, #596C2E)", // Gradient background
                    borderRadius: "50%", // Circular shape
                    zIndex: -1, // Background behind the profile icon
                  }}
                ></div>
              </div>

              {/* Display username and handle */}
              <div className="lg:text-[24px] text-14 font-bold text-center mt-12">
                SilverSphinx
              </div>
              <div className="lg:text-[18px] text-sm text-center">@ssphinx</div>

              {/* Navigation options for Support & About section */}
              <div className="flex flex-col items-center text-[12px] mt-[40px]">
                <div className="flex flex-col px-4 lg:text-xs text-[10px]">
                  <div className="font-bold">Support & About</div>
                  <div className="flex flex-col lg:w-[187px] w-[150px] h-[172px] bg-[#2427600D] rounded-lg mt-[12px]">
                    <div className="flex flex-col h-[142px] justify-between mt-[12px]">
                      <div
                        className="flex flex-row items-center gap-3 pl-4"
                        onClick={() => navigate("/dashboard/account")}
                      >
                        <ProfileLight className="w-4 h-4" />
                        <div className="font-bold hover:font-extrabold transition-all duration-300 cursor-pointer hover:text-[#9747FF]">
                          Edit Profile
                        </div>
                      </div>
                      <div className="flex flex-row items-center gap-3 pl-4">
                        <Security className="w-4 h-5" />
                        <div className="font-bold">Security</div>
                      </div>
                      <div className="flex flex-row items-center gap-3 pl-4">
                        <Notification className="w-4 h-5" />
                        <div className="font-bold">Notifications</div>
                      </div>
                      <div className="flex flex-row items-center gap-3 pl-4">
                        <Privacy className="w-4 h-5" />
                        <div className="font-bold">Privacy</div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Additional options for Wallet and Help */}
                <div className="flex flex-col lg:text-xs text-[10px] px-5">
                  <div className="mt-3 font-bold">Support & About</div>
                  <div className="flex flex-col lg:w-[187px] w-[150px] h-[132px] bg-[#2427600D] rounded-lg mt-[12px]">
                    <div className="flex flex-col h-[103px] justify-between mt-[12px]">
                      <div className="flex flex-row items-center gap-3 pl-4">
                        <Wallet className="w-5 h-4" />
                        <div className="font-bold">Wallet</div>
                      </div>
                      <div className="flex flex-row items-center gap-3 pl-4">
                        <QuestionMark className="w-5 h-5" />
                        <div className="font-bold">Help & Support</div>
                      </div>
                      <div className="flex flex-row items-center gap-3 pl-4">
                        <TermsNPolicies className="w-5 h-5" />
                        <div className="font-bold">Terms & Policies</div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Cache & Cellular options and member information */}
                <div className="flex flex-col lg:text-xs text-[10px] justify-center px-5">
                  <div className="mt-3 font-bold">Cache & Cellular</div>
                  <div className="flex flex-col lg:w-[187px] w-[150px] h-[94px] bg-[#2427600D] rounded-lg mt-[12px]">
                    <div className="flex flex-col h-[64px] justify-between mt-[12px]">
                      <div className="flex flex-row items-center gap-3 pl-4">
                        <Wallet className="w-5 h-4" />
                        <div className="font-bold">Wallet</div>
                      </div>
                      <div className="flex flex-row items-center gap-3 pl-4">
                        <QuestionMark className="w-5 h-5" />
                        <div className="font-bold">Help & Support</div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-10 text-[11px]">
                    Member since <b>29th September 2023</b>
                  </div>
                </div>
              </div>
            </div>

            {/* Main content area of the Dashboard */}
            <div className="w-[75%] lg:px-5 flex-col flex lg:gap-6 gap-2">
              {/* Account setup progress section */}
              <div className="w-full h-[141px] lg:text-lg text-[12px] border-[1px] pt-4 px-5 flex flex-col shadow-lg border-[#D9D9D9] rounded-xl">
                <div>Account Setup</div>
                <div className="flex flex-row lg:gap-[34px] gap-2 lg:justify-normal justify-between pt-3">
                  <div className="flex lg:flex-row flex-col text-center gap-2 items-center">
                    <TickboxFilled /> {/* Icon for completed task */}
                    <div>Completed Profile</div>
                  </div>
                  <div className="flex lg:flex-row flex-col gap-2 text-center items-center">
                    <Tickbox /> {/* Icon for incomplete task */}
                    <div>Linked Wallet</div>
                  </div>
                  <div className="flex lg:flex-row flex-col gap-2 text-center items-center">
                    <Tickbox />
                    <div>Fund Wallet</div>
                  </div>
                  <div className="flex lg:flex-row flex-col gap-2 text-center items-center">
                    <Tickbox />
                    <div>Select Pricing</div>
                  </div>
                </div>
                <div className="text-[14px] lg:block hidden text-[#9747FF] font-normal mt-2">
                  Fund your wallet to negotiate with sellers
                </div>
              </div>

              {/* Summary of listed, sold, and bought items */}
              <div className="flex lg:flex-row flex-col justify-between gap-[22px] w-full">
                <div className="border-[#D9D9D9] flex flex-row gap-6 items-center justify-center rounded-xl lg:w-[224px] h-[104px] shadow-xl border-[1px]">
                  <ListedItems />
                  <div className="flex flex-col">
                    <p className="text-[24px] font-bold">23</p>
                    <p className="text-[14px] font-light">Listed Items</p>
                  </div>
                </div>
                <div className="border-[#D9D9D9] flex flex-row gap-6 items-center justify-center rounded-xl lg:w-[224px] h-[104px] shadow-xl border-[1px]">
                  <SoldItems />
                  <div className="flex flex-col">
                    <p className="text-[24px] font-bold">23</p>
                    <p className="text-[14px] font-light">Sold Items</p>
                  </div>
                </div>
                <div className="border-[#D9D9D9] flex flex-row gap-6 items-center justify-center rounded-xl lg:w-[224px] h-[104px] shadow-xl border-[1px]">
                  <BoughtItems />
                  <div className="flex flex-col">
                    <p className="text-[24px] font-bold">23</p>
                    <p className="text-[14px] font-light">Bought Items</p>
                  </div>
                </div>
                <div className="border-[#D9D9D9] flex flex-row gap-6 items-center justify-center rounded-xl lg:w-[224px] h-[104px] shadow-xl border-[1px]">
                  <BoughtItems />
                  <div className="flex flex-col">
                    <p className="text-[24px] font-bold">23</p>
                    <p className="text-[14px] font-light">Waitlist</p>
                  </div>
                </div>
              </div>

              {/* Tabs for Listings and Saved Items */}
              <div className="flex flex-row w-full items-center gap-[35px] text-[17px]">
                <p className="border-b-[3px] border-[#ffffff] hover:border-[#9747FF] lg:max-w-full transition-all duration-500 hover:cursor-pointer">
                  You Listing
                </p>
                <div className="border-b-[3px] border-[#9747FF]">
                  Saved Items
                </div>
              </div>

              {/* List of items in the Dashboard */}
              <div className="flex lg:flex-row md:flex-row lg:justify-between flex-wrap gap-y-5 gap-x-5 flex-col lg:overflow-visible overflow-y-scroll w-full h-[160px]">
                {items.map((item, index) => (
                  <div
                    key={index}
                    className="border-[#D9D9D9] flex flex-row items-center justify-start pl-3 gap-[10px] rounded-xl w-[250px] h-[143px] lg:shadow-xl shadow-sm border-[1px]"
                  >
                    <img
                      src={item.imageSrc}
                      alt={item.imageAlt}
                      className="w-[100px] h-[116px]"
                    />
                    <div className="flex flex-col gap-[-5px] w-[150px]">
                      <p className="text-[16px] font-bold text-wrap">
                        {item.name}
                      </p>
                      <p className="text-[16px] font-bold text-wrap">
                        {item.variant}
                      </p>
                      <p className="text-[16px] font-bold text-wrap">
                        {item.price}
                      </p>
                      <p className="text-sm font-normal">{item.stock}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className=""></div>
          </div>
        </div>
      </AppWrapper>
    </RequireAuth>
  );
};

export default Dashboard;
