import { functions, db, auth, provider, appleProvider } from "./firebaseConfig";
import { doc, getDoc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { signInWithPopup } from "firebase/auth";
import { v4 } from "uuid";

export const signUpCredentials = async (email, firstName, lastName) => {
  try {    
    const userRef = doc(db, "users", email );
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      // user already exist message or error
      throw new Error("User already exist. Please log in");
    } else {
      // the user is new. They should only get entered into the db
      // until after the otp is verified
      await requestOtp(email);
      await setDoc(userRef, { 
        email: email,
        displayName: firstName + " " + lastName,
        userName: '',
        photoURL: '',
        dob: '',
        countryCode: '',
        phoneNumber: '',
        provider: 'email',
        city: '',
        zipCode: '',
        businessAccount: false, 
        userId: v4(),
        verificationStatus: '',
        creationTime: Timestamp.now(),
        lastSessionTime: Timestamp.now(),
        lastLoginLocation: '',
        businessName: '',
        businessType: '',
        businessNumber: ''
      }, { merge: true });
      return { success: true, message: "User saved successfully", isNewUser: true  };
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

//sign in with google or apple and store in db
export const signInWithProvider = async (authProvider) => {
  try {
    const result = await signInWithPopup(auth, authProvider);
    const user = result.user;
    // Check if user already exists
    const userRef = doc(db, "users", user.email);
    const docSnap = await getDoc(userRef);
    if (!docSnap.exists()) {
      // New user, store user information in Firestore
      await setDoc(userRef, {
        email: user.email,
        displayName: user.displayName,
        userName: '',
        photoURL: user.photoURL,
        dob: '',
        countryCode: '',
        phoneNumber: '',
        provider: authProvider.providerId,
        city: '',
        zipCode: '',
        businessAccount: false,
        userId: user.uid,
        verificationStatus: '',
        creationTime: user.metadata.creationTime,
        lastSessionTime: Timestamp.now(),
        lastLoginLocation: '',
        businessName: '',
        businessType: '',
        businessNumber: ''
      }, { merge: true });
      return { success: true, data: user, isNewUser: true };
    }
    sessionStorage.setItem("userEmail", user.email);
    await updateDB(user.email)
  } catch (error) {
    console.error("Error signing in with" + provider + " : ", error);
    return { success: false, message: error.message };
  }
};

export const signInWithGoogle = () => signInWithProvider(provider);
export const signInWithApple = () => signInWithProvider(appleProvider);

//helper function for request otp
const requestOtpHelper = async (email) => {
  const send = httpsCallable(functions, "sendOtp"); 
  const result = await send({ email: email });
  if (result.data.success) {
        return { success: true, message: "OTP sent successfully" };
      } else {
        throw Error("Failed to send OTP. Please try again.");
      }
}

// for handling sign in with email and want to verify
export const requestOtpSpecialCase = async (email) =>{
  try {
    const userRef = doc(db, "users", email);
    const docSnap = await getDoc(userRef);
  
    if(docSnap.exists()){
      const response = await requestOtpHelper(email);
      return response;
    }else{
      throw new Error("User does not exist. Please sign up");
    }   
  } catch (error) {
    throw new Error(error.message);
  }
}

// Function to request OTP
export const requestOtp = async (email) => {
  try {
    const userRef = doc(db, "users", email);
    const docSnap = await getDoc(userRef);
  
    if(docSnap.exists()){
      throw new Error("User already exist. Please sign in");
    }else{
      await requestOtpHelper(email);
    }   
  } catch (error) {
    throw new Error(error.message);
  }
};

// Custom error class to handle OTP errors
class OtpError extends Error {
  constructor(message, statusCode) {
    super(message);
    this.name = "OtpError";
    this.statusCode = statusCode;
  }
}

//this is to update the db for all login/sign in types 
const updateDB = async (email) => {
  const emailDocRef = doc(db, "users", email);
  const emailDoc = await getDoc(emailDocRef);

  //update lastSessionTime when user last signed in and others
  if(emailDoc.data().lastSessionTime){
    await updateDoc(emailDocRef, {lastLoginLocation:'', lastSessionTime: Timestamp.now() }, { merge: true });
  }else{
    //create lastSessionTime and others
    await setDoc(emailDocRef, {
      dob: '', 
      city: '', 
      zipCode: '', 
      userName : '', 
      countryCode : '', 
      phoneNumber : '', 
      verificationStatus : '', 
      lastLoginLocation:'', 
      userId: auth.currentUser.uid,
      businessAccount: false, 
      lastSessionTime: Timestamp.now(), 
      businessName: '',
      businessType: '',
      businessNumber: '' },
     { merge: true });
  }
}

// Function to verify OTP
export const verifyOtp = async (email, otp) => {
  try {
    const otpDocRef = doc(db, "otps", email);
    const otpDoc = await getDoc(otpDocRef);

    if (otpDoc.exists()) {
      const otpData = otpDoc.data();
      const now = new Date();
      const createdAt = otpData.createdAt.toDate();
      
      // Check if OTP is correct and not older than 10 minutes
      if ( (now - createdAt < 10 * 60 * 1000) && (otpData.otp === otp) ) {
        await updateDB(email)

        return { message: "OTP Verified", status: true, statusCode: 200 };
      } else {
        throw new OtpError(
          "Invalid or expired OTP. Please request a new one.",
          400
        );
      }
    } else {
      throw new OtpError(
        "No OTP found for this email. Please request a new one.",
        404
      );
    }
  } catch (error) {
    if (error instanceof OtpError) {
      return {
        message: error.message,
        status: false,
        statusCode: error.statusCode,
      };
    } else {
      return {
        message: "Failed to verify OTP. Please try again.",
        status: false,
        statusCode: 500,
      };
    }
  }
};

export const logout = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    throw new Error("Failed to logout. Please try again.");
  }
};
