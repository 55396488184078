import React, { useState, useRef, useEffect } from "react";

const GrowingInput = ({ name, value, onChange, label }) => {
  const inputRef = useRef(null);
  const spanRef = useRef(null);

  useEffect(() => {
    if (spanRef.current) {
      inputRef.current.style.width = `${spanRef.current.offsetWidth}px`;
    }
  }, [value]);

  return (
    <span className="bg-[#9747FF40] text-[#9747FF] w-auto p-2 rounded-xl inline-flex items-center">
      {label && `${label}: `}
      <span ref={spanRef} className="absolute invisible whitespace-pre ">
        {value || " "}
      </span>
      <input
        ref={inputRef}
        className="bg-transparent ml-2 outline-none border-none min-w-[1ch]"
        type="text"
        name={name}
        value={value}
        onChange={onChange}
      />
    </span>
  );
};

export default GrowingInput;
