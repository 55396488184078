import React, { useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUserValue } from "../context/userContext";

const RequireAuth = ({ children }) => {
  const { currentUser } = useUserValue();
  const navigate = useNavigate();
  const location = useLocation();
  const [authChecked, setAuthChecked] = useState(false);
  
  useEffect(() => {
    if (!authChecked) {
      if (!currentUser) {
        const redirectUrl = new URLSearchParams(location.search);
        redirectUrl.set('redirect', location.pathname);
        navigate(`/get-started?${redirectUrl.toString()}`);
      }
      setAuthChecked(true);
    }
  }, [currentUser, navigate, location, authChecked]);

  
  if (!authChecked) {
    return null;
  }

  return currentUser ? children : null;
};

export default RequireAuth;