import React, { useState, useEffect, useRef } from "react"; // Importing React and hooks
import { ReactComponent as CaretDownIcon } from "../../../../../assets/svg/downIcon.svg"; // Importing down icon SVG

function CurrencySelect({
  containerVariant = "w-full flex flex-col", // Default styling for the container
  label, // Label for the select input
  options, // Available currency options
  name, // Name attribute for the input
  placeholder, // Placeholder text for the input
  isDisabled = false, // Flag to disable the input
  onChange = () => {}, // Function to handle change events
  onBlur = () => {}, // Function to handle blur events
  selectedItem = null, // Initially selected item
  isCountrySelect = false, // Flag to indicate if it's a country select
  value, // Current value of the input
  hasError = false, // Flag to indicate if there's an error
  error = "", // Error message
  isLabelBold = false, // Flag to make the label bold
  isBorderBold = false, // Flag to make the border bold
}) {
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
  const [selectedOption, setSelectedOption] = useState(selectedItem); // State for the selected option
  const fieldRef = useRef(null); // Ref for the input field

  // Filtering the country logo based on the selected value
  const countryLogo = options?.filter((x) => x?.value === value)[0];

  // Effect to update selected option when selectedItem changes
  useEffect(() => {
    setSelectedOption(selectedItem);
  }, [selectedItem]);

  // Effect to handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        fieldRef.current &&
        !fieldRef.current.contains(event.target) &&
        !event.target.classList.contains("select-field-option")
      ) {
        setIsOpen(false); // Close dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside); // Adding event listener

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup listener on unmount
    };
  }, []);

  // Function to handle option selection
  const handleOptionClick = (option) => {
    setSelectedOption(option); // Update selected option
    setIsOpen(false); // Close dropdown

    // Trigger onChange event with the selected option
    onChange({
      target: {
        name,
        value: option.value,
      },
    });
  };

  // Function to toggle dropdown visibility
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle dropdown open state
  };

  // Function to handle blur event
  const handleBlur = () => {
    onBlur({
      target: {
        name,
      },
    });
  };

  return (
    <div className={`${containerVariant}`}>
      {" "}
      {/* Container for the currency select */}
      {label && ( // Render label if provided
        <div
          className="flex items-center font-jarkata font-normal"
          ref={fieldRef} // Attach ref to the label container
        >
          <label
            htmlFor={`input-${name}`} // For accessibility
            className={`text-xs mb-2.5 ${
              isDisabled ? "text-gray-300" : "text-black"
            } ${isLabelBold ? "font-bold" : "font-normal"}`} // Conditional styling
          >
            {label} {/* Displaying the label */}
          </label>
        </div>
      )}
      <div className="relative flex flex-row items-center justify-center">
        {" "}
        {/* Container for input and dropdown */}
        <input
          className={`w-full h-[40px] outline-none text-white placeholder:text-xl pr-10 pt-1 placeholder:text-white text-xs ${
            value && isCountrySelect && `pl-10 `
          } text-center border ${
            isBorderBold ? "border-black" : "border-[#858585]"
          } rounded-full bg-black ${hasError ? "border-red-500" : ""}`} // Input styling
          onClick={handleToggleDropdown} // Toggle dropdown on click
          onBlur={handleBlur} // Handle blur event
          value={value} // Current value of the input
          placeholder={placeholder} // Placeholder text
        />
        {isCountrySelect && ( // Render country logo if applicable
          <span
            className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${countryLogo?.className}`}
          />
        )}
        <CaretDownIcon className="absolute right-6 top-[52%] transform -translate-y-1/2 rotate-180 text-white stroke-white pointer-events-none" />{" "}
        {/* Dropdown icon */}
      </div>
      {isOpen && ( // Render dropdown if open
        <div className="absolute z-10 min-w-[150px] mt-[40px] py-2 px-3 bg-black rounded-md shadow-lg">
          <div
            className={`overflow-x-auto ${
              options?.length > 5 ? "h-60" : "h-20"
            }`}
          >
            {options?.map((option) =>
              option ? (
                <div
                  key={option.value} // Unique key for each option
                  className="px-2 py-2 cursor-pointer text-xs text-white select-field-option"
                  onClick={() => handleOptionClick(option)} // Handle option click
                >
                  {isCountrySelect && ( // Render country logo if applicable
                    <span
                      className={`mr-2 ${option.className} mr-2 scale-75`}
                    />
                  )}
                  <span>{option.label}</span> {/* Displaying option label */}
                </div>
              ) : null
            )}
          </div>
        </div>
      )}
      {hasError && ( // Render error message if applicable
        <div className="flex gap-2">
          <p className="text-red-500 text-[10px] h-auto py-1 font-openSans">
            {error} {/* Displaying error message */}
          </p>
        </div>
      )}
    </div>
  );
}

export default CurrencySelect; // Exporting the CurrencySelect component
