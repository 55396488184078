export const interests = [
  { id: 1, label: "Travel" },
  { id: 2, label: "Games" },
  { id: 3, label: "Cooking" },
  { id: 4, label: "Reading" },
  { id: 5, label: "Music" },
  { id: 6, label: "Sports" },
  { id: 7, label: "Photography" },
  { id: 8, label: "Art" },
  { id: 9, label: "Technology" },
  { id: 10, label: "Fashion" },
  { id: 11, label: "Fitness" },
  { id: 12, label: "Hiking" },
  { id: 13, label: "Yoga" },
  { id: 14, label: "Movies" },
  { id: 15, label: "Writing" },
  { id: 16, label: "Gardening" },
  { id: 17, label: "Crafting" },
  { id: 18, label: "Dancing" },
  { id: 19, label: "Fishing" },
  { id: 20, label: "Cycling" },
  { id: 21, label: "Running" },
  { id: 22, label: "Skiing" },
  { id: 23, label: "Surfing" },
  { id: 24, label: "Camping" },
  { id: 25, label: "Bird Watching" },
  { id: 26, label: "Volunteering" },
  { id: 27, label: "DIY Projects" },
  { id: 28, label: "Astrology" },
  { id: 29, label: "Meditation" },
  { id: 30, label: "Baking" },
  { id: 31, label: "Puzzles" },
  { id: 32, label: "Collecting" },
  { id: 33, label: "Knitting" },
  { id: 34, label: "Scrapbooking" },
  { id: 35, label: "Martial Arts" },
  { id: 36, label: "Chess" },
  { id: 37, label: "Board Games" },
  { id: 38, label: "Wine Tasting" },
  { id: 39, label: "Brewing Beer" },
  { id: 40, label: "Investing" },
  { id: 41, label: "Genealogy" },
  { id: 42, label: "Home Improvement" },
  { id: 43, label: "Interior Design" },
  { id: 44, label: "Magic" },
  { id: 45, label: "Stand-Up Comedy" },
  { id: 46, label: "Model Building" },
  { id: 47, label: "RC Cars" },
  { id: 48, label: "Karaoke" },
  { id: 49, label: "Parkour" },
  { id: 50, label: "Origami" },
];
