import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Preloader from "../mics/Preloader";

const TextArena = forwardRef(
  (
    {
      autoFocus = false,
      containerVariant = "w-full flex flex-col",
      type = "text",
      name,
      label,
      handleChange,
      handleBlur,
      value = "",
      error,
      hasError = false,
      placeHolder = "Enter text",
      variant = "text-black w-full text-lg px-5",
      icon = {
        active: false,
        variant: "",
        preview: null,
      },
      isDisabled = false,
      isLoading = false,
      success = false,
      maxLength,
      isPin,
      rows = 5,
    },
    ref
  ) => {
    // Effects
    useEffect(() => {
      if (value !== "") {
        setLocalValue(value);
      }
    }, [value]);

    const [localValue, setLocalValue] = useState(value);

    const validationFulfilled = value !== "" && success;

    return (
      <div className={`${containerVariant}`}>
        {label && (
          <div className="flex items-center justify-between font-jarkata font-normal">
            <label
              htmlFor={`input-${name}`}
              className={`text-xs mb-2.5
                          ${isDisabled ? "text-gray-300" : "text-black"}`}
            >
              {label}
            </label>
          </div>
        )}
        {icon?.active && <span className={icon.variant}>{icon.preview}</span>}
        <div className="relative">
          {isLoading && (
            <span className="absolute text-[#AB0B4B] ml-5 right-1.5 top-3">
              <Preloader variant="w-6 h-6" currentColor="#AB0B4B" currentFill="#F8E8E8" />
            </span>
          )}
          {validationFulfilled && (
            <span className="absolute text-[#AB0B4B] ml-5 right-1.5 top-3">
              {"good"}
            </span>
          )}
          <textarea
            ref={ref}
            name={name}
            type={type}
            className={`focus:outline-none text-sm border border-[#858585] z-2 bg-[#ffffff] bg-opacity-10 outline-none placeholder:text-xs pt-3 placeholder:text-[#00000098] rounded-xl
            ${icon?.active && "px-12"}
            ${isDisabled
              ? `cursor-not-allowed bg-gray-500`
              : "bg-lib-alat-gray-input placeholder:text-gray-500 border-lib-alat-gray-input-border"
            }
            ${success && !hasError && "valid:border-[#3BB54A] focus:valid:border-[#3BB54A]"}
            ${hasError && "border-red-500 focus:border-red-500"}
            ${variant}`}
            value={localValue}
            onChange={(event) => {
              if (isPin) {
                const re = /^[0-9\b]+$/;
                if (event.target.value !== "" && re.test(event.target.value) === false) return;
                if (maxLength && event.target.value.length > maxLength) return;
              }
              setLocalValue(event.target.value);
              handleChange(event);
            }}
            rows={rows}
            onBlur={handleBlur}
            placeholder={placeHolder}
            disabled={isDisabled}
            autoFocus={autoFocus}
            maxLength={maxLength}
          />
        </div>
        {hasError && (
          <div className="flex gap-2">
            <p className="text-red-500 text-[10px] h-auto py-1 font-openSans">
              {error}
            </p>
          </div>
        )}
      </div>
    );
  }
);

TextArena.propTypes = {
  autoFocus: PropTypes.bool,
  containerVariant: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string,
  hasError: PropTypes.bool,
  placeHolder: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.shape({
    active: PropTypes.bool,
    variant: PropTypes.string,
    preview: PropTypes.element,
  }),
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  success: PropTypes.bool,
  maxLength: PropTypes.number,
  rows: PropTypes.number,
  isPin: PropTypes.bool,
};

export default TextArena;
