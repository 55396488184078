import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import SimpleCardModal from "../../../../components/Modal/SimpleCardModal";
import TextArena from "../../../../components/reusables/TextArena";
import TextInput from "../../../../components/reusables/TextInput";
import SelectField from "../../../../components/reusables/SelectField";
import { currency } from "../constants";
import { CustomButton } from "../../../../components/buttons/CustomButton";
import { useDispatch } from "react-redux";
import { ToastNotify } from "../../../../components/reusables/helpers/ToastNotify";
import { listingValidationStep2 } from "../../../../utils/ValidationSchemas/getStarted.validation";
import CenterModal from "../../../../components/Modal/CenterModal";
import GrowingInput from "../../../../components/reusables/GrowingInput";
import { nextStep, previousStep, storeData } from "../../../../redux/reducers/reducer";
import { ReactComponent as Left } from "../../../../assets/svg/arrow-left.svg";
import { ReactComponent as Right } from "../../../../assets/svg/arrow-right.svg";
import  useAccountDetails  from "../../../../utils/hooks/useAccountDetails";
import { useNavigate, useLocation } from "react-router-dom";

const Step2 = ({ listingData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [load, toggleLoad] = useState(false);
  const { getUser, currentUser } = useAccountDetails();
  const [modalStep, setModalStep] = useState(() => {
    const savedModalStep = localStorage.getItem('modalStep');
    return savedModalStep ? Number(savedModalStep) : 0;
  });

  const [tags, setTags] = useState([
    "Cow",
    "Black",
    "Electric",
    "WWW",
    "Facebook",
  ]);
  const [inputValue, setInputValue] = useState("");

  const handleAddTag = () => {
    if (inputValue.trim()) {
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleProceedClick = () => {
    dispatch(nextStep({ ...values }));
    if (!currentUser) {
      ToastNotify({
        message: "Please sign in or register to proceed.",
        position: "top-right",
        type: "warning",
      });
    }
  };

  const handleAdd = () => {
    setFieldValue("quantity", values.quantity + 1);
  };

  const handleMinus = () => {
    if (values.quantity > 1) {
      setFieldValue("quantity", values.quantity - 1);
    }
  };

  const handleNextAiStep = () => {
    if (modalStep < 3) {
      setModalStep((prev) => prev + 1);
    }
  };

  const handlePrevAiStep = () => {
    if (modalStep > 1) {
      setModalStep((prev) => prev - 1);
    }
  };

  const formik = useFormik({
    initialValues: {
      description: "",
      price: "",
      currency: "",
      condition: "",
      quantity: 1,
    },

    onSubmit: (values) => {
      try {
        toggleLoad(true);
        setModalStep(1);
        dispatch(storeData({ ...values }));
      } catch (error) {
        ToastNotify({
          type: "error",
          message: error.message,
          position: "top-right",
        });
      } finally {
        toggleLoad(false);
      }
    },

    validationSchema: listingValidationStep2,
  });

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    dirty,
    isValid,
    setFieldValue,
    setValues,
  } = formik;

  useEffect(() => {
    if (listingData) {
      const { base64Urls, itemName, ...rest } = listingData;
      setValues({ ...rest });
    }
  }, [listingData]);

  const handleCloseModal = () => {
    setModalStep(0);
  };

  useEffect(() => {
    localStorage.setItem('modalStep', modalStep);
  }, [modalStep]);

  return (
    <>
      <div className="">
        <div className="w-full flex justify-center px-10 my-10">
          <SimpleCardModal width={"650px"}>
            <>
              <div className="text-center">
                <h1 className="font-medium text-2xl">List Item</h1>
                <div className="font-light leading-tight text-sm">
                  Kindly add more details pertaining to the item you want to
                  list.
                </div>
              </div>

              <div className="my-10 px-10 space-y-5">
                <div>
                  <label className="text-xs mb-3">Quantity</label>
                  <div className="flex items-center gap-5 mt-3">
                    <div className="cursor-pointer" onClick={handleMinus}>
                      -
                    </div>
                    <div className="w-10 h-10 flex justify-center items-center border border-black rounded-xl">
                      {values.quantity}
                    </div>
                    <div className="cursor-pointer" onClick={handleAdd}>
                      +
                    </div>
                  </div>
                </div>
                <div>
                  <TextArena
                    label="Description"
                    placeHolder="E.g A rare orange skin alligator birkin bag"
                    keyboardType="default"
                    value={values?.description}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    name="description"
                    error={errors?.description}
                    hasError={errors?.description && touched?.description}
                  />
                </div>
                <div className="w-full mx-auto grid grid-cols-3 gap-5 items-center justify-center">
                  <div className="col-span-2">
                    <TextInput
                      isCurrencyInput
                      label="Price"
                      keyboardType="numeric"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values?.price}
                      name="price"
                      placeHolder="25,000"
                      error={errors?.price}
                      hasError={errors?.price && touched?.price}
                    />
                  </div>
                  <div className="col-span-1">
                    <SelectField
                      name="currency"
                      options={currency}
                      label="Currency"
                      placeholder="Select Currency"
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.currency}
                      hasError={errors?.currency && touched?.currency}
                      error={errors?.currency}
                    />
                  </div>
                </div>
                <div>
                  <label className="text-xs">Condition</label>
                  <div className="flex text-sm gap-5 mt-2">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="condition"
                        value="new"
                        checked={values?.condition === "new"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mr-2"
                      />
                      New
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="condition"
                        value="certified"
                        checked={values?.condition === "certified"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mr-2"
                      />
                      Certified
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="condition"
                        value="used"
                        checked={values?.condition === "used"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="mr-2"
                      />
                      Used
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-[70%] mx-auto text-center">
                <CustomButton
                  labelText={load ? "Please Wait" : "Continue 2/5"}
                  containerVariant="text-sm px-5 py-3 rounded-full w-full"
                  variant="font-bold"
                  buttonVariant="secondary"
                  onClick={handleSubmit}
                  isDisabled={!(dirty && isValid) || load}
                />
                <button
                  onClick={() => dispatch(previousStep())}
                  className="mt-5 cursor-pointer"
                >
                  Back
                </button>
              </div>
            </>
          </SimpleCardModal>
        </div>


        {/* first modal */}
        {modalStep === 1 && (
          <CenterModal
            showCloseIcon={false}
            allowOutsideClick={true}
            handleClose={handleCloseModal}
          >
            <div className="text-center font-sfpro px-10">
              <div className="font-medium text-2xl">
                Congrats! You are close to setting up your item
              </div>
              <div className="text-sm mt-2">
                We can help optimize your items with special
                <br />
                keywords and more details
              </div>
              <div className="flex items-center justify-between gap-5 mt-10">
                <div
                  onClick={handlePrevAiStep}
                  className={`cursor-pointer`}
                >
                  {/* <Left /> */}
                </div>
                <div>
                  <SimpleCardModal width="500px">
                    <>
                      <div className="space-y-3">
                        <div className="font-medium text-sm text-center">
                          Optimized Description
                        </div>
                        <div>
                          <TextArena
                            //   label="Description"
                            placeHolder=""
                            keyboardType="default"
                            value={values?.optimizedDescription}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            name="optimizedDescription"
                            error={errors?.description}
                            hasError={
                              errors?.description && touched?.description
                            }
                            // rows={25}
                          />
                        </div>
                        <div className="flex items-center gap-3">
                          <CustomButton
                            labelText={"Save"}
                            containerVariant="text-sm px-5 py-1 rounded-full w-full"
                            variant="font-bold"
                            buttonVariant="secondary"
                            onClick={() => {
                              storeData({ ...values });
                              ToastNotify({
                                message:
                                  "Saved successfully, Click on the Right to proceed or skip",
                                position: "top-right",
                                type: "success",
                              });
                              //   setModalStep(2);
                            }}
                          />
                          <CustomButton
                            labelText={"Edit"}
                            containerVariant="text-sm px-5 py-1 rounded-full w-full"
                            variant="font-bold"
                            buttonVariant="tetiary"
                            // onClick={() => setModalStep(2)}
                          />
                        </div>
                      </div>
                    </>
                  </SimpleCardModal>
                </div>
                <div onClick={handleNextAiStep} className="cursor-pointer">
                  <Right />
                </div>
              </div>
              <div className="mt-10 flex justify-center items-center gap-5">
                <div className="h-2 w-2 bg-[#9747FF] rounded-full"></div>
                <div className="h-2 w-2 bg-[#D9D9D9] rounded-full"></div>
                <div className="h-2 w-2 bg-[#D9D9D9] rounded-full"></div>
              </div>
              <div>
                <button
                  // onClick={() => navigate(-1)}
                  className="mt-4 text-sm cursor-pointer"
                >
                  Skip
                </button>
              </div>
            </div>
          </CenterModal>
        )}

        {/* second modal */}
        {modalStep === 2 && (
          <CenterModal
            showCloseIcon={false}
            // handleClose={() => toggleOtpModal(false)}
            allowOutsideClick={false}
          >
            <div className="text-center font-sfpro px-10">
              <div className="font-medium text-2xl">
                Congrats! You are close to setting up your item
              </div>
              <div className="text-sm mt-2">
                We can help optimize your items with special
                <br />
                keywords and more details
              </div>
              <div className="flex items-center gap-5 justify-between mt-10">
                <div onClick={handlePrevAiStep} className="cursor-pointer">
                  <Left />
                </div>
                <div>
                  <SimpleCardModal width="500px">
                    <>
                      <div className="space-y-5">
                        <div className="font-medium text-sm text-center">
                          Optimized Additional Information
                        </div>
                        <div className="border w-full p-4 rounded-xl">
                          <div>
                            <h1 className="text-center text-sm mb-3 font-medium">
                              Details
                            </h1>
                            <div className="flex flex-wrap gap-3">
                              <GrowingInput
                                label="Year"
                                name="year"
                                value={values.year}
                                onChange={handleChange}
                              />
                              <GrowingInput
                                label="Model"
                                name="model"
                                value={values.model}
                                onChange={handleChange}
                              />
                              <GrowingInput
                                label="Color"
                                name="color"
                                value={values.color}
                                onChange={handleChange}
                              />
                              <GrowingInput
                                label="Type"
                                name="type"
                                value={values.type}
                                onChange={handleChange}
                              />
                              <GrowingInput
                                label="Class"
                                name="class"
                                value={values.class}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div>
                            <h1 className="text-center text-sm my-3 font-medium">
                              Dimensions
                            </h1>
                            <div className="flex flex-wrap gap-3">
                              <GrowingInput
                                label="Height"
                                name="height"
                                value={values.height}
                                onChange={handleChange}
                              />
                              <GrowingInput
                                label="Width"
                                name="width"
                                value={values.width}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-3">
                          <CustomButton
                            labelText={"Save"}
                            containerVariant="text-sm px-5 py-1 rounded-full w-full"
                            variant="font-bold"
                            buttonVariant="secondary"
                            onClick={() => {
                              storeData({ ...values });
                              ToastNotify({
                                message:
                                  "Saved successfully, Click on the Right to proceed or skip",
                                position: "top-right",
                                type: "success",
                              });
                              //   setModalStep(3);
                            }}
                          />
                          <CustomButton
                            labelText={"Edit"}
                            containerVariant="text-sm px-5 py-1 rounded-full w-full"
                            variant="font-bold"
                            buttonVariant="tetiary"
                            // onClick={() => setModalStep(3)}
                          />
                        </div>
                      </div>
                    </>
                  </SimpleCardModal>
                </div>
                <div onClick={handleNextAiStep} className="cursor-pointer">
                  <Right />
                </div>
              </div>
              <div className="mt-10 flex justify-center items-center gap-5">
                <div className="h-2 w-2 bg-[#D9D9D9] rounded-full"></div>
                <div className="h-2 w-2 bg-[#9747FF] rounded-full"></div>
                <div className="h-2 w-2 bg-[#D9D9D9] rounded-full"></div>
              </div>
              <div>
                <button
                  // onClick={() => navigate(-1)}
                  className="mt-4 text-sm cursor-pointer"
                >
                  Skip
                </button>
              </div>
            </div>
          </CenterModal>
        )}

        {/* third modal */}
        {modalStep === 3 && (
          <CenterModal
            showCloseIcon={false}
            // handleClose={() => toggleOtpModal(false)}
            allowOutsideClick={false}
          >
            <div className="text-center font-sfpro px-10">
              <div className="font-medium text-2xl">
                Congrats! You are close to setting up your item
              </div>
              <div className="text-sm mt-2">
                We can help optimize your items with special
                <br />
                keywords and more details
              </div>
              <div className="flex items-center gap-5 justify-between mt-10">
                <div onClick={handlePrevAiStep} className="cursor-pointer">
                  <Left />
                </div>
                <div>
                  <SimpleCardModal width="500px">
                    <>
                      <div className="space-y-3">
                        <div className=" w-full p-4 rounded-xl">
                          <div>
                            <h1 className="text-center text-sm mb-3 font-medium">
                              Select detail tags
                            </h1>
                            <div className="flex flex-wrap gap-3">
                              {tags.map((tag, index) => (
                                <span
                                  key={index}
                                  className="bg-[#9747FF40] text-[#9747FF] p-2 rounded-md"
                                >
                                  {tag}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center gap-3">
                          <CustomButton
                            labelText={"Save"}
                            containerVariant="text-sm px-5 py-1 rounded-full w-full"
                            variant="font-bold"
                            buttonVariant="secondary"
                            onClick={() => {
                              dispatch(storeData({ ...values, tags }));
                              ToastNotify({
                                message:
                                  "Saved successfully, Click on Proceed to move to the next step",
                                position: "top-right",
                                type: "success",
                              });
                            }}
                          />
                          <CustomButton
                            labelText={"Edit"}
                            containerVariant="text-sm px-5 py-1 rounded-full w-full"
                            variant="font-bold"
                            buttonVariant="tetiary"
                            // onClick={() => setModalStep(3)}
                          />
                        </div>
                      </div>
                    </>
                  </SimpleCardModal>
                </div>
                <div
                  onClick={handleNextAiStep}
                  className={`cursor-pointer`}
                >
                  {/* <Right /> */}
                </div>
              </div>
              <div className="mt-10 flex justify-center items-center gap-5">
                <div className="h-2 w-2 bg-[#D9D9D9] rounded-full"></div>
                <div className="h-2 w-2 bg-[#D9D9D9] rounded-full"></div>
                <div className="h-2 w-2 bg-[#9747FF] rounded-full"></div>
              </div>
              <div className=" mt-5 flex flex-col items-center">
                <CustomButton
                  labelText={"Proceed"}
                  containerVariant="text-sm px-5 py-1 rounded-full w-[50%]"
                  variant="font-bold"
                  buttonVariant="secondary"
                  onClick={handleProceedClick}
                />
                <button
                  // onClick={() => navigate(-1)}
                  className="mt-4 text-sm cursor-pointer"
                >
                  Skip
                </button>
              </div>
            </div>
          </CenterModal>
        )}
      </div>
    </>
  );
};

export default Step2;

