// App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from "react-helmet-async";
import AOS from "aos";
import "aos/dist/aos.css";
import AppRoutes from "./routes/Routes";
import { store, persistor } from "./store";
import { UserProvider } from "./context/userContext";
import PageLoader from "./components/PageLoader";

function App() {
  useEffect(() => {
    AOS.init({
      disableMutationObserver: false,
      mirror: false,
    });
  }, []);

  return (
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={<PageLoader />} persistor={persistor}>
          <UserProvider>
            <div className="App">
              <Router>
                <AppRoutes />
              </Router>
            </div>
          </UserProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
