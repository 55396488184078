import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Preloader from "../mics/Preloader";
import { ReactComponent as Search } from "../../assets/svg/Search.svg";

const SearchInput = forwardRef(
  (
    {
      autoFocus = false,
      containerVariant = "w-full flex flex-col",
      type = "text",
      name,
      label,
      handleChange,
      handleBlur,
      value = "",
      error,
      hasError = false,
      placeHolder = "Enter text",
      variant = "text-black w-full h-12 text-lg px-5",
      icon = {
        active: true,
        variant: "absolute left-3 top-1/2 transform -translate-y-1/2",
        preview: <Search className="w-5 h-5" />,
      },
      isDisabled = false,
      isLoading = false,
      success = false,
      maxLength,
      isPin,
    },
    ref
  ) => {
    // Effects
    useEffect(() => {
      if (value !== "") {
        setLocalValue(value);
      }
    }, [value]);

    const [localValue, setLocalValue] = useState(value);

    const validationFulfilled = value !== "" && success;

    return (
      <div className={`${containerVariant}`}>
        {label && (
          <div className="flex items-center justify-between font-jarkata font-normal">
            <label
              htmlFor={`input-${name}`}
              className={`text-sm mb-2.5
                          ${isDisabled ? "text-gray-300" : "text-white"}`}
            >
              {label}
            </label>
          </div>
        )}
        {/* {icon?.active && <span className={icon.variant}>{icon.preview}</span>} */}
        <div className="relative">
          {isLoading && (
            <span className="absolute text-[#AB0B4B] ml-5 right-1.5 top-3">
              <Preloader
                variant="w-6 h-6"
                currentColor="#AB0B4B"
                currentFill="#F8E8E8"
              />
            </span>
          )}
          {validationFulfilled && (
            <span className="absolute text-[#AB0B4B] ml-5 right-1.5 top-3">
              {"good"}
            </span>
          )}
          <div>
            <input
              ref={ref}
              name={name}
              type={type}
              className={`focus:outline-none bg-[#F5F5F5] text-xs ${value ? "pl-10" : "text-gray-300"} z-2 focus:border outline-none placeholder:text-xs placeholder:text-[#00000098] placeholder:pl-5 rounded-2xl
            ${icon?.active && "px-12"}
            ${
              isDisabled
                ? `cursor-not-allowed bg-gray-500`
                : "bg-lib-alat-gray-input placeholder:text-gray-500 border-lib-alat-gray-input-border"
            }
            ${
              success &&
              !hasError &&
              "valid:border-[#3BB54A] focus:valid:border-[#3BB54A]"
            }
            ${hasError && "border-red-500 focus:border-red-500"}
            ${variant}`}
              value={localValue}
              onChange={(event) => {
                if (isPin) {
                  const re = /^[0-9\b]+$/;
                  if (
                    event.target.value !== "" &&
                    re.test(event.target.value) === false
                  )
                    return;
                  if (maxLength && event.target.value.length > maxLength)
                    return;
                }
                setLocalValue(event.target.value);
                handleChange(event);
              }}
              onBlur={handleBlur}
              placeholder={placeHolder}
              disabled={isDisabled}
              autoFocus={autoFocus}
              maxLength={maxLength}
            />
            {icon?.active && (
              <span className={icon.variant}>{icon.preview}</span>
            )}
          </div>
        </div>
        {hasError && (
          <div className="flex gap-2">
            <p className="text-red-500 text-[10px] h-auto py-1 font-openSans">
              {error}
            </p>
          </div>
        )}
      </div>
    );
  }
);

SearchInput.propTypes = {
  autoFocus: PropTypes.bool,
  containerVariant: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string,
  hasError: PropTypes.bool,
  placeHolder: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.shape({
    active: PropTypes.bool,
    variant: PropTypes.string,
    preview: PropTypes.element,
  }),
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  success: PropTypes.bool,
  maxLength: PropTypes.number,
  isPin: PropTypes.bool,
};

export default SearchInput;
