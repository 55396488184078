import Threshold from "../../assets/gifs/threshold.gif";
import List from "../../assets/gifs/listing2.gif";
import Wallet from "../../assets/gifs/wallet.gif";
import Support from "../../assets/gifs/support.gif";
import Security from "../../assets/gifs/security.gif";

export const details = [
  {
    title: "Find Interested Clients Quickly",
    description:
      "Optimize your listings for success with our AI copilot recommended descriptions, pricing, and categorization to ensure you quickly find your desired clients",
    svg: List,
  },
  {
    title: "Move Mountains of Money with Confidence",
    description:
      "Instantly transfer or receive payments using our FDIC-insured deposit system to manage funds, spend directly, and gain interest within same ecosystem",
    svg: Wallet,
  },
  {
    title: "Say Goodbye to Inquires Falling through",
    description:
      "Save your time and build trust by using our threshold system integrated within our banking system to filter inquiries. This ensures quality leads for valuable transactions",
    svg: Threshold,
  },
  {
    title: "High Quality Products Without the Risk",
    description: (
      <>
        Trusted authentication to ensure{" "}
        <span className="lg:block">
          transactions without the fear of fraud or
        </span>{" "}
        <span className="lg:block">counterfeits</span>
      </>
    ),
    svg: Security,
  },
  {
    title: (
      <>
        Round the Clock<span className="block">Support</span>
      </>
    ),
    description: (
      <>
        We provide personalized customer support{" "}
        <span className="lg:block">
          to ensure all your needs are met in a timely
        </span>{" "}
        <span className="lg:block">fashion</span>
      </>
    ),
    svg: Support,
  },
];
