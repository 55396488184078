import React, { useEffect, useState } from "react";
import { CustomButton } from "../../../components/buttons/CustomButton";
import { interests } from "../components/constants";
import { APP_PATHS } from "../../../routes/constants";
import { useNavigate } from "react-router-dom";
import { userInterestsStore } from "../storeOnboardingData";
import { ToastNotify } from "../../../components/reusables/helpers/ToastNotify";
import { useUserValue } from "../../../context/userContext";
import OnboardingWrapper from "../OnboardingWrapper";
import useAccountDetails from "../../../utils/hooks/useAccountDetails";
import { Helmet } from "react-helmet-async";

const Step2 = () => {
  const [selectedInterests, setSelectedInterests] = useState([]);
  const navigate = useNavigate();
  const [load, toggleLoad] = useState(false);
  const { userInterests, getUserInterest } = useAccountDetails();
  const { step2Data, setStep2Data } = useUserValue();

  useEffect(() => {
    //TODO OO: We need to find a way to lazy load this before we get here. Maybe in step1
    getUserInterest();
  }, []);
  

  const handleInterestClick = async (interestLabel) => {
    if (selectedInterests === null) {
      setSelectedInterests([interestLabel]); // Initialize selectedInterests with the clicked interest
    } else {
      const isSelected = selectedInterests.includes(interestLabel);
      if (isSelected) {
        setSelectedInterests(
          selectedInterests.filter((label) => label !== interestLabel)
        );
      } else if (selectedInterests.length < 5) {
        setSelectedInterests([...selectedInterests, interestLabel]);
      } else {
        ToastNotify({
          type: "warning",
          message: "5 items selected already",
          position: "top-right",
        });
      }
    }
  };

  const handleContinueClick = async () => {
    try {
      toggleLoad(true);
      setStep2Data(selectedInterests);
      const result = await userInterestsStore(selectedInterests);
      if (result.success) {
        navigate(APP_PATHS.ONBOARDING_STEP_3);
      }
    } catch (error) {
      console.error(error);
    } finally {
      toggleLoad(false);
    }
  };

  useEffect(() => {
    if (userInterests?.Interests?.length > 0) {
      setSelectedInterests(userInterests?.Interests);
    } else {
      setSelectedInterests(step2Data);
    }
  }, [step2Data, userInterests]);

  return (
    <>
      <Helmet>
        <title> Onboarding 2/3 | Nezz </title>
      </Helmet>
      <OnboardingWrapper>
        <div className="flex mt-5 justify-center overflow-auto">
          <div className="text-center rounded-lg px-5">
            <div className="mb-10 w-full mx-auto max-w-lg">
              <h1 className="text-2xl font-semibold">Interests</h1>
              <p className="text-xs">
                Share your interests with us, and let us tailor more products
                <br /> to suit your preferences.
                <br />
                <br />
                <span className="text-xs font-semibold">
                  ({selectedInterests?.length} out of 5 interest selected)
                </span>
              </p>
            </div>
            <div className="space-y-10 w-full max-w-2xl">
              <div className="flex gap-2 flex-wrap">
                {interests.map((interest) => (
                  <span
                    key={interest.id}
                    className={`px-3 py-1 border ${
                      selectedInterests?.includes(interest.label)
                        ? "border-[#9747FF] bg-[#9747FF] text-white"
                        : "border-[#11111140] text-[#9747FF]"
                    } rounded-full text-sm cursor-pointer`}
                    onClick={() => handleInterestClick(interest.label)}
                  >
                    {interest.label}
                  </span>
                ))}
              </div>
              <div className="mx-auto w-full max-w-lg text-center space-y-2">
                <CustomButton
                  labelText={load ? "Please wait" : "Continue 2/3"}
                  containerVariant="text-sm px-5 py-3 rounded-full w-full"
                  variant="font-bold"
                  buttonVariant="secondary"
                  onClick={handleContinueClick}
                  isDisabled={selectedInterests?.length < 1 || load}
                />
                <button
                  onClick={() => navigate(-1)}
                  className="mt-3 cursor-pointer"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </OnboardingWrapper>  
    </>
  );
};

export default Step2;
