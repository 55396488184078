import React from 'react';
import { Outlet } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, isNewUserRoute, ...rest }) => {

  return <Outlet />;
  //return <Route {...rest} element={<Component />} />;
};

export default ProtectedRoute;
