import React, { useRef } from "react";
import RequireAuth from "../../../components/RequireAuth";
import { Helmet } from "react-helmet-async";
import AppWrapper from "../../../components/AppWrapper";
import ImageViewer from "../../../components/reusables/ImageViewer";
import Image1 from "./assets/image1.png";
import Image2 from "./assets/image2.png";
import Image3 from "./assets/image3.png";
import Image4 from "./assets/image4.png";
import Image5 from "./assets/image5.png";
import { ReactComponent as ArrowLeft } from "../../../assets/svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../assets/svg/arrow-right.svg";

import RadRover from "./assets/radrover electric fat.png";
import ArielRider from "./assets/Ariel Rider Grizzly.png";
import M2SBike from "./assets/M2S Bikes All Terrain R750.png";
import QuietKat from "./assets/QuietKat Jeep.png";
import RipCurrent from "./assets/RipCurrent S.png";
import { CustomButton } from "../../../components/buttons/CustomButton";
import { ReactComponent as Heart } from "../../../assets/svg/heart.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/svg/profile-circle.svg";
import { useParams } from "react-router-dom";
import BlackWhite from "./assets/BlackWhite.png";

const Details = () => {
  const { id } = useParams();
  const scrollContainerRef = useRef(null);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = 235 + 20; // Width of one item (235px) plus gap (20px)
      if (direction === "left") {
        container.scrollBy({ left: -scrollAmount, behavior: "smooth" });
      } else {
        container.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    }
  };

  return (
    <RequireAuth>
      <Helmet>
        <title>Details | Nezz</title>
      </Helmet>
      <AppWrapper>
        {/* Main container with responsive padding */}
        <div className="w-full px-4 sm:px-6 lg:px-8 xl:px-[60px] overflow-hidden pb-10">
          <div className="flex w-full flex-col max-w-[1440px] mx-auto">
            <div className="flex flex-col lg:flex-row gap-8 lg:gap-[72px]">
              <div className="flex flex-col w-full lg:w-[453px] justify-center">
                {/* Image viewer component */}
                <ImageViewer
                  variant="w-full h-[355px] object-contain"
                  images={[Image1, Image2, Image3, Image4, Image5, BlackWhite]}
                />
                {/* Tags section */}
                <div className="flex flex-row flex-wrap gap-2 pt-4">
                  <CustomButton
                    labelText="Black"
                    variant="py-[8px] px-[16px] shadow-none text-[12px] rounded-full flex justify-center"
                    buttonVariant="tag"
                  />
                  <CustomButton
                    labelText="Wallke X3 Pro"
                    variant="py-[8px] px-[16px] shadow-none text-[12px] rounded-full flex justify-center"
                    buttonVariant="tag"
                  />
                  <CustomButton
                    labelText="Electric Bike"
                    variant="py-[8px] px-[16px] shadow-none text-[12px] rounded-full flex justify-center"
                    buttonVariant="tag"
                  />
                  <CustomButton
                    labelText="750W Motor"
                    variant="py-[8px] px-[16px] shadow-none text-[12px] rounded-full flex justify-center"
                    buttonVariant="tag"
                  />
                  <CustomButton
                    labelText="Commuter Bike"
                    variant="py-[8px] px-[16px] shadow-none text-[12px] rounded-full flex justify-center"
                    buttonVariant="tag"
                  />
                  <CustomButton
                    labelText="Fat Tires"
                    variant="py-[8px] px-[16px] shadow-none text-[12px] rounded-full flex justify-center"
                    buttonVariant="tag"
                  />
                </div>
                <div className="pt-7">
                  {/* Additional details section */}
                  <span className="text-lg font-bold inline-block border-b-2 border-black pb-2">
                    Additional Details
                  </span>
                </div>
                {/* Features section */}
                <div className="font-bold text-lg pt-8">Features</div>
                <ul className="list-disc pl-5 text-sm pt-2">
                  <li>Ergonomic grips and a comfortable saddle</li>
                  <li>Integrated LED lights improve visibility and safety</li>
                  <li>LCD display panel provides real-time information</li>
                  <li>Hydraulic disc brakes offer reliable stopping power</li>
                  <li>Fat tires provide stability and traction</li>
                  <li>Rear rack enables convenient cargo storage</li>
                </ul>
                {/* Specifications section */}
                <div className="font-bold text-lg pt-8">Specifications</div>
                <ul className="list-disc pl-5 pt-2 text-sm space-y-2">
                  <li>Equipped with a powerful 750W mid-drive motor</li>
                  <li>Lightweight and durable aluminum alloy frame</li>
                  <li>Long-lasting lithium-ion battery</li>
                  <li>Hydraulic disc brakes for precise braking performance</li>
                  <li>Front suspension forks</li>
                  <li>20-inch fat tires, designed to grip surfaces securely</li>
                </ul>
              </div>
              {/* Details section */}
              <div className="flex flex-col flex-grow">
                <div className="flex flex-col w-full h-auto rounded-lg lg:h-[350px] lg:shadow-xl lg:p-4 lg:pt-[10.5px] lg:pl-[30px]">
                  <div className="text-[#6D25C6] hidden lg:block">
                    5km Away from you
                  </div>
                  {/* Title section */}
                  <div className="flex flex-row relative">
                    <div className="font-bold text-[20px] whitespace-nowrap">
                      Wallke X3 Pro- $1,300 - $1300
                    </div>
                    <Heart className="w-8 h-8 flex-shrink-0 absolute right-8 top-0" />
                  </div>
                  {/* Status section */}
                  <div className="flex flex-row lg:hidden justify-between">
                    <div className="text-[#6D25C6] text-[12px] lg:hidden block">
                      5km Away from you
                    </div>
                    <div className="text-[12px] lg:hidden flex flex-row gap-1 items-center pr-4">
                      Status:{" "}
                      <div className="text-[#9747FF] font-bold"> Hold</div>
                    </div>
                  </div>
                  {/* Price section */}
                  <div className="text-[20px]">$1300/Set Threshold</div>
                  {/* Description section */}
                  <div className="text-[15px] flex flex-wrap mt-[15px] h-[200px] lg:h-auto lg:w-[592px] lg:pr-[40px]">
                    <div className="lg:pl-8 leading-[15px]">
                      Introducing the Wallke X3 Pro: your ultimate companion for
                      adventurous rides and seamless urban commutes. Engineered
                      with precision and powered by a robust 750W motor, this
                      electric bike effortlessly combines power and agility. Its
                      durable aluminum alloy frame and versatile fat tires
                      ensure stability on any terrain, while hydraulic disc
                      brakes guarantee reliable stopping power. <br />
                      Equipped with a high-capacity lithium-ion battery, the
                      Wallke X3 Pro offers an impressive range of up to 80 miles
                      per charge, making every journey longer and more
                      enjoyable. Navigate with ease using the intuitive LCD
                      display and ergonomic controls, while integrated features
                      like suspension forks, LED lights, and a sturdy rear rack
                      enhance both safety and convenience.
                      <br />
                      Whether you crave off-road adventures or efficient city
                      commuting, the Wallke X3 Pro stands ready to elevate your
                      riding experience. Discover the perfect blend of
                      performance, comfort, and style in an electric bike
                      designed to exceed your expectations.
                    </div>
                  </div>
                </div>
                {/* Chat and status section */}
                <div className="flex Lg:flex-col flex-col-reverse sm:flex-row mt-6 mb-8 justify-between">
                  <div className="flex flex-col w-full sm:w-[227px] mb-4 sm:mb-0">
                    <CustomButton
                      labelText="Begin Chat"
                      variant="py-[10px] px-[10px] shadow-none text-[12px] rounded-full flex justify-center hover:border-black hover:bg-white hover:text-black transition-all duration-300"
                      buttonVariant="secondary"
                    />
                  </div>
                  {/* Status section */}
                  <div className="text-[12px] lg:flex flex-row gap-1 items-center hidden">
                    Status:{" "}
                    <div className="text-[#9747FF] font-bold"> Hold</div>
                  </div>
                </div>
                {/* Seller review section */}
                <div className="flex flex-col w-full h-auto rounded-lg lg:h-[350px] lg:shadow-xl lg:p-4 lg:pt-[14px] lg:pl-[30px] lg:pr-14">
                  <div className=" font-bold lg:block hidden text-[16px]">
                    Seller Review
                  </div>
                  <div className="flex-col flex lg:pl-[35px]">
                    <div className="flex flex-row justify-between items-center">
                      <div className="flex flex-row gap-2 items-center">
                        <ProfileIcon className="w-10 h-10" />{" "}
                        <div className="text-lg font-bold">@JJMariot</div>
                      </div>
                      <div className="text-[16px] text-[#9747FF] font-bold">
                        4.50/5.00
                      </div>
                    </div>
                    {/* Review section */}
                    <div className="flex flex-col gap-1 pt-1">
                      <div className="w-full h-[80px] pl-4 rounded-xl bg-[#F8F8F8] border-black border-[1px] flex flex-col">
                        <div className="flex flex-row justify-between pt-4">
                          <div className="text-[12px]  font-bold">User #21</div>
                          <div className="text-[9px] text-[#11111173] pr-10">
                            10 mins ago
                          </div>
                        </div>
                        <div className="text-wrap text-[11px] pb-4 w-2/3">
                          Great Seller!, with awesome products. Communication is
                          top notch and he is also considerate
                        </div>
                      </div>
                      {/* Review section */}
                      <div className="w-full mt-[2px] h-[80px] pl-4 rounded-xl bg-[#F8F8F8] overflow-auto border-black border-[1px] flex flex-col">
                        <div className="flex flex-row justify-between pt-4">
                          <div className="text-[12px]  font-bold">User #21</div>
                          <div className="text-[9px] text-[#11111173] pr-10">
                            10 mins ago
                          </div>
                        </div>
                        <div className="text-wrap overflow-auto text-[11px] w-2/3">
                          Great Seller!, with awesome products. Communication is
                          top notch and he is also considerate
                        </div>
                      </div>
                      {/* Review section */}
                      <div className="w-full mt-[2px] h-[80px] pl-4 rounded-xl bg-[#F8F8F8] border-black border-[1px] flex flex-col">
                        <div className="flex flex-row justify-between pt-4">
                          <div className="text-[12px]  font-bold">User #21</div>
                          <div className="text-[9px] text-[#11111173] pr-10">
                            10 mins ago
                          </div>
                        </div>
                        <div className="text-wrap text-[11px] overflow-auto w-2/3">
                          Great Seller!, with awesome products. Communication is
                          top notch and he is also considerate
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:flex flex-col w-full h-[200px] rounded-lg shadow-xl mt-12 p-4 lg:pl-[30px] hidden overflow-hidden">
                  <div className="font-bold mt-[6px]">
                    Nezz Recommended Questions to Ask
                  </div>
                  <ul className="list-disc text-[12px] pl-5 space-y-1 pb-5">
                    <li>
                      What is the bike's range per charge and battery lifespan?
                    </li>
                    <li>
                      What type of motor does it have, and what are its
                      specifications?
                    </li>
                    <li>Long-lasting lithium-ion battery</li>
                    <li>
                      What safety features are included, such as brakes and
                      lighting?
                    </li>
                    <li>
                      What is the warranty coverage, including for components
                      like the battery and motor?
                    </li>
                    <li>
                      Are there customization options or additional accessories
                      available?
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Recommendations section */}
          <div className="flex-col flex mt-7 pt-10">
            <div className="text-lg font-bold mb-6">
              Check Out These Recommendations
            </div>

            <div className="relative">
              {/* Arrow positioned outside and to the left */}
              <div className="absolute left-[-30px] top-[40%] transform -translate-y-1/2 z-10">
                <ArrowLeft
                  className="cursor-pointer"
                  onClick={() => scroll("left")}
                />
              </div>
              {/* Arrow positioned outside and to the right */}
              <div className="absolute right-[-30px] top-[40%] transform -translate-y-1/2 z-10">
                <ArrowRight
                  className="cursor-pointer"
                  onClick={() => scroll("right")}
                />
              </div>
              <div
                ref={scrollContainerRef}
                className="overflow-x-auto pb-4 hide-scroller"
              >
                <div className="flex flex-row gap-5 min-w-max">
                  <div className="flex flex-col gap-[18px]">
                    <img
                      src={RadRover}
                      alt="RadRover"
                      className="w-[235px] h-[184px] object-cover"
                    />
                    <span className="text-sm">RadRover Electric Fat</span>
                  </div>
                  {/* Recommendation section */}

                  <div className="flex flex-col gap-[18px]">
                    <img
                      src={RipCurrent}
                      alt="RipCurrent"
                      className="w-[235px] h-[184px] object-cover"
                    />
                    <span className="text-sm">RipCurrent S</span>
                  </div>
                  {/* Recommendation section */}
                  <div className="flex flex-col gap-[18px]">
                    <img
                      src={ArielRider}
                      alt="Ariel Rider"
                      className="w-[235px] h-[184px] object-cover"
                    />
                    <span className="text-sm">Ariel Rider Grizzly</span>
                  </div>
                  {/* Recommendation section */}
                  <div className="flex flex-col gap-[18px]">
                    <img
                      src={QuietKat}
                      alt="QuietKat"
                      className="w-[235px] h-[184px] object-cover"
                    />
                    <span className="text-sm">QuietKat Jeep</span>
                  </div>
                  {/* Recommendation section */}
                  <div className="flex flex-col gap-[18px]">
                    <img
                      src={M2SBike}
                      alt="M2S Bike"
                      className="w-[235px] h-[184px] object-cover"
                    />
                    <span className="text-sm">M2S Bikes All Terrain R750</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppWrapper>
    </RequireAuth>
  );
};

export default Details;
