import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import Card2 from "../../assets/img/nezzcard-2.png";
import Cards2 from "../../assets/img/cards2.png";
import { CustomButton } from "../../components/buttons/CustomButton";
import { Helmet } from "react-helmet-async";
import Navbar from "../../components/navbar/Navbar";
import HowItWorks from "./components/HowItWorks";
import Cards from "../../assets/img/cards.png";
import Brands from "../../assets/img/brands.png";
import "./landing.css";
//import ExitableSection from "../../components/navbar/exitableSection";
import { track } from "@vercel/analytics";

const Landing = () => {
  //const navigate = useNavigate();

  const handleApply = () => {
    const url = "https://dbwe9d9cfaa.typeform.com/to/FBYpvPqv";
    window.open(url, "_self");
  };

  useEffect(() => {
    console.log(window.innerWidth);
  }, []);

  const brandImages = [Brands, Brands];

  return (
    <>
      <Helmet>
        <title>
          Nezz - The #1 End-to-End Marketplace for Your High-Value Items
        </title>
        <meta
          name="description"
          content="Join our financial ecosystem built on trust and transparency. Our AI-driven 
          platform ensures secure and hassle-free transactions, putting trust back in your hands."
        />
        <link rel="canonical" href="https://nezzapp.com/" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "name": "Nezz",
              "url": "https://nezzapp.com"
            }
          `}
        </script>
        <meta property="og:site_name" content="Nezz" />
        <meta
          property="og:title"
          content="Nezz - The #1 End-to-End Marketplace for Your High-Value Items"
        />
        <meta
          property="og:description"
          content="Join our financial ecosystem built on trust and transparency. Our AI-driven 
          platform ensures secure and hassle-free transactions, putting trust back in your hands."
        />
        <meta property="og:url" content="https://nezzapp.com" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="relative bg-black text-white font-jarkata w-full lg:h-screen overflow-scroll overflow-x-hidden lg:bg-nobanner bg-banner">
        <Navbar />

        <div className="w-full h-full mt-48 px-10 md:mx-10 flex md:flex-col flex-col font-sfpro items-start text-white text-4xl lg:text-6xl font-medium text-left md:text-left ">
          <div className="text-left relative">
            <div className={`relative lg:w-full w-screen h-full`}>
              <span className="lg:inline block">The #1 End-to-End</span>{" "}
              <span className="landing-text lg:inline block">Marketplace</span>{" "}
              <span className="lg:block inline">
                for <span className="landing-text">High-Value</span>{" "}
                <span className="lg:inline block">Transactions</span>
              </span>
            </div>
          </div>
          <div className="">
            <div className="mt-7 lg:block hidden">
              <CustomButton
                labelText="Get Priority Access"
                containerVariant="lg:text-sm text-xs px-4 py-5 rounded-lg"
                variant="font-bold "
                onClick={() => {
                  track("Get Access tf"); //top fold
                  handleApply();
                }}
              />
            </div>
          </div>
          <div className="lg:block hidden w-full">
            <img src={Cards} alt="cards" className="w-full pr-12 mt-24" />
          </div>
          <div className="hidden md:flex justify-end z-50 ml-10 w-full"></div>
        </div>
        <div className="z-50 lg:pt-[200px] lg:mt-0 mt-32 ">
          <HowItWorks />
        </div>
        <div className="relative w-full lg:h-[183px] h-full px-5 flex font-sfpro items-center justify-center text-white text-4xl md:text-7xl lg:mb-0 mt-[-40px] mb-10 lg:mt-10 font-bold text-center overflow-hidden">
          {/* ... existing code ... */}

          <motion.div
            className="flex"
            initial={{ x: 0 }}
            animate={{ x: "-100%" }}
            transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
            style={{ display: "flex", width: `${brandImages.length * 50}%` }} // Set width based on number of images
          >
            {brandImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`brand-${index}`}
                className="w-auto"
              />
            ))}
          </motion.div>
        </div>

        <div className="relative w-full md:h-full px-5 flex font-sfpro items-center justify-center text-white text-4xl lg:text-7xl md:text-4xl font-bold md:text-left text-center">
          {/* <div className="absolute gradient top-0 left-2 md:left-2/3 " /> */}

          <div className="w-full bg-custom-gradient md:rounded-t-custom rounded-xl mx-5 md:mx-10 p-5 flex flex-col md:flex-row z-50 justify-between items-center text-white">
            <div className="w-full pl-0 pt-10 md:pt-0 md:pl-20">
              Spend Directly from Your Account
            </div>
            <div className="w-full flex justify-center md:justify-end">
              <img className="object-contain" src={Card2} alt="" />
            </div>
          </div>
        </div>

        <div className="relative w-full h-full px-5  flex font-sfpro items-center justify-center text-white text-lg md:text-4xl lg:text7xl lg:m-0 lg:mt-10 mt-20 font-bold text-center overflow-hidden">
          {/* <div className="absolute gradient bottom-0 left-2 md:left-2/3 z-1" /> */}

          <div className="">
            <span className="block">Join our Financial Ecosystem Built</span>{" "}
            <span className="block">on Trust and Transparency</span>
            <div className="text-[12px] md:text-[18px] font-light mt-5 lg:px-0 md:px-20 px-10 ">
              AI-driven platform ensuring secure and hassle-free transactions,
              putting trust back in your hands
            </div>
            <div className="mt-7">
              <CustomButton
                labelText="Get Priority Access"
                containerVariant="text-sm px-3 py-2 rounded-full"
                variant="font-bold "
                onClick={() => {
                  track("Get Access bf"); //bottom fold
                  handleApply();
                }}
              />
            </div>
          </div>
        </div>
        <footer className="bg-black text-white lg:py-4 py-2 lg:mt-10 mt-28 md:px-16 px-10 lg:px-20 mb-6">
          <div className="container mx-auto text-center flex-row flex text-[10px] justify-between  lg:pb-10 ">
            <div>Copyright © 2024 Nezz. All Rights Reserved.</div>
            <a
              href="https://www.linkedin.com/company/nezzapp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Landing;
