import { CustomButton } from "../../../../components/buttons/CustomButton"; // Importing CustomButton component
import { ReactComponent as X } from "../../../../assets/svg/x.svg"; // Importing close icon

const TransferFail = ({ handleClose }) => {
  return (
    <div className="flex flex-col relative">
      <X
        className="absolute top-[-20px] left-0 hover:cursor-pointer" // Close icon with hover effect
        onClick={handleClose} // Close the modal when clicked
      />

      <div className="flex flex-col justify-center">
        <div className="text-[31px] font-bold text-center">Sorry</div>{" "}
        {/* Title for the failure message */}
        <div className="text-[15px] text-wrap pl-[78px] pr-[42px] mt-[40px]">
          We were unable to complete your transfer. This can be due to
          insufficient funds in your wallet or because the maximum number of
          transactions for this time period has been reached. Please check the
          balance or transaction limits for your wallet. If this issue persists,
          please contact us...
        </div>{" "}
        {/* Detailed message explaining the failure reason */}
        <div className="flex justify-center mt-[40px] mb-[28px]">
          <CustomButton
            buttonVariant="secondary" // Button variant
            labelText="OK" // Button label
            containerVariant="py-[7px] px-[74px] rounded-full flex justify-center" // Button styling
            onClick={handleClose} // Close the modal when clicked
          />
        </div>
      </div>
    </div>
  );
};

export default TransferFail; // Export the TransferFail component
