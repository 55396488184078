import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Preloader from "../mics/Preloader";

const TextInput = forwardRef(
  (
    {
      autoFocus = false,
      containerVariant = "w-full flex flex-col",
      type = "text",
      name,
      label,
      handleChange,
      handleBlur,
      value = "",
      error,
      hasError = false,
      placeHolder = "Enter text",
      variant = "text-black w-full h-12 text-lg px-5",
      icon = {
        active: false,
        variant: "",
        preview: null,
      },
      isDisabled = false,
      isLoading = false,
      success = false,
      maxLength,
      isPin,
      isCurrencyInput = false,
      isLabelBold = false,
      isBorderBold = false, // New prop with default value false
    },
    ref
  ) => {
    // Effects
    useEffect(() => {
      if (value !== "") {
        setLocalValue(value);
      }
    }, [value]);

    const [localValue, setLocalValue] = useState(value);

    const validationFulfilled = value !== "" && success;

    const handleInputChange = (e) => {
      let inputValue = e.target.value;

      if (isCurrencyInput) {
        // Remove non-numeric characters except for the decimal point
        inputValue = inputValue.replace(/[^0-9.]/g, "");

        // Format the number with commas
        const formattedValue = new Intl.NumberFormat().format(inputValue);
        handleChange({ target: { name, value: formattedValue } });
      } else {
        setLocalValue(inputValue);
        handleChange(e);
      }
    };

    return (
      <div className={`${containerVariant}`}>
        {label && (
          <div className="flex items-center justify-between font-jarkata font-normal">
            <label
              htmlFor={`input-${name}`}
              className={`text-xs mb-2.5
                          ${isDisabled ? "text-gray-500" : "text-black"}
                          ${isLabelBold ? "font-bold" : "font-normal"}`}
            >
              {label}
            </label>
          </div>
        )}
        {icon?.active && <span className={icon.variant}>{icon.preview}</span>}
        <div className="relative">
          {isLoading && (
            <span className="absolute text-[#AB0B4B] ml-5 right-1.5 top-3">
              <Preloader
                variant="w-6 h-6"
                currentColor="#AB0B4B"
                currentFill="#F8E8E8"
              />
            </span>
          )}
          {validationFulfilled && (
            <span className="absolute text-[#AB0B4B] ml-5 right-1.5 top-3">
              {"good"}
            </span>
          )}
          <div className="relative">
            <input
              ref={ref}
              name={name}
              type={type}
              className={`focus:outline-none text-sm border ${
                isBorderBold ? "border-black" : "border-[#858585]"
              } z-2 bg-[#ffffff] bg-opacity-10 outline-none placeholder:text-xs placeholder:text-[#00000098] rounded-xl
            ${icon?.active && "px-12"}
            ${isCurrencyInput && "pl-8"}
            ${
              isDisabled
                ? `cursor-not-allowed bg-gray-500`
                : "bg-lib-alat-gray-input placeholder:text-gray-500 border-lib-alat-gray-input-border"
            }
            ${
              success &&
              !hasError &&
              "valid:border-[#3BB54A] focus:valid:border-[#3BB54A]"
            }
            ${hasError && "border-red-500 focus:border-red-500"}
            ${variant}`}
              value={localValue}
              onChange={handleInputChange}
              onBlur={handleBlur}
              placeholder={placeHolder}
              disabled={isDisabled}
              autoFocus={autoFocus}
              maxLength={maxLength}
            />
            {isCurrencyInput && (
              <span
                className={`text-black absolute left-3 top-1/2 transform -translate-y-1/2`}
              >
                $
              </span>
            )}
          </div>
        </div>
        {hasError && (
          <div className="flex gap-2">
            <p className="text-red-500 text-[10px] h-auto py-1 font-openSans">
              {error}
            </p>
          </div>
        )}
      </div>
    );
  }
);

TextInput.propTypes = {
  autoFocus: PropTypes.bool,
  containerVariant: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string,
  hasError: PropTypes.bool,
  placeHolder: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.shape({
    active: PropTypes.bool,
    variant: PropTypes.string,
    preview: PropTypes.element,
  }),
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  success: PropTypes.bool,
  maxLength: PropTypes.number,
  isPin: PropTypes.bool,
  isCurrencyInput: PropTypes.bool,
  isLabelBold: PropTypes.bool,
  isBorderBold: PropTypes.bool, // Added this line
};

export default TextInput;
