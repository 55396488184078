import React, { useState } from "react";
import { ReactComponent as Checked } from "../../../assets/svg/check-circle.svg";
import { ReactComponent as NotChecked } from "../../../assets/svg/x-circle.svg";
import { ReactComponent as Marked } from "../../../assets/svg/tick-square-active.svg";
import { ReactComponent as Unmarked } from "../../../assets/svg/tick-square.svg";
import bestdeal from "../../../assets/img/bestdeal.png";
import CenterModal from "../../../components/Modal/CenterModal";
import { CustomButton } from "../../../components/buttons/CustomButton";

const PricingCard = ({ title, benefits, selectedPricing, id }) => {
  const [detailsModal, toggleDetailsModal] = useState(false);
  return (
    <>
      <div
        className={`relative w-full h-full ${
          selectedPricing === id
            ? "bg-[#9747FF12] border-[#9747FF]"
            : "border-[#494949]"
        } border rounded-xl space-y-3 p-4`}
      >
        {id === 1 && (
          <img className="absolute -right-12 -top-12" src={bestdeal} alt="" />
        )}
        <div className="font-bold text-xl">
          <span>{selectedPricing === id ? <Marked /> : <Unmarked />}</span>
          <span>{title}</span>
        </div>
        <p className="text-sm">Benefits</p>
        <div>
          {benefits?.map((x) => (
            <div className="flex items-center gap-2 space-y-2 text-sm">
              <span>{x?.active ? <Checked /> : <NotChecked />}</span>
              <span className="">{x?.name}</span>
            </div>
          ))}
        </div>
        <p
          onClick={() => toggleDetailsModal(true)}
          className="cursor-pointer font-medium text-xs pt-5 text-center"
        >
          View Details
        </p>
      </div>

      {detailsModal && (
        <CenterModal
          showCloseIcon={false}
          width="50%"
          handleClose={() => toggleDetailsModal(false)}
        >
          <div className="">
            <p className="text-center">
              {id === 1
                ? "If the “Pay now” option is selected, you will be charged a flat fee of $18.99 per product sold"
                : "If the “Pay later” option is selected, you will be charged 10% of the products listing price. (E.g  if a product is sold for $500 you will paying $50) "}
            </p>
            <div className="w-[70%] mx-auto text-center mt-10">
              <CustomButton
                labelText={"Close"}
                containerVariant="text-sm px-5 py-3 rounded-full w-[50%]"
                variant="font-bold"
                buttonVariant="secondary"
                onClick={() => toggleDetailsModal(false)}
                // isDisabled={
                //   values.itemName.trim() === "" ||
                //   base64Urls.every((file) => file === null) ||
                //   load
                // }
              />
            </div>
          </div>
        </CenterModal>
      )}
    </>
  );
};

export default PricingCard;
