import { useState } from "react";
import { db } from "../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

const useAccountDetails = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userInterests, setUserInterests] = useState([null]);
  const userEmail = sessionStorage.getItem("userEmail");
  const [loading, toggleLoading] = useState(false);

  const getUser = async () => {
    try {
      toggleLoading(true);
      const userRef = doc(db, "users", userEmail);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        setCurrentUser(docSnap.data());
      }
    } catch (error) {
      console.error(error);
    } finally {
      toggleLoading(false);
    }
  };

  const getUserInterest = async () => {
    try {
      toggleLoading(true);// it will be the users's first time so no loading needed
      const userRef = doc(db, "interests", userEmail);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        setUserInterests(docSnap.data());
      }
    } catch (error) {
      console.error(error);
    } finally {
      toggleLoading(false); //shouldn't be here either
    }
  };

  return {
    loading,
    getUser,
    currentUser,
    userInterests,
    getUserInterest,
  };
};

export default useAccountDetails;
