import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleCardModal from "../../../../components/Modal/SimpleCardModal";
import { CustomButton } from "../../../../components/buttons/CustomButton";
import { nextStep, previousStep } from "../../../../redux/reducers/reducer";
import PricingCard from "../PricingCard";
import { pricingTiers } from "../constants";
import RequireAuth from "../../../../components/RequireAuth";
import { formatNumberToCurrency } from "../../../../utils/libs";

const Step3 = ({ listingData }) => {
  const dispatch = useDispatch();
  const [load] = useState(false);
  const [selectedPricing, setSelectedPricing] = useState(1);


  const price = useSelector((state) => state.step.price);
  const numericPrice = parseFloat(price.replace(/,/g, ""));
  const quantity = useSelector((state) => state.step.quantity);
  const payNowPrice = 18.99 * quantity;
  const payLaterPrice = (numericPrice * 0.1);

  useEffect(() => {
    if (listingData?.selectedPricing) {
      setSelectedPricing(listingData?.selectedPricing);
    }
  }, [listingData]);

  return (
    <RequireAuth>
      <div className="w-full flex justify-center my-10">
        <SimpleCardModal width="700px">
          <>
            <div className="text-center">
              <h1 className="font-medium text-2xl">Pricing Options</h1>
              <div className="font-light leading-tight text-sm">
                In order to help listing process to be smooth and seamless, you
                will <br /> need to select one of the payment options below.
              </div>
            </div>

            <div className="w-full my-10 px-10 flex items-center justify-center gap-10">
              <div
                className="w-full cursor-pointer"
                onClick={() => setSelectedPricing(1)}
              >
                <PricingCard
                  id={1}
                  title={`Pay Now ${formatNumberToCurrency({
                    number: payNowPrice,
                    currencyCode: 2,
                    precision: 2,
                  })}`}
                  //TODO : We need to indicate how much their saving in red beneath the pay now and pay later
                  benefits={pricingTiers.payNow}
                  selectedPricing={selectedPricing}
                />
              </div>
              <div
                className="w-full cursor-pointer"
                onClick={() => setSelectedPricing(2)}
              >
                <PricingCard
                  id={2}
                  title={`Pay Later ${formatNumberToCurrency({
                    number: payLaterPrice,
                    currencyCode: 2,
                    precision: 0
                  })}/item sold`}
                  benefits={pricingTiers.payLater}
                  selectedPricing={selectedPricing}
                />
              </div>
            </div>

            <div className="w-[70%] mx-auto text-center">
              <CustomButton
                labelText={load ? "Please wait" : "Continue 3/5"}
                containerVariant="text-sm px-5 py-3 rounded-full w-full"
                variant="font-bold"
                buttonVariant="secondary"
                onClick={() =>
                  dispatch(nextStep({ selectedPricing: selectedPricing }))
                }
                // isDisabled={
                //   values.itemName.trim() === "" ||
                //   base64Urls.every((file) => file === null) ||
                //   load
                // }
              />
              <button
                onClick={() => dispatch(previousStep())}
                className="mt-4 text-sm cursor-pointer"
              >
                Back
              </button>
            </div>
          </>
        </SimpleCardModal>
      </div>
    </RequireAuth>
  );
};

export default Step3;
