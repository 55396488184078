// reducer.js
import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  aiData: {},
  currentStep: 1,
  base64Urls: "",
  itemName: "",
  description: "",
  price: "",
  currency: "",
  condition: "",
  quantity: 1,
};

const stepSlice = createSlice({
  name: "Listings",
  initialState,
  reducers: {
    storeData: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    },
    nextStep: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        currentStep: state?.currentStep < 4 && state?.currentStep + 1,
      };
    },
    previousStep: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        currentStep: state?.currentStep - 1,
      };
    },
    resetSteps: (state, action) => {
      const { payload } = action;
      localStorage.removeItem('modalStep');
      return {
        ...state,
        ...payload,
        currentStep: 1,
      };
    },
    clearListingData: (state) => {
      localStorage.removeItem('modalStep');
      return initialState;
    },
  },
});

export const {
  storeData,
  nextStep,
  previousStep,
  resetSteps,
  clearListingData,
} = stepSlice.actions;

export default stepSlice.reducer;
