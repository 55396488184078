import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ColorThief from "color-thief-browser";
import { ReactComponent as ArrowRight } from "../../assets/svg/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/svg/arrow-left.svg";

const ImageViewer = ({
  images,
  variant = "w-full h-64 rounded-xl object-contain",
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dominantColor, setDominantColor] = useState(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const img = imageRef.current;
    if (img && img.complete) {
      extractColor();
    } else if (img) {
      img.addEventListener("load", extractColor);
    }

    return () => {
      if (img) {
        img.removeEventListener("load", extractColor);
      }
    };
  }, [selectedIndex]);
  let isBlackAndWhite = false;
  const extractColor = () => {
    const colorThief = new ColorThief();
    const img = imageRef.current;
    if (img && img.complete) {
      try {
        const color = colorThief.getColor(img);
        setDominantColor(`rgb(${color[0]}, ${color[1]}, ${color[2]})`);
        // Check if the image is black and white
        isBlackAndWhite = color[0] === color[1] && color[1] === color[2];
        if (isBlackAndWhite) {
          console.log("Image is black and white");
          setDominantColor("#FFFFFF");
        }
      } catch (error) {
        console.error("Error extracting color:", error);
      }
    }
  };

  const handlePrev = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleNext = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div className="flex flex-col items-center">
      <div className="mb-4 w-full">
        <img
          ref={imageRef}
          src={images[selectedIndex]}
          alt="Selected"
          className={variant}
          style={{
            backgroundColor: isBlackAndWhite
              ? "#D9D9D9"
              : dominantColor || "#D9D9D9",
          }}
          crossOrigin="anonymous"
        />
      </div>
      <div className="flex items-center gap-2">
        {images.length > 1 && (
          <button
            onClick={handlePrev}
            className="bg-white bg-opacity-50 p-2 rounded-full"
          >
            <ArrowLeft className="h-4 w-4 text-gray-800" />
          </button>
        )}
        <div className="flex gap-2 overflow-x-auto">
          {images?.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index}`}
              className={`cursor-pointer shadow-md ${
                selectedIndex === index ? "w-12 h-12" : "w-10 h-10"
              }`}
              onClick={() => setSelectedIndex(index)}
            />
          ))}
        </div>
        {images.length > 1 && (
          <button
            onClick={handleNext}
            className="bg-white bg-opacity-50 p-2 rounded-full"
          >
            <ArrowRight className="h-4 w-4 text-gray-800" />
          </button>
        )}
      </div>
    </div>
  );
};

ImageViewer.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  variant: PropTypes.string,
};

export default ImageViewer;
