import React, { useState } from "react"; // Importing React and useState hook

// Importing necessary components and assets
import RequireAuth from "../../components/RequireAuth"; // Component to enforce authentication
import { Helmet } from "react-helmet-async"; // For managing document head
import AppWrapper from "../../components/AppWrapper"; // Wrapper for layout
import BOA from "./assets/boa.png"; // Bank of America logo
import CreditCards from "./assets/creditcards.png"; // Credit card image
import { ReactComponent as AddCircle } from "../../assets/svg/add-circle.svg"; // Add circle icon
import { CustomButton } from "../../components/buttons/CustomButton"; // Custom button component
import { ReactComponent as ProfileCircle } from "../../assets/svg/profile-circle.svg"; // Profile circle icon
import SearchInput from "../../components/reusables/SearchInput"; // Search input component
import CenterModal from "../../components/Modal/CenterModal"; // Modal component
import BankSelect from "./components/BankSelect/BankSelect"; // Bank selection component
import MoneyTransfer from "./components/MoneyTransfer/MoneyTransfer"; // Money transfer component
import TransferFail from "./components/TransferFail/TransferFail"; // Transfer failure component
import Verification from "./components/Verification/Verification"; // Verification component
import PaymentSuccess from "./components/PaymentSuccess/PaymentSuccess"; // Payment success component
import Redirecting from "./components/Redirecting/Redirecting"; // Redirecting component
import ProductInformation from "./components/ProductInformation/ProductInformation"; // Product info component
import PaymentPaid from "./components/PaymentSuccess/PaymentPaid"; // Payment paid component

const Wallet = () => {
  let balance = 500; // Initial balance
  // State variables for managing modal visibility and transfer status
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCenterModalOpen, setCenterModalOpen] = useState(false);
  const [transferFailed, setTransferedFailed] = useState(false);
  const [verificationOpen, setVerificationOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [productInfoOpen, setProductInfoOpen] = useState(false);
  const [transferOpen, setTransferOpen] = useState(false);
  const [payFlow, setPayFlow] = useState(false);
  const [transferVariant, setTransferVariant] = useState(""); // Variant for transfer type

  // Function to handle transfer failure
  const failTransfer = () => {
    setTransferedFailed(true);
    openCenterModal(); // Open center modal on failure
  };

  // Function to open transfer modal
  const openTransferModal = () => {
    setTransferOpen(true);
    openModal(); // Open main modal
  };

  // Function to close transfer modal
  const closeTransferModal = () => {
    closeModal(); // Close main modal
    setTransferOpen(false);
  };

  // Function to close payment flow
  const closePayFlow = () => {
    closeModal(); // Close main modal
    setPayFlow(false);
  };

  // Function to open product modal
  const openProductModal = () => {
    setProductInfoOpen(true);
    transferPay(); // Set transfer variant to pay
    setPayFlow(true);
    console.log("Product"); // Log product action
    openCenterModal(); // Open center modal
  };

  // Function to close product modal
  const closeProductModal = () => {
    setProductInfoOpen(false);
    closeCenterModal(); // Close center modal
  };

  // Function to open payment modal
  const openPayment = () => {
    openModal(); // Open main modal
    setPaymentOpen(true);
  };

  // Function to close payment modal
  const closePayment = () => {
    setPaymentOpen(false);
    closeModal(); // Close main modal
  };

  // Function to reset transfer failure state
  const passTransfer = () => setTransferedFailed(false);

  // Functions to set transfer variant
  const transferBank = () => setTransferVariant("transfer");
  const transferAdd = () => setTransferVariant("add");
  const transferPay = () => setTransferVariant("pay");

  // Function to open verification modal
  const verificationModalOpen = () => {
    setTransferedFailed(false);
    openCenterModal(); // Open center modal
  };

  // Functions to manage modal visibility
  const openCenterModal = () => setCenterModalOpen(true);
  const closeCenterModal = () => setCenterModalOpen(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div>
      <RequireAuth>
        <Helmet>
          <title> Wallet | Nezz</title> {/* Set page title */}
        </Helmet>
        <AppWrapper>
          <div className="flex lg:flex-row flex-col lg:pl-[50px] pl-[10px] lg:pr-[30px]">
            <div className="flex flex-col lg:w-[725px] w-full pr-[21px] justify-center pb-[54px]">
              <div className="flex lg:flex-row flex-col lg:justify-between justify-center">
                <div className="flex flex-col">
                  <div className="font-bold text-[40px]">My Wallet</div>{" "}
                  {/* Wallet title */}
                </div>
                <div className="flex-col flex ">
                  <div className="flex flex-col">
                    <div className="text-[28px] lg:mx-auto">Added Accounts</div>{" "}
                    {/* Added accounts title */}
                    <div className="flex flex-row mt-[5px] w-[241.84px] justify-between">
                      <img
                        alt="Bank Account"
                        src={BOA} // Bank of America logo
                        className="w-[46px] h-[26px]"
                      />
                      <img
                        alt="Bank Account"
                        src={CreditCards} // Credit card image
                        className="w-[186.84px] h-[26px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col lg:items-center lg:justify-between pt-[21px]">
                <div className="font-medium text-[30px]">
                  Total Balance ${balance}
                </div>{" "}
                {/* Display total balance */}
                <div className="flex lg:flex-row flex-col lg:items-center lg:gap-[2px] lg:justify-end gap-x-0 gap-y-2">
                  <AddCircle className="w-[34px] h-[34px]" stroke="#616161" />{" "}
                  {/* Add icon */}
                  <div className="text-[18px] text-[#616161] leading-[1.2]">
                    Add new payment
                    <br />
                    Additional text on a new line
                  </div>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col gap-y-4 lg:pr-[29px] lg:pl-[53px] justify-between mt-[86px]">
                <div className="lg:w-[286px] w-full">
                  <CustomButton
                    labelText="Add Funds" // Button label
                    buttonVariant="secondary" // Button variant
                    containerVariant="lg:w-[286px] h-[44px] items-center w-full rounded-full flex justify-center" // Button styling
                    onClick={() => {
                      // Reset states and open add funds modal
                      setPaymentOpen(false);
                      setTransferOpen(false);
                      setVerificationOpen(false);
                      setTransferedFailed(false);
                      setProductInfoOpen(false);
                      setPayFlow(false);
                      transferAdd(); // Set transfer variant to add
                      setIsModalOpen(true); // Open modal
                    }}
                  />
                </div>
                <div className="lg:w-[286px] w-full">
                  <CustomButton
                    labelText="Transfer Funds" // Button label
                    buttonVariant="secondary" // Button variant
                    containerVariant="lg:w-[286px] h-[44px] items-center w-full rounded-full flex justify-center" // Button styling
                    onClick={() => {
                      // Reset states and open transfer funds modal
                      setPaymentOpen(false);
                      setTransferOpen(false);
                      setVerificationOpen(false);
                      setTransferedFailed(false);
                      setProductInfoOpen(false);
                      transferBank(); // Set transfer variant to bank
                      setPayFlow(false);
                      setIsModalOpen(true); // Open modal
                    }}
                  />
                </div>
              </div>
              <div className="flex w-full bg-[#00000007] justify-center mt-[35px]">
                <div className="w-[700px] h-[522px] shadow-lg flex flex-col ">
                  <div className="font-bold text-[24px] text-[#616161] pl-[20px] pt-[23px]">
                    Recent Transactions {/* Recent transactions title */}
                  </div>
                  {/* Transaction entries */}
                  <div className="flex mt-2">
                    <div className="flex flex-col pl-5 w-full border-b-[1px] border-[#000000]">
                      <div className="text-[15px] mb-5">May 11, Sat</div>
                      <div className="flex flex-row justify-between w-full">
                        <div className="text-[20px] leading-[1.2]">
                          Added to Threshold <br />
                          Transfer from 128 09 896
                        </div>
                        <div className="text-[18px] font-bold text-[#9747FF] pr-5 flex items-center">
                          5,000
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-2">
                    <div className="flex flex-col pl-5 w-full border-b-[1px] border-[#000000]">
                      <div className="text-[15px] mb-5">May 1, Wed</div>
                      <div className="flex flex-row justify-between w-full">
                        <div className="text-[20px] leading-[1.2]">
                          James Lane <br />
                          Transfer from 128 09 896
                        </div>
                        <div className="text-[18px] font-bold text-[#9747FF] pr-5 flex items-center">
                          300
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-2">
                    <div className="flex flex-col pl-5 w-full border-b-[1px] border-[#000000]">
                      <div className="text-[15px] mb-5">May 1, Wed</div>
                      <div className="flex flex-row justify-between w-full">
                        <div className="text-[20px] leading-[1.2]">
                          James Lane <br />
                          Transfer from 128 09 896
                        </div>
                        <div className="text-[18px] font-bold text-[#9747FF] pr-5 flex items-center">
                          7,000
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-2">
                    <div className="flex flex-col pl-5 w-full">
                      <div className="text-[15px] mb-5">May 1, Wed</div>
                      <div className="flex flex-row justify-between w-full">
                        <div className="text-[20px] leading-[1.2]">
                          James Lane <br />
                          Transfer from 128 09 896
                        </div>
                        <div className="text-[18px] font-bold text-[#9747FF] pr-5 flex items-center">
                          300
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[51px] mb-[88px] w-[1px] border-[1px] border-[#706E6E63] "></div>
            <div className="flex flex-col lg:w-[542px] w-full lg:ml-4 mb-[36px] lg:pr-0 pr-[21px]">
              <div className="flex flex-col h-[700px] bg-[#00000007] shadow-lg">
                <div className="w-full h-[64.5px] rounded-lg bg-[#9747FF] shadow-md justify-between flex flex-row items-center pl-[22px]">
                  <div className="flex flex-row items-center">
                    <div className="flex relative">
                      {" "}
                      {/* Keep relative for positioning */}
                      <ProfileCircle
                        stroke="#FFFFFF"
                        className="w-[33px] h-[33px]"
                      />
                      <AddCircle
                        className="absolute bottom-0 right-0 w-[10px] h-[10px]"
                        stroke="#9747FF"
                        fill="#FFFFFF"
                      />
                    </div>
                    <div className="text-[14px] font-extralight text-white">
                      Add Recipient {/* Add recipient label */}
                    </div>
                  </div>
                  <div className="flex flex-col pr-[14px] items-end">
                    <div className="text-[14px] font-medium text-white">
                      Current Balance
                    </div>
                    <div className="text-[14px] font-medium text-white">
                      ${balance} {/* Display current balance */}
                    </div>
                  </div>
                </div>
                <div className="mx-[55px] h-[36px] mt-[22px]">
                  <SearchInput /> {/* Search input for recipients */}
                </div>
                <div className="mt-4 flex flex-col pl-[31px] mr-3">
                  <div className="text-[20px] font-semibold text-[#616161] mt-[25px]">
                    Quick Send {/* Quick send title */}
                  </div>
                  {/* Quick send recipient entries */}
                  <div className="flex flex-row items-center w-full">
                    <div className="relative w-[50px] h-[50px] mr-4">
                      <ProfileCircle
                        className="relative w-full h-full"
                        stroke="#FFFFFF"
                      />
                      <div
                        className="absolute inset-0 m-auto w-[83%] h-[70%]"
                        style={{
                          background:
                            "linear-gradient(to bottom, #F047FF, #5B608B)", // Gradient background
                          borderRadius: "50%", // Circular background
                          zIndex: -1, // Ensure gradient is behind the ProfileCircle
                        }}
                      ></div>
                    </div>
                    <div className="flex flex-col w-full">
                      <div className="text-[18px] font-bold m-0 leading-none">
                        MidnightWhisper {/* Recipient name */}
                      </div>
                      <div className="flex flex-row items-center justify-between pr-[10px]">
                        <div className="text-[16px] m-0 leading-none">
                          Ray-Ban Classic Sunglasses {/* Item description */}
                        </div>
                        <button
                          className="text-[#9747FF] text-[14px] hover:cursor-pointer"
                          onClick={openProductModal} // Open product modal on click
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center w-full mt-[30px]">
                    <div className="relative w-[50px] h-[50px] mr-4">
                      <ProfileCircle
                        className="relative w-full h-full"
                        stroke="#FFFFFF"
                      />
                      <div
                        className="absolute inset-0 m-auto w-[83%] h-[70%]"
                        style={{
                          background:
                            "linear-gradient(to bottom, #FFAA47, #5B608B)", // Gradient background
                          borderRadius: "50%", // Circular background
                          zIndex: -1, // Ensure gradient is behind the ProfileCircle
                        }}
                      ></div>
                    </div>
                    <div className="flex flex-col w-full">
                      <div className="text-[18px] font-bold m-0 leading-none">
                        SereneSeeker {/* Recipient name */}
                      </div>
                      <div className="flex flex-row items-center justify-between pr-[10px]">
                        <div className="text-[16px] m-0 leading-none">
                          Dyson V11 Torque Drive Vacuum {/* Item description */}
                        </div>
                        <div className="text-[#9747FF] text-[14px] hover:cursor-pointer">
                          Send {/* Send button for recipient */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center w-full mt-[30px]">
                    <div className="relative w-[50px] h-[50px] mr-4">
                      <ProfileCircle
                        className="relative w-full h-full"
                        stroke="#FFFFFF"
                      />
                      <div
                        className="absolute inset-0 m-auto w-[83%] h-[70%]"
                        style={{
                          background:
                            "linear-gradient(to bottom, #FF5D47, #5B608B)", // Gradient background
                          borderRadius: "50%", // Circular background
                          zIndex: -1, // Ensure gradient is behind the ProfileCircle
                        }}
                      ></div>
                    </div>
                    <div className="flex flex-col w-full">
                      <div className="text-[18px] font-bold m-0 leading-none">
                        CosmicNomad {/* Recipient name */}
                      </div>
                      <div className="flex flex-row items-center justify-between pr-[10px]">
                        <div className="text-[16px] m-0 leading-none">
                          Original 1977 Star Wars Figures{" "}
                          {/* Item description */}
                        </div>
                        <div className="text-[#9747FF] text-[14px] hover:cursor-pointer">
                          Send {/* Send button for recipient */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-[1px] border-[1px] border-[#BBBBBB61] ml-5 mr-5 mt-[22px]"></div>
                {/* Archive Section */}
                <div className="mt-4 flex flex-col pl-[31px] mr-3">
                  <div className="text-[20px] font-semibold text-[#616161]">
                    Archive {/* Archive title */}
                  </div>
                  {/* Archive recipient entries */}
                  <div className="flex flex-row items-center w-full">
                    <div className="relative w-[50px] h-[50px] mr-4">
                      <ProfileCircle
                        className="relative w-full h-full"
                        stroke="#FFFFFF"
                      />
                      <div
                        className="absolute inset-0 m-auto w-[83%] h-[70%]"
                        style={{
                          background:
                            "linear-gradient(to bottom, #F047FF, #5B608B)", // Gradient background
                          borderRadius: "50%", // Circular background
                          zIndex: -1, // Ensure gradient is behind the ProfileCircle
                        }}
                      ></div>
                    </div>
                    <div className="flex flex-col w-full">
                      <div className="text-[18px] font-bold m-0 leading-none">
                        MidnightWhisper {/* Archived recipient name */}
                      </div>
                      <div className="flex flex-row items-center justify-between pr-[10px]">
                        <div className="text-[16px] m-0 leading-none">
                          Ray-Ban Classic Sunglasses{" "}
                          {/* Archived item description */}
                        </div>
                        <div className="text-[#9747FF] text-[14px] hover:cursor-pointer">
                          Send {/* Send button for archived item */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center w-full mt-[30px]">
                    <div className="relative w-[50px] h-[50px] mr-4">
                      <ProfileCircle
                        className="relative w-full h-full"
                        stroke="#FFFFFF"
                      />
                      <div
                        className="absolute inset-0 m-auto w-[83%] h-[70%]"
                        style={{
                          background:
                            "linear-gradient(to bottom, #FFAA47, #5B608B)", // Gradient background
                          borderRadius: "50%", // Circular background
                          zIndex: -1, // Ensure gradient is behind the ProfileCircle
                        }}
                      ></div>
                    </div>
                    <div className="flex flex-col w-full">
                      <div className="text-[18px] font-bold m-0 leading-none">
                        SereneSeeker {/* Archived recipient name */}
                      </div>
                      <div className="flex flex-row items-center justify-between pr-[10px]">
                        <div className="text-[16px] m-0 leading-none">
                          Dyson V11 Torque Drive Vacuum{" "}
                          {/* Archived item description */}
                        </div>
                        <button
                          className="text-[#9747FF] text-[14px] hover:cursor-pointer"
                          onClick={openProductModal} // Open product modal on click
                        >
                          Send {/* Send button for archived item */}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center w-full mt-[30px]">
                    <div className="relative w-[50px] h-[50px] mr-4">
                      <ProfileCircle
                        className="relative w-full h-full"
                        stroke="#FFFFFF"
                      />
                      <div
                        className="absolute inset-0 m-auto w-[83%] h-[70%]"
                        style={{
                          background:
                            "linear-gradient(to bottom, #FF5D47, #5B608B)", // Gradient background
                          borderRadius: "50%", // Circular background
                          zIndex: -1, // Ensure gradient is behind the ProfileCircle
                        }}
                      ></div>
                    </div>
                    <div className="flex flex-col w-full">
                      <div className="text-[18px] font-bold m-0 leading-none">
                        CosmicNomad {/* Archived recipient name */}
                      </div>
                      <div className="flex flex-row items-center justify-between pr-[10px]">
                        <div className="text-[16px] m-0 leading-none">
                          Original 1977 Star Wars Figures{" "}
                          {/* Archived item description */}
                        </div>
                        <div className="text-[#9747FF] text-[14px] hover:cursor-pointer">
                          Send {/* Send button for archived item */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col bg-[#00000007] h-[142px] shadow-lg mt-[27px] pl-[72px] pt-[41px]">
                <div className="text-[18px] font-bold">
                  Do you to enable Auto-Fund {/* Auto-fund prompt */}
                </div>
                <div className="flex flex-row font-medium text-[18px] mt-[20px] gap-[100px]">
                  <div className="flex flex-row items-center">
                    <CustomButton
                      containerVariant="w-[26px] rounded-full flex justify-center mr-[18px]"
                      buttonVariant="secondary" // Button for Yes
                    />{" "}
                    Yes
                  </div>
                  <div className="flex flex-row items-center">
                    <CustomButton
                      containerVariant="w-[26px] rounded-full flex justify-center mr-[18px]"
                      buttonVariant="secondary" // Button for No
                    />{" "}
                    No
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Modal for various actions */}
          <div>
            {isModalOpen && (
              <div className="flex flex-row justify-end">
                <CenterModal
                  width="898px"
                  showCloseIcon={false}
                  handleClose={closeModal} // Close modal function
                  padding={false}
                  variant="relative bg-[#ffffff] border z-5000 rounded-3xl py-2 my-10 overflow-auto"
                  containerVariant="fixed font-jarkata top-0 left-0 w-full h-full bg-black/80 z-50 overflow-auto backdrop-filter backdrop-blur-sm flex justify-center items-start"
                >
                  {paymentOpen ? (
                    payFlow ? (
                      <PaymentPaid handleClose={closeModal} /> // Payment paid component
                    ) : (
                      <PaymentSuccess
                        transferVariant={transferVariant} // Pass transfer variant
                        handleClose={closePayment} // Close payment function
                        balance={balance} // Pass balance
                      />
                    )
                  ) : transferOpen ? (
                    <MoneyTransfer
                      balance={balance} // Pass balance
                      variant={transferVariant} // Pass transfer variant
                      verificationOpen={verificationModalOpen} // Open verification modal
                      handleClose={closeModal} // Close modal function
                    />
                  ) : (
                    <BankSelect
                      handleClose={closeModal} // Close modal function
                      transferFailed={failTransfer} // Handle transfer failure
                      transferVariant={transferVariant} // Pass transfer variant
                      verificationOpen={verificationModalOpen} // Open verification modal
                      balance={balance} // Pass balance
                    />
                  )}
                </CenterModal>
              </div>
            )}
            {isCenterModalOpen && (
              <div className="flex flex-row justify-end">
                <CenterModal
                  width="675px"
                  showCloseIcon={false}
                  handleClose={closeCenterModal} // Close center modal function
                  padding={false}
                  variant="relative bg-[#ffffff] border z-5000 rounded-3xl my-10 overflow-auto"
                  containerVariant="fixed font-jarkata top-0 left-0 w-full h-full bg-black/80 z-50 overflow-auto backdrop-filter backdrop-blur-sm flex justify-center items-start"
                >
                  {transferFailed ? (
                    <TransferFail handleClose={closeCenterModal} /> // Transfer fail component
                  ) : productInfoOpen ? (
                    <ProductInformation
                      handleClose={() => closeProductModal()} // Close product modal function
                      nextStep={() => openTransferModal()} // Open transfer modal function
                    />
                  ) : (
                    <Verification
                      handleClose={closeCenterModal} // Close verification modal function
                      openNextModal={openPayment} // Open payment modal function
                    />
                  )}
                </CenterModal>
              </div>
            )}
          </div>
        </AppWrapper>
      </RequireAuth>
    </div>
  );
};

export default Wallet; // Export Wallet component
