import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../view/Landing/components/NotFound";
import Landing from "../view/Landing/Landing";
import Login from "../view/Onboarding/Onboarding/Login/Login";
import Step1 from "../view/Onboarding/OnboardingSteps/Step1";
import Step2 from "../view/Onboarding/OnboardingSteps/Step2";
import Step3 from "../view/Onboarding/OnboardingSteps/Step3";
import ListingMain from "../view/Listing";
import ReviewListing from "../view/Listing/ReviewListing";
import ProtectedRoute from "../components/ProtectedRoute";
import ItemSearchDetail from "../view/ItemSearchDetails/ItemSearchDetail";
import Details from "../view/ItemSearchDetails/Details/Details";
import Dashboard from "../view/Dashboard/Dashboard";
import Account from "../view/Dashboard/Account/Account";
import Wallet from "../view/Wallet/Wallet";
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Landing />} />
      {/* Onboarding */}
      <Route path="/get-started" element={<Login />} />
      <Route element={<ProtectedRoute isNewUserRoute={true} />}>
        <Route path="/onboarding/step-1" element={<Step1 />} />
        <Route path="/onboarding/step-2" element={<Step2 />} />
        <Route path="/onboarding/step-3" element={<Step3 />} />
      </Route>
      {/* Listing */}
      <Route path="/list" element={<ListingMain />} />
      <Route path="/list/review-listing" element={<ReviewListing />} />
      <Route path="/search-results" element={<ItemSearchDetail />} />
      <Route path="/search-results/:id/details/" element={<Details />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dashboard/account" element={<Account />} />
      <Route path="wallet" element={<Wallet />} />
    </Routes>
  );
};

export default AppRoutes;
