import React, { useEffect } from "react";
import AppWrapper from "../../components/AppWrapper";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import Step1 from "./components/Listing/Step1";
import Step2 from "./components/Listing/Step2";
import Step3 from "./components/Listing/Step3";
import Step4 from "./components/Listing/Step4";
import { resetSteps } from "../../redux/reducers/reducer";

const ListingMain = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.step.currentStep);
  const listData = useSelector((state) => state?.step);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      dispatch(resetSteps());
      // Optionally, you can show a confirmation dialog
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dispatch]);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 listingData={listData} />;
      case 2:
        return <Step2 listingData={listData} />;
      case 3:
        return <Step3 listingData={listData} />;
      case 4:
        return <Step4 listingData={listData} />;
      default:
        return <Step1 listingData={listData} />;
    }
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [currentStep]);

  return (
    <>
      <Helmet>
        <title> Listing | Nezz</title>
      </Helmet>
      <AppWrapper>
        <div>{renderStep()}</div>
      </AppWrapper>
    </>
  );
};

export default ListingMain;
