export const currency = [
  { name: "USD", value: "USD", label: "USD" },
  { name: "CAD", value: "CAD", label: "CAD" },
];
export const deliveryOpt = [{ name: "Pick-up", value: "Delivery" }];

export const pricingTiers = {
  payNow: [
    { name: "Benefits", active: true },
    { name: "Listing Assists", active: true },
    { name: "Free Transfers and Deposits", active: true },
    { name: "Private & Public Listing", active: true },
    { name: "Multi Chat", active: true },
    { name: "Customer Support", active: true },
    { name: "No Minimum threshold limit", active: true },
  ],
  payLater: [
    { name: "Benefits", active: true },
    { name: "Listing Assists", active: true },
    { name: "Free Transfers and Deposits", active: true },
    { name: "Private & Public Listing", active: true },
    { name: "Multi Chat", active: false },
    { name: "Customer Support", active: false },
    { name: "No Minimum threshold limit", active: false },
  ],
};
