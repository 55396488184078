import React, { useEffect } from "react";
import { ReactComponent as PendingIcon } from "../../assets/svg/error-icon.svg";
import { ReactComponent as Close } from "../../assets/svg/Close.svg";

const CenterModal = ({
  icon = <PendingIcon />,
  title,
  subTitle,
  children,
  variant = "relative bg-[#ffffff] border z-5000 rounded-3xl py-2 mx-auto my-10 overflow-auto",
  containerVariant = "fixed font-jarkata top-0 left-0 w-full h-full bg-black/80 p-5 z-50 overflow-auto backdrop-filter backdrop-blur-sm flex justify-center items-start",
  info,
  handleClick,
  handleClose,
  handleClick2,
  padding = true,
  showCloseIcon = true, // Default to true for showing the close icon
  width = "auto",
  allowOutsideClick = true, // Default to true for allowing outside click to close
}) => {
  useEffect(() => {
    // Add 'modal-open' class to body when modal is open
    document.body.classList.add("modal-open");

    // Remove 'modal-open' class from body when modal is closed
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <div
      onClick={allowOutsideClick ? handleClose : null} // Conditionally allow outside click
      className={containerVariant}
    >
      <div
        className={variant}
        onClick={(e) => e.stopPropagation()}
        style={{ width: width, minWidth: "500px" }}
      >
        <div className={padding ? `mx-6 my-4` : null}>
          <div className="flex justify-between items-center">
            <p className="text-xl font-semibold text-left text-black">
              {title}
            </p>
            {showCloseIcon && ( // Conditionally render close icon
              <div className="cursor-pointer" onClick={handleClose}>
                <Close />
              </div>
            )}
          </div>
          <p className="text-[10px] font-normal text-left pt-0.5 text-nav-item-inactive">
            {subTitle}
          </p>
          <p className="text-xs text-lib-alat-black text-left">{info}</p>
          <div className={padding ? `mt-6 py-2 text-left` : `text-left`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenterModal;
