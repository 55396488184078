import * as Yup from "yup";

const usZipCodeRegex = /^\d{5}(-\d{4})?$/; // Matches U.S. zip codes (e.g., 12345 or 12345-6789)
const canadaPostalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/; // Matches Canadian postal codes (e.g., A1A 1A1)
const phoneNumberRegex = /^\(\d{3}\)-\d{3}-\d{4}$/; // Matches phone numbers in the format (000)-000-0000

export const listingValidationStep1 = () =>
  Yup.object().shape({
    itemName: Yup.string()
      .required("Item name is required")
      .min(2, "Item name should be well defined"),
  });

  export const listingValidationStep2 = () =>
  Yup.object().shape({
    price: Yup.string()
      .required("Price is required")
      .matches(/^[\d,]+$/, "Invalid price format"),
    description: Yup.string()
      .required("Description is required")
      .min(2, "Description must be at least 2 characters")
      .max(1000, "description must not exceed 1000 characters"),
    currency: Yup.string()
      .required("Select the currency"),
    condition: Yup.string()
      .required("Select a condition"),
  });

export const getStartedValidationSchema = (loginType) =>
  Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    firstName:
      loginType === 1
        ? Yup.string()
            .min(2, "First name is too short")
            .max(50, "First name is too long")
            .required("First name is required")
        : Yup.string().nullable(),
    lastName:
      loginType === 1
        ? Yup.string()
            .min(2, "Last name is too short")
            .max(50, "Last name is too long")
            .required("Last name is required")
        : Yup.string().nullable(),
  });

export const OnboardingValidationSchema = () =>
  Yup.object().shape({
    fullName: Yup.string()
      .required("Full name is required")
      .min(2, "Full name must be at least 2 characters")
      .max(50, "Full name must not exceed 50 characters"),
    userName: Yup.string()
      .required("Username is required")
      .min(3, "Username must be at least 3 characters")
      .max(30, "Username must not exceed 30 characters"),
    dob: Yup.date()
      //.required("Date of birth is required")
      .max(new Date(), "Date of birth cannot be in the future"),
    countryCode: Yup.string()
      .required("Country code is required"),
      // .matches(/^\+\d{1,3}$/, "Invalid country code"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(/^\d{10,15}$/, "Invalid phone number"),
    city: Yup.string()
      .required("City is required")
      .min(2, "City must be at least 2 characters")
      .max(50, "City must not exceed 50 characters"),
    zipCode: Yup.string()
      .required("Zip/Postal code is required")
      .test(
        'is-valid-zip-or-postal-code',
        'Invalid zip/postal code', // Error message
        value => usZipCodeRegex.test(value) || canadaPostalCodeRegex.test(value)),
  });


