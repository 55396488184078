import RequireAuth from "../../../components/RequireAuth";
import { Helmet } from "react-helmet-async";
import AppWrapper from "../../../components/AppWrapper";
import { ReactComponent as ProfileCircle } from "../../../assets/svg/profile-circle.svg";
import TextInput from "../../../components/reusables/TextInput";
import SelectField from "../../../components/reusables/SelectField";
import TextArena from "../../../components/reusables/TextArena";
import { CustomButton } from "../../../components/buttons/CustomButton";

const Dashboard = () => {
  return (
    <RequireAuth>
      <Helmet>
        <title> Dashboard | Nezz</title>
      </Helmet>
      <AppWrapper>
        <div className="flex flex-col justify-center ">
          <div className="relative mx-auto mt-8 w-[108px] h-[108px]">
            <ProfileCircle
              className="relative w-full h-full"
              stroke="#FFFFFF"
            />
            <div
              className="absolute inset-0 m-auto w-[83%] h-[83%]"
              style={{
                background: "linear-gradient(to bottom, #AED259, #596C2E)",
                borderRadius: "50%", // Ensures the gradient background is circular
                zIndex: -1, // Ensures the gradient is behind the ProfileCircle
              }}
            ></div>
          </div>
          <div className="text-[24px] font-bold text-center">SilverSphinx</div>
          <div className="text-[18px] text-center mb-6">@ssphinx</div>
          <TextInput
            containerVariant="mx-auto flex flex-col pb-6"
            variant="text-black w-[513px] h-[45px] text-lg px-5"
            placeHolder="Username"
          />
          <TextInput
            containerVariant="mx-auto flex flex-col pb-6"
            variant="text-black w-[513px] h-[45px] text-lg px-5"
            placeHolder="Full Name"
          />
          <TextInput
            containerVariant="mx-auto flex flex-col pb-[35px]"
            variant="text-black w-[513px] h-[45px] text-lg px-5"
            placeHolder="Email"
          />
          <TextInput
            containerVariant="mx-auto flex flex-col pb-6"
            variant="text-black w-[513px] h-[45px] text-lg px-5"
            placeHolder="Date of Birth"
          />
          <div className="flex w-[513px] flex-row mx-auto gap-5">
            <SelectField
              containerVariant="flex flex-col pb-6"
              variant="text-black h-[45px] text-lg px-5"
              options={["+1"]}
              placeholder="+1"
            />
            <TextInput
              containerVariant="w-full flex flex-col pb-6"
              variant="text-black w-full h-[45px] text-lg px-5"
              placeHolder="Phone Number"
            />
          </div>
          <TextArena
            containerVariant="mx-auto flex flex-col pb-6"
            variant="text-black w-[513px] h-[82px] text-lg px-5"
            placeHolder="Address"
          />
          <div className="flex flex-row gap-5 justify-center mb-[126px]">
            <div className="w-[193px] h-[45px]">
              <CustomButton
                containerVariant="py-[7px] w-full rounded-full flex justify-center"
                buttonVariant="secondary"
                labelText="Update Info"
              />
            </div>
            <div className="w-[193px] h-[45px]">
              <CustomButton
                containerVariant="py-[7px] w-full rounded-full flex justify-center"
                buttonVariant="secondary"
                labelText="Edit Info"
              />
            </div>
          </div>
        </div>
      </AppWrapper>
    </RequireAuth>
  );
};

export default Dashboard;
