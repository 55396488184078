import React from "react";
import { details } from "../constants";

const HowItWorks = () => {
  return (
    <>
      <div className="text-white px-10 md:px-0 font-inter z-50">
        {details.map((x, index) => (
          <>
            <div
              key={index}
              className="w-full h-full hidden md:grid grid-cols-2 gap-5 mb-20"
            >
              {index % 2 === 0 ? (
                <>
                  <div className="space-y-5 flex flex-col items-center justify-start">
                    <div className="lg:text-4xl text-2xl w-[75%] font-bold">
                      {x.title}
                    </div>
                    <div className="text-md w-[75%]">{x.description}</div>
                  </div>
                  <div className="flex justify-center z-20 w-full overflow-visible">
                    <img
                      src={x?.svg}
                      alt="Background Image"
                      className="w-[70%] h-full object-cover rounded-xl"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex justify-center">
                    <img
                      src={x?.svg}
                      alt="Background Image"
                      className="w-[70%] h-full object-cover rounded-xl"
                      // style={{
                      //   animation: "bgAnimation 3s ease-in-out infinite",
                      // }}
                    />
                  </div>
                  <div className="space-y-5 flex flex-col justify-start items-center">
                    <div className="lg:text-4xl text-2xl w-[75%] font-bold">
                      {x.title}
                    </div>
                    <div className="text-md w-[75%]">{x.description}</div>
                  </div>
                </>
              )}
            </div>

            <div key={index} className="w-full h-full md:hidden">
              <div className="space-y-5 mb-20 ">
                <div className="flex justify-center z-20 w-full overflow-visible">
                  <img
                    src={x?.svg}
                    alt="Background Image"
                    className="w-full md:w-[70%] h-full object-cover rounded-xl"
                  />
                </div>
                <div className="space-y-5 my-auto text-left p-5 md:px-20 bg-[#1E0045] rounded-xl">
                  <div className="lg:text-4xl text-2xl w-full font-bold">
                    {x.title}
                  </div>
                  <div className="text-md w-full">{x.description}</div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default HowItWorks;
